import { Button, Modal } from 'antd';
import { useState } from 'react';
import CreateOrEditNote from './CreateOrEditNote';

import { CalendarOutlined } from '@ant-design/icons';

const ModalButtonNoteCreate = ({ closeModal, entityId, entityType, buttonText }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   return (
      <>
         <Button
            onClick={showModal}
            style={{ width: '100%' }}
            icon={!buttonText ? null : <CalendarOutlined />}>
            {!buttonText ? <CalendarOutlined /> : buttonText}
         </Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Создать заметку`}
            footer={null}
            width={600}
            destroyOnClose={true}>
            <CreateOrEditNote
               id={null}
               callbackOnSuccess={handleCancel}
               entityId={entityId}
               entityType={entityType}
            />
         </Modal>
      </>
   );
};

export default ModalButtonNoteCreate;
