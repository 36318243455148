import React from 'react';
import TableSellers from '@features/list-seller/ui/TableSellers';
import { Title } from '@shared/ui';

const SellerListPage = () => {
   return (
      <div>
         <Title>Список продавцов</Title>
         <TableSellers />
      </div>
   );
};

export default SellerListPage;
