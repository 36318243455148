import { useState, useEffect } from 'react';
import { getWorkCars } from '@shared/api/all/stats';
import { Statistic } from 'antd';

const InWorkCars = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [count, setCount] = useState(null);

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = () => {
      setIsLoading(true);
      getWorkCars()
         .then((res) => {
            setCount(res?.json?.data || 0);
            setIsLoading(false);
         })
         .catch(() => setIsLoading(false));
   };

   return (
      <Statistic
         title={`Водителей на маршруте`}
         value={`${count}`}
         loading={isLoading}
      />
   );
};

export default InWorkCars;
