/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import SelectCategoriesOfEntity from '@widgets/FormItems/SelectCategoriesOfEntity';
import { Col, Row, Alert, Typography } from 'antd';
import { statusesOfDrivers } from '@shared/const/statuses';
// import { getDistricts } from '@shared/api/all/const';
import regions from '@shared/const/regions';

import InputUniqueNumberOfEntity from '@widgets/FormItems/InputUniqueNumberOfEntity';
const { TextArea } = Input;
const { Text } = Typography;

const DriverForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabledButton, setIsDisabledButton] = useState(true);
   // const [districtList, setDistrictList] = useState([]);
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();

   // useEffect(() => {
   //    getDistricts().then((res) => {
   //       setDistrictList(res?.json?.data || []);
   //    });
   // }, []);

   const onFinish = (values) => {
      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const suffixSelector = <Form.Item noStyle>кг</Form.Item>;
   const currentStatus = initialValues?.status || 'active';

   const isDisabledInput = (inputName) => {
      const list = {
         firstName: ['active'],
         lastName: ['active'],
         email: ['active'],
         mobileNumber: ['active'],
         carBrand: ['active'],
         carCapacity: ['active'],
         carNumber: ['active'],
         carSTS: ['active'],
         status: ['active', 'blocked', 'inwork'],
         entityCategories: ['active'],
         notes: ['active', 'blocked', 'inwork'],
         districts: ['active', 'blocked', 'inwork']
      };

      return !list[inputName].includes(currentStatus);
   };

   const statusesCanBeDoOnCurrent = {
      active: ['active', 'blocked'],
      inwork: ['inwork', 'active', 'blocked'],
      blocked: ['blocked', 'active'],
      deactivated: ['deactivated']
   };

   const isDriverBlocked = currentStatus === 'blocked';
   const isDriverInWork = currentStatus === 'inwork';

   const actualStatuses = initialValues?.status
      ? statusesCanBeDoOnCurrent[currentStatus].map((e) => statusesOfDrivers[e])
      : [statusesOfDrivers.active];

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         initialValues={initialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  name="firstName"
                  label="Имя"
                  // rules={!isDisabledInput('firstName') && russianOneWordRequired()}
               >
                  <Input placeholder="Имя" disabled={isDisabledInput('firstName')} />
               </Form.Item>
            </Col>

            <Col span={12}>
               <Form.Item
                  name="lastName"
                  label="Фамилия"
                  // rules={!isDisabledInput('lastName') && russianOneWordRequired()}
               >
                  <Input
                     placeholder="Фамилия"
                     disabled={isDisabledInput('lastName')}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={12}>
               <InputUniqueNumberOfEntity
                  disabled={isDisabledInput('mobileNumber')}
                  entityType="driver"
               />
            </Col>
            <Col span={12}>
               <Form.Item
                  name="email"
                  label="E-mail"
                  rules={
                     !isDisabledInput('email')
                        ? [
                             {
                                type: 'email',
                                message: 'Не похоже на почту!'
                             }
                          ]
                        : []
                  }>
                  <Input placeholder="Почта" disabled={isDisabledInput('email')} />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={6}>
               <Form.Item
                  name="carBrand"
                  label="Марка авто"
                  // rules={
                  //    !isDisabledInput('carBrand') && [
                  //       {
                  //          required: true,
                  //          message: 'Поле пропущено!'
                  //       },
                  //       {
                  //          max: 50,
                  //          message: 'Максимум 50 символов'
                  //       }
                  //    ]
                  // }
               >
                  <Input
                     style={{
                        width: '100%'
                     }}
                     placeholder="Марка"
                     disabled={isDisabledInput('carBrand')}
                  />
               </Form.Item>
            </Col>
            <Col span={6}>
               <Form.Item
                  name="carCapacity"
                  label="Грузоподъемность"
                  // rules={
                  //    !isDisabledInput('carCapacity') && [
                  //       {
                  //          required: true,
                  //          message: 'Поле пропущено!'
                  //       }
                  //    ]
                  // }
               >
                  <Input
                     addonAfter={suffixSelector}
                     style={{
                        width: '100%'
                     }}
                     type="number"
                     placeholder="Введите вес"
                     disabled={isDisabledInput('carCapacity')}
                  />
               </Form.Item>
            </Col>
            <Col span={6}>
               <Form.Item
                  name="carNumber"
                  label="Номер авто"
                  // rules={
                  //    !isDisabledInput('carNumber') && [
                  //       {
                  //          required: true,
                  //          message: 'Поле пропущено!'
                  //       },
                  //       {
                  //          max: 20,
                  //          message: 'Максимум 20 символов'
                  //       },
                  //       ...onlyAlphabetRuAndEnAndNumbers
                  //    ]
                  // }
               >
                  <Input
                     style={{
                        width: '100%'
                     }}
                     placeholder="Гос номер"
                     disabled={isDisabledInput('carNumber')}
                  />
               </Form.Item>
            </Col>
            <Col span={6}>
               <Form.Item
                  name="carSTS"
                  label="Номер СТС"
                  // rules={
                  //    !isDisabledInput('carSTS') && [
                  //       {
                  //          required: true,
                  //          message: 'Поле пропущено!'
                  //       },
                  //       {
                  //          max: 50,
                  //          message: 'Максимум 50 символов'
                  //       },
                  //       ...onlyAlphabetRuAndEnAndNumbers
                  //    ]
                  // }
               >
                  <Input
                     style={{
                        width: '100%'
                     }}
                     placeholder="СТС"
                     disabled={isDisabledInput('carSTS')}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={12}>
               {/* <Form.Item
                  label="Категории сырья"
                  name="entityCategories"
                  // rules={
                  //    !isDisabledInput('entityCategories') && [
                  //       {
                  //          required: true,
                  //          message: 'Выберите категории'
                  //       }
                  //    ]
                  // }
               >
                  <SelectCategoriesOfEntity
                     disabled={isDisabledInput('entityCategories')}
                     initialCategories={initialValues?.entityCategories || null}
                     setField={(value) => {
                        form.setFieldValue('entityCategories', value);
                        setIsDisabledButton(false);
                     }}
                  />
               </Form.Item> */}
               <Form.Item
                  name="status"
                  label="Статус"
                  rules={
                     !isDisabledInput('status')
                        ? [
                             {
                                required: true,
                                message: 'Выберите статус'
                             }
                          ]
                        : []
                  }
                  defaultValue={statusesOfDrivers.active}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     disabled={isDisabledInput('status')}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={actualStatuses}
                  />
               </Form.Item>

               <Form.Item name="districts" label="Округи Москвы">
                  <Select
                     disabled={isDisabledInput('districts')}
                     mode="multiple"
                     allowClear
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите округ"
                     options={Object.values(regions).map((e) => ({
                        label: `${e.value} ${e.label}`,
                        value: e.value
                     }))}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  name="notes"
                  label={
                     <span>
                        Комментарий водителю{' '}
                        <Text
                           type="secondary"
                           style={{ fontSize: '12px', marginLeft: '5px' }}>
                           видно только вам
                        </Text>
                     </span>
                  }>
                  <TextArea
                     disabled={isDisabledInput('notes')}
                     rows={8}
                     placeholder="Максимум 800 символов"
                     maxLength={800}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={[16, 24]}>
            {isDriverBlocked && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе заблокирован нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {isDriverInWork && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе В работе нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {errorMessage && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message={errorMessage.replace('Error: ', '')}
                     type="error"
                     showIcon
                  />
               </Col>
            )}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={isLoading}
                     disabled={isDisabledButton}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default DriverForm;
