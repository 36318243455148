import { get, post, put, patch, generateQueryParams, _delete } from '../fetch.js';

export const getRouteById = (id) => get(`/route/${id}`);

export const getRouteEarningById = (id, type) => get(`/route/${id}/earning/${type}`);

export const deleteRouteById = (id) => _delete(`/route/${id}`, {}, true);

export const getRoutes = (params) => get(generateQueryParams(`/routes`, params));

export const updateStatusOfRoute = (fields, id) =>
   patch(
      `/route/${id}`,
      {
         ...fields
      },
      true
   );

export const createRoute = (fields) =>
   post(
      '/route',
      {
         ...fields
      },
      true
   );

export const updateRoute = (fields, id) =>
   put(
      `/route/${id}`,
      {
         ...fields
      },
      true
   );
