import React, { useState, useEffect } from 'react';
import {
   ChatContainer,
   ConversationHeader,
   MessageList,
   Message,
   MessageSeparator
} from '@chatscope/chat-ui-kit-react';
import { getChat } from '@shared/api/all/chat';
import { Empty, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';
import ModalImage from 'react-modal-image';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

const getImageSrc = (message) => {
   if (message.includes('data:image')) {
      return message;
   }

   return `data:image/jpeg;charset=utf-8;base64, ${message}`;
};

const ChatOfLead = (props) => {
   const [loading, setLoading] = useState(false);
   const [entityInfo, setEntityInfo] = useState({
      seller: {},
      driver: {}
   });
   const [messages, setMessages] = useState([]);
   const { leadId, driver, seller } = props;

   useEffect(() => {
      const driverId = driver?.id;
      const sellerId = seller?.id;

      if (driverId && sellerId && leadId) {
         setEntityInfo({
            seller,
            driver
         });
         fetchData(driverId, sellerId, leadId);
      }
   }, [driver, seller]);

   const fetchData = (driverId, sellerId, leadId) => {
      getChat(
         {
            page: 1,
            limit: 1000,
            entityFromType: 'seller',
            entityFromId: sellerId,
            entityToType: 'driver',
            entityToId: driverId
         },
         'lead',
         leadId
      ).then((response) => {
         if (response?.json?.data.length > 0) {
            setMessages(response?.json?.data);
         }
      });

      setLoading(true);
      setMessages([]);
      setLoading(false);
   };

   return (
      <ChatContainer
         style={{
            height: '400px'
         }}>
         <ConversationHeader>
            <ConversationHeader.Content
               info={`Продавец №${entityInfo.seller?.id} +7${entityInfo.seller?.mobileNumber}`}
               userName={`${entityInfo.seller?.firstName} ${entityInfo.seller?.lastName}`}
            />
            <ConversationHeader.Actions>
               <ConversationHeader.Content
                  info={`Водитель №${entityInfo.driver?.id} +7${entityInfo.driver?.mobileNumber}`}
                  userName={`${entityInfo.driver?.firstName} ${entityInfo.driver?.lastName}`}
               />
            </ConversationHeader.Actions>
         </ConversationHeader>

         <MessageList>
            {messages.length === 0 && !loading ? (
               <div
                  style={{
                     height: '340px',
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center'
                  }}>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
               </div>
            ) : (
               <>
                  <br />
                  <MessageSeparator
                     content={dayjs(messages[0].createdAt).format('YYYY.MM.DD')}
                  />
                  <br />
                  {messages.map((message, index) => (
                     <>
                        {message.messageType === 'image' ? (
                           <Message
                              key={index}
                              model={{
                                 direction:
                                    message.entityFromType === 'seller' ? 0 : 1,
                                 type: 'custom',
                                 payload: (
                                    <div style={{ width: '100px' }}>
                                       <ModalImage
                                          small={getImageSrc(message.message)}
                                          large={getImageSrc(message.message)}
                                          alt="Картинка"
                                       />
                                    </div>
                                 )
                              }}>
                              <Message.Footer style={{ gap: '5px' }}>
                                 {message.readed ? (
                                    <Tooltip placement="bottom" title={`Прочтено`}>
                                       <EyeOutlined />
                                    </Tooltip>
                                 ) : (
                                    <Tooltip
                                       placement="bottom"
                                       title={`Не прочтено`}>
                                       <EyeInvisibleOutlined />
                                    </Tooltip>
                                 )}
                                 <Tooltip
                                    placement="top"
                                    title={`${dayjs(message.createdAt).format(
                                       'YYYY.MM.DD HH:mm'
                                    )}`}>
                                    {`${getTimeAgo(
                                       dayjs(message.createdAt).unix() * 1000
                                    )}`}
                                 </Tooltip>
                              </Message.Footer>
                           </Message>
                        ) : (
                           <Message
                              key={index}
                              model={{
                                 direction:
                                    message.entityFromType === 'seller' ? 0 : 1,
                                 type: 'text',
                                 message: message.message,
                                 position: 'normal'
                              }}>
                              <Message.Footer style={{ gap: '5px' }}>
                                 {message.readed ? (
                                    <Tooltip placement="bottom" title={`Прочтено`}>
                                       <EyeOutlined />
                                    </Tooltip>
                                 ) : (
                                    <Tooltip
                                       placement="bottom"
                                       title={`Не прочтено`}>
                                       <EyeInvisibleOutlined />
                                    </Tooltip>
                                 )}
                                 <Tooltip
                                    placement="top"
                                    title={`${dayjs(message.createdAt).format(
                                       'YYYY.MM.DD HH:mm'
                                    )}`}>
                                    {`${getTimeAgo(
                                       dayjs(message.createdAt).unix() * 1000
                                    )}`}
                                 </Tooltip>
                              </Message.Footer>
                           </Message>
                        )}
                     </>
                  ))}
               </>
            )}
         </MessageList>
      </ChatContainer>
   );
};

export default ChatOfLead;
