import { getNotes } from '@shared/api/all/note';

export const GetNoteList = async (params) => {
   try {
      const response = await getNotes(params);
      if (!response.json) throw new Error();
      return { data: response.json.data, count: response.json.count };
   } catch (e) {
      console.log(e);
   }
};
