import { Select, Tag } from 'antd';
import { useState, useEffect } from 'react';
import { GetLeadList } from '@features/list-lead/model/GetLeadList';
import { message } from 'antd';

import { statusesOfLeads } from '@shared/const/statuses';
import _ from 'lodash';

const generateSelectLabelString = (lead) => {
   return `Заявка №${lead?.id}`;
};

const SelectLeads = ({
   initialLeads,
   setField,
   leadIds,
   maxCount = 30,
   mode = 'multiple',
   disabled = false,
   setCapacityOfLeads = () => {}
}) => {
   const [leads, setLeads] = useState([]);
   const [searchParams, setSearchParams] = useState({});
   const [selectedLeadIds, setSelectedLeadIds] = useState([]);

   useEffect(() => {
      fetchLeads(searchParams);
   }, [searchParams]);

   useEffect(() => {
      if (leadIds) {
         setSelectedLeadIds(leadIds);
      }
   }, [leadIds]);

   useEffect(() => {
      updateCapacitySelectedOfLeads();
   }, [selectedLeadIds]);

   useEffect(() => {
      if (initialLeads?.length > 0) {
         setLeads(
            initialLeads.map((item) => ({
               ...item,
               selectLabel: generateSelectLabelString(item)
            }))
         );
         setSelectedLeadIds(initialLeads.map((item) => item.id));
      }
   }, [initialLeads]);

   const fetchLeads = (searchParams = {}) => {
      GetLeadList({
         page: 1,
         limit: 5,
         sort: 'id',
         order: 'desc',
         status: 'active',
         routeId: '',
         ...searchParams
      }).then((res) => {
         if (!res?.data) {
            setLeads([]);
            return;
         }
         const modifiedData = res.data.map((item) => ({
            ...item,
            selectLabel: generateSelectLabelString(item)
         }));

         setLeads((prev) => _.uniqBy([...prev, ...modifiedData], 'id'));
      });
   };

   const onFocus = () => {
      fetchLeads(searchParams);
   };

   const onChange = (value) => {
      if (value?.length === maxCount) {
         message.warning(`Максимальное количество заявок ${maxCount}`);
         return;
      }

      setField(value);
      setSelectedLeadIds(Array.isArray(value) ? value : [value]);
   };

   const onSearch = (value) => {
      let val = {};
      if (!isNaN(parseInt(value))) {
         val = { id: value };
      }

      setSearchParams(val);
   };

   const updateCapacitySelectedOfLeads = () => {
      let capacityArr = selectedLeadIds.map(
         (id) => leads.find((e) => id === e.id)?.capacity
      );
      if (capacityArr.length === 0) {
         setCapacityOfLeads(0);
         return;
      }

      setCapacityOfLeads(capacityArr.reduce((a, b) => a + b));
   };

   const tagRender = (props) => {
      const { label, value, closable, onClose } = props;
      const onPreventMouseDown = (event) => {
         event.preventDefault();
         event.stopPropagation();
      };

      const currentLead = leads.find((e) => e.id === value);

      return (
         <Tag
            color={statusesOfLeads[currentLead?.status]?.color || null}
            onMouseDown={onPreventMouseDown}
            closable={closable}
            onClose={onClose}
            style={{
               marginRight: 3
            }}
         >
            {label}
         </Tag>
      );
   };

   return (
      <Select
         mode={mode}
         disabled={disabled}
         allowClear
         showSearch
         placeholder="Введите номер заявки"
         optionFilterProp="children"
         onChange={onChange}
         onFocus={onFocus}
         value={selectedLeadIds}
         onSearch={onSearch}
         filterOption={false}
         tagRender={(props) => tagRender({ ...props })}
         rules={[
            {
               required: true,
               message: 'Поле не может быть пустым'
            }
         ]}
         fieldNames={{
            label: 'selectLabel',
            value: 'id'
         }}
         options={leads}
      />
   );
};
export default SelectLeads;
