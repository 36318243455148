import React from 'react';
import { Tag, Typography } from 'antd';

const { Text } = Typography;

const TotalCountTableText = ({ total = 0 }) => {
   if (total === 0) {
      return null;
   }

   return (
      <div>
         <Text type="secondary" style={{ fontSize: '14px' }}>
            Найдено записей: <Tag bordered={false}>{total}</Tag>
         </Text>
      </div>
   );
};

export default TotalCountTableText;
