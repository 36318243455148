import { Select, message, Badge } from 'antd';
import { useState, useEffect } from 'react';
import { GetSellersList } from '@features/list-seller/model/GetSellersList';

import { statusesOfSellers } from '@shared/const/statuses';

const generateSelectLabelString = (seller) => {
   return `${seller?.firstName} ${seller?.lastName} ${seller?.mobileNumber}`;
};

const { Option } = Select;

const SelectSeller = ({
   seller,
   setField,
   disabled = false,
   sellerId,
   setAddressList = () => {}
}) => {
   const [sellers, setSellers] = useState([]);
   const [searchParams, setSearchParams] = useState({});
   const [selectedSellerId, setSelectedSellerId] = useState(null);

   useEffect(() => {
      fetchSellers(searchParams);
   }, [searchParams]);

   useEffect(() => {
      if (sellerId) {
         setSelectedSellerId(sellerId);
      }
   }, [sellerId]);

   useEffect(() => {
      if (seller?.id) {
         seller.selectLabel = generateSelectLabelString(seller);
         setSellers([seller]);
         setSelectedSellerId(seller?.id);
      }
   }, [seller]);

   const fetchSellers = (searchParams = {}) => {
      GetSellersList({
         page: 1,
         limit: 10,
         sort: 'id',
         order: 'desc',
         // status: 'active',
         op: 'or',
         ...searchParams
      }).then((res) => {
         const modifiedData = res.data.map((item) => ({
            ...item,
            selectLabel: `${item.firstName} ${item.lastName} ${item.mobileNumber}`
         }));
         setSellers(modifiedData.filter((e) => e.status === 'active'));
      });
   };

   const onFocus = () => {
      fetchSellers(searchParams);
   };

   const onChange = (value, option) => {
      if (option.info.status !== 'active') {
         message.error(
            'Продавец не доступен для выбора, так как он сейчас отключен'
         );
         return;
      }
      setField(value);
      setSelectedSellerId(value);
      setAddressList(option.addresses);
   };

   const onSearch = (value) => {
      setSearchParams(
         value ? { mobileNumber: value, firstName: value, lastName: value } : {}
      );
   };

   return (
      <Select
         disabled={disabled}
         showSearch
         placeholder="Введите номер телефона"
         optionFilterProp="children"
         onChange={onChange}
         onFocus={onFocus}
         value={selectedSellerId}
         onSearch={onSearch}
         filterOption={false}
         optionLabelProp="label">
         {sellers.map((item) => (
            <Option
               key={item.id + item.firstName}
               value={item.id}
               label={generateSelectLabelString(item)}
               info={item}>
               <Badge
                  status={statusesOfSellers[item.status].badgeStatus}
                  style={{ paddingRight: '8px' }}
               />
               {generateSelectLabelString(item)}
            </Option>
         ))}
      </Select>
   );
};
export default SelectSeller;
