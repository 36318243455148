import React, { useEffect, useState } from 'react';
import { Button, List, Badge, Space } from 'antd';
import { getNotifications } from '@shared/api/all/notification';
import { LAST_SEEN_NOTIFICATION_ID } from '@shared/const/localStorage';
import {
   setLocalStorageByKey,
   getLocalStorageByKey
} from '@shared/lib/localStorage';
import { ClockCircleOutlined } from '@ant-design/icons';
import { timestampToNormalDate } from '@shared/utils/tsToTime';

const IconText = ({ icon, text }) => (
   <Space>
      {React.createElement(icon)}
      {text}
   </Space>
);

const NotificationList = () => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);
   const [pagination, setPagination] = useState({
      page: 0,
      sorter: {
         sort: 'id',
         order: 'desc'
      }
   });
   const [count, setCount] = useState(0);
   const [lastSeenId, setLastSeenId] = useState(0);

   useEffect(() => {
      getLastSeendId();
      fetchData();
   }, []);

   const getLastSeendId = async () => {
      const lastSeenId = await getLocalStorageByKey(LAST_SEEN_NOTIFICATION_ID);
      setLastSeenId(lastSeenId || 0);
   };

   const fetchData = () => {
      if (loading) {
         return;
      }

      let page = pagination.page + 1;

      setLoading(true);
      getNotifications({
         page,
         limit: 10,
         ...pagination.sorter
      })
         .then((res) => {
            if (page === 1 && res?.json?.data?.length >= 1) {
               const lastSeendId = res?.json?.data[0]?.id;
               setLocalStorageByKey(LAST_SEEN_NOTIFICATION_ID, lastSeendId);
            }

            setData([...data, ...res.json.data]);
            setLoading(false);
            setCount(res.json.count);
            setPagination({ ...pagination, page });
         })
         .catch(() => setLoading(false));
   };

   return (
      <div>
         <List
            className="notification-list"
            dataSource={data}
            renderItem={(item) => (
               <List.Item
                  key={`item-${item.id}-${item.title}`}
                  className={lastSeenId < item.id ? 'active' : ''}
                  actions={[
                     <IconText
                        icon={ClockCircleOutlined}
                        text={timestampToNormalDate(item.createdAt)}
                        key="list-vertical-star-o"
                     />
                  ]}>
                  <List.Item.Meta
                     title={
                        lastSeenId < item.id ? (
                           <Badge status={'processing'} text={item.title} />
                        ) : (
                           item.title
                        )
                     }
                     description={item.description}
                  />
               </List.Item>
            )}
         />

         {count !== 0 && (
            <Space
               direction="horizontal"
               style={{ width: '100%', justifyContent: 'center' }}>
               <Button
                  type="primary"
                  loading={loading}
                  onClick={() => fetchData()}
                  disabled={count === data.length}>
                  Загрузить больше
               </Button>
            </Space>
         )}
      </div>
   );
};

export default NotificationList;
