import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Col, Rate, Row } from 'antd';

import { GetRatingSimpleList } from '@features/list-rating/model/GetRatingSimpleList';

const RatingOfEntity = ({
   actionForEntityType,
   actionForEntityId,
   fromEntityType,
   fromEntityId,
   toEntityType,
   toEntityId,
   status,
   categoryOfRating
}) => {
   const [data, setData] = useState([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      fetchData(actionForEntityId);
   }, [actionForEntityId]);

   const fetchData = (actionForEntityId) => {
      if (!actionForEntityId) {
         return;
      }

      setLoading(true);

      GetRatingSimpleList({
         actionForEntityType,
         actionForEntityId,
         fromEntityType,
         fromEntityId,
         toEntityType,
         toEntityId,
         status,
         categoryOfRating
      }).then((res) => {
         console.log(res?.data);
         setData(res?.data || []);

         setLoading(false);
      });
   };

   if (loading) {
      return (
         <div>
            <Spin />
         </div>
      );
   }

   if (!actionForEntityId) {
      return <div>Оценка не загружена</div>;
   }

   if (data?.length === 0) {
      return <div>Не выставлена оценка</div>;
   }

   return (
      <>
         {data.map((item, index) => (
            <Row gutter={24} key={`rating-${index}`}>
               <Col span={24}>
                  <Rate
                     className="rate-big"
                     allowHalf
                     disabled={true}
                     allowClear={true}
                     defaultValue={0}
                     value={item?.value / 10}
                  />
               </Col>

               <Col span={24} style={{ marginTop: '15px' }}>
                  Комментарий к оценке: {item?.comment || 'Без комментариев'}
               </Col>
            </Row>
         ))}
      </>
   );
};

export default RatingOfEntity;
