import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { Descriptions, Tag, Space, Divider } from 'antd';

import { GetRoute } from '@features/list-route/model/GetRoute';
import routeCapacity from '@shared/utils/routeCapacity';
import routePriceToBuy from '@shared/utils/routePriceToBuy';
import stringToColor from '@shared/utils/stringToColor';

import { StatusTag } from '@shared/ui';
import { useNavigate } from 'react-router-dom';
import DriverSimpleDescriptions from '@features/list-driver/ui/DriverSimpleDescriptions';

const getCategoriesList = (leads) => {
   const list = new Set();

   leads.forEach((e) => {
      list.add(e.materialCategory.name);
   });

   return [...list];
};

const SimpleRouteInfo = ({ routeId }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      fetchData(routeId);
   }, [routeId]);

   const routeDescriptionLabelsAndValues = [
      {
         label: 'ID',
         key: 'id',
         render: (_, record) => (
            <>
               <span style={{ marginRight: '10px' }}>{_}</span>
               <StatusTag status={record.status} type="route" />
               <span
                  className="green-span-url"
                  style={{}}
                  onClick={() => navigate(`/routes/${_}`)}>
                  Перейти
               </span>
            </>
         )
      },
      {
         label: 'Общий вес',
         key: 'fullCapacity',
         render: (_, record) => <>{routeCapacity(record?.leads || [])} кг</>
      },
      {
         label: 'Стоимость выкупа',
         key: 'fullPriceToBuy',
         render: (_, record) => <>{routePriceToBuy(record?.leads || [])}</>
      },
      {
         label: 'Создан автоматически',
         key: 'isAutoCreated',
         render: (_) => <>{_ ? 'Да' : 'Нет'}</>
      },
      {
         label: 'Комиссия',
         key: 'commission',
         render: (_) => (_ ? `${_} рублей` : 'Не указана')
      },

      {
         label: 'Категории сырья',
         key: 'materialCategories',
         render: (_, record) => {
            const r = [];

            getCategoriesList(record?.leads || []).forEach((e) =>
               r.push(<Tag color={stringToColor(e)}>{e}</Tag>)
            );

            return (
               <Space size="small" wrap>
                  {r}
               </Space>
            );
         }
      }
   ];

   const fetchData = (routeId) => {
      if (!routeId) {
         return;
      }

      setLoading(true);

      GetRoute(routeId).then((res) => {
         setData({ ...res });
         setLoading(false);
      });
   };

   if (loading) {
      return (
         <div>
            <Spin />
         </div>
      );
   }

   if (!routeId) {
      return <div>Маршрут не назначен</div>;
   }

   return (
      <>
         <Descriptions size="small">
            {routeDescriptionLabelsAndValues.map((e) => (
               <Descriptions.Item
                  span={3}
                  key={`${data.id}-${e.label}`}
                  label={e.label}>
                  {e.render(data?.[e.key], data)}
               </Descriptions.Item>
            ))}
         </Descriptions>

         <Divider orientation="left">Информация о водителе</Divider>
         {data?.driver ? (
            <DriverSimpleDescriptions data={data?.driver || false} />
         ) : (
            'Водитель не назначен'
         )}
      </>
   );
};

export default SimpleRouteInfo;
