import React, { useState, useEffect } from 'react';
import { Space, Table, Switch, List } from 'antd';
import { Button, VerticalSpace, StatusTag, DateWithAgo } from '@shared/ui';
import { GetSellersList } from '../model/GetSellersList';
import { EditOutlined } from '@ant-design/icons';
import ModalSellerForm from '../../form-seller/ui/ModalSellerForm';
import { Divider, Tooltip, Typography } from 'antd';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import stringToColor from '@shared/utils/stringToColor';
import ModalButtonSellerCreate from './ModalButtonSellerCreate';
import CanDo from '@shared/lib/CanDo';
import CanDoManager from '@shared/lib/CanDoManager';
import { getUserAuthData } from '@entitles/User';
import {
   getColumnSearchProps,
   getStatusColumnFilterProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';
import { statusesOfSellers } from '@shared/const/statuses';
import { deleteSellerById } from '@shared/api/all/seller';
import EnterToEntityButton from '@widgets/Button/EnterToEntityButton';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import { useNavigate } from 'react-router-dom';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import { useSelector } from 'react-redux';
import ExportToCSVModalButton from '@widgets/ExportToCSV/ExportToCSVModalButton';
import UserCardById from '@features/list-user/ui/UserCardById';
import { changeUrl } from '@shared/utils/browser';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { LeadCount } from './SellerComponents';
import ModalButtonNoteCreate from '@features/notes/ui/ModalButtonNoteCreate';

import { GetSellerTypes } from '@features/form-seller/model/services/GetSellerTypes';
const { Text } = Typography;

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const addressList = (adressArr) => {
   if (!adressArr?.length) {
      return (
         <Text type="secondary" style={{ fontSize: '12px' }}>
            Адресов нет
         </Text>
      );
   }

   return (
      <div className="address_list">
         <List
            itemLayout="horizontal"
            dataSource={adressArr}
            renderItem={(item) => (
               <List.Item key={item.toString()}>
                  <List.Item.Meta
                     title={item.address}
                     // description={item.comment}
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%'
                     }}
                  />
               </List.Item>
            )}
         />
      </div>
   );
};

const TableSellers = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const userData = useSelector(getUserAuthData);

   const [selectedSeller, setSelectedSeller] = useState(null);
   const navigate = useNavigate();

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const params = parseUrlListSettings();

         fetchData(params);
      }

      // fetchTags();
   }, []);

   const getCategoryNames = (arrOfEntityCategories = []) => {
      return arrOfEntityCategories.map((item) => item?.materialCategory?.name);
   };

   const columns = [
      // {
      //    title: 'ID',
      //    dataIndex: 'id',
      //    key: 'id',
      //    sorter: true,
      //    filtered: !!filters?.['id'],
      //    ...getColumnSearchProps({
      //       dataIndex: 'id',
      //       handleSearch: (searchObj) => onSearchTable(searchObj),
      //       type: 'number'
      //    })
      // },
      {
         title: 'Продавец',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         // filtered: !!filters?.['firstName'],

         filtered: !!filters?.['id'],
         filteredValue: filters?.['id'] || null,
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),
         render: (_, record) => (
            <>
               <Space direction="vertical">
                  <Space direction="horizontal" size="small">
                     <span
                        className="green-span-url"
                        style={{ margin: 0 }}
                        onClick={() => navigate(`/sellers/${record.id}`)}>
                        {`Продавец №${record.id}`}
                     </span>

                     <NewEntityTag date={record.createdAt} />
                  </Space>

                  <Text type="secondary" style={{ fontSize: '12px' }}>
                     {record.firstName} {record.lastName}
                  </Text>

                  {/* {record?.materialCategory?.name && (
                  <Tag color={stringToColor(record.materialCategory.name)}>
                     {record.materialCategory.name}:{' '}
                     {record?.capacity && `${record.capacity} кг`}
                  </Tag>
               )} */}
               </Space>
            </>
         )
         // ...getColumnSearchProps({
         //    dataIndex: 'firstName',
         //    handleSearch: (searchObj) => onSearchTable(searchObj)
         // })
      },
      {
         title: 'Номер телефона',
         dataIndex: 'mobileNumber',
         key: 'mobileNumber',
         filtered: !!filters?.['mobileNumber'],
         filteredValue: filters?.['mobileNumber'] || null,
         ...getColumnSearchProps({
            dataIndex: 'mobileNumber',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Дата регистрации',
         dataIndex: 'createdAt',
         key: 'createdAt',
         sorter: true,
         align: 'center',
         sortOrder:
            paramsTable?.sorter?.sort === 'createdAt'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         render: (_) => <DateWithAgo date={_} />
      },
      {
         title: 'Был в приложении',
         dataIndex: 'lastSeenAt',
         key: 'lastSeenAt',
         sorter: true,
         align: 'center',
         sortOrder:
            paramsTable?.sorter?.sort === 'lastSeenAt'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         render: (_) => <DateWithAgo date={_} showDate={false} />
      }
   ];

   const actions = [
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         align: 'center',
         filterMode: true,
         filteredValue: Array.isArray(paramsTable?.filters?.status)
            ? paramsTable?.filters?.status
            : paramsTable?.filters?.status
            ? [paramsTable?.filters?.status]
            : [],

         filtered: !!filters?.['status'],
         filters: Object.values(statusesOfSellers).map((e) => ({
            text: <StatusTag status={e.value} type="seller" />,
            value: e.value
         })),
         render: (_) => <StatusTag status={_} type="seller" />
      },
      // {
      //    title: 'Категория',
      //    dataIndex: 'entityCategories',
      //    key: 'entityCategories',
      //    render: (_, record) => (
      //       <Space size="middle" wrap>
      //          {getCategoryNames(_).map((item) => (
      //             <React.Fragment key={`category-${record.id}-${item}`}>
      //                {item && <Tag color={stringToColor(item)}>{item}</Tag>}
      //             </React.Fragment>
      //          ))}
      //       </Space>
      //    )
      // },

      {
         title: 'Количество заявок',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         align: 'center',
         render: (_, record) => <LeadCount sellerId={record.id} />
      },
      {
         title: 'Ответственный',
         dataIndex: 'userId',
         key: 'userId',
         filtered: !!filters?.['userId'],
         filteredValue: filters?.['userId'] || null,
         ...getColumnSearchProps({
            dataIndex: 'userId',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),
         render: (userId) => <UserCardById id={userId} />
      },
      {
         title: 'Действие',
         key: 'action',
         width: 200,
         render: (_, record) => (
            <Space size="middle">
               <EnterToEntityButton id={record.id} entity="sellers" />
               <CanDo permission="can_edit_sellers">
                  <CanDoManager userId={record.userId}>
                     <Tooltip placement="top" title={'Редактирование'}>
                        <Button
                           onClick={() => setSelectedSeller(record)}
                           type="primary">
                           <EditOutlined />
                        </Button>
                     </Tooltip>{' '}
                  </CanDoManager>
               </CanDo>{' '}
               <Tooltip placement="top" title={'Добавить заметку'}>
                  <ModalButtonNoteCreate
                     closeModal={closeModal}
                     entityId={record.id}
                     entityType={'seller'}
                     buttonText={null}
                  />{' '}
               </Tooltip>
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedSeller(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      const queryObj = getApiQueryParamsForTables(
         params,
         paramsTable,
         initialSorter
      );
      const { page: current, limit: pageSize } = queryObj;

      GetSellersList({ ...queryObj }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, current, pageSize, total: res.count }
         });
         changeUrl(params);
         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   const showOnlyMy = (bool) => {
      const newParams = {
         ...paramsTable,
         filters: {
            ...paramsTable.filters,
            userId: userData.id.toString()
         }
      };

      if (!bool) {
         delete newParams.filters.userId;
      }

      fetchData(newParams);
   };

   return (
      <>
         <ModalSellerForm selectedSeller={selectedSeller} closeModal={closeModal} />{' '}
         <CanDo permission="can_create_sellers">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonSellerCreate closeModal={closeModal} />
               <Space style={{ marginLeft: '10px' }} size="small">
                  Показать
                  <Switch
                     checkedChildren="Мои"
                     unCheckedChildren="Все"
                     checked={filters.userId === userData.id.toString()}
                     onChange={showOnlyMy}
                  />{' '}
               </Space>
            </Space>
         </CanDo>
         <VerticalSpace />
         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
                  <ExportToCSVModalButton
                     type="seller"
                     fetchData={GetSellersList}
                     dataParams={paramsTable}
                  />
               </Space>
            </div>
         </div>
         <Table
            rowKey="id"
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            loading={isLoading}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableSellers;
