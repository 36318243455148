import React, { useState, useEffect } from 'react';
import { Button } from '@shared/ui';
import { GetAuctionPricesList } from './../model/GetAuctionPricesList';
import { FinishAuctionById } from './../model/FinishAuctionById';
import { CancelAuctionById } from './../model/CancelAuctionById';

import { message, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { List, Radio } from 'antd';
const { confirm } = Modal;

const AuctionPeersButton = ({ id, callbackOnSuccess = () => {} }) => {
   const [isLoading, setLoading] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [selectedWinnerAcceptanceId, setSelectedWinnerAcceptanceId] =
      useState(null);
   const [prices, setPrices] = useState([]);

   useEffect(() => {
      if (isModalOpen) {
         fetchPrices();
      }
      setSelectedWinnerAcceptanceId(null);
   }, [isModalOpen]);

   const showModal = () => {
      setIsModalOpen(true);
   };
   const handleOk = () => {
      if (!selectedWinnerAcceptanceId) {
         message.error('Выберите победителя');
         return;
      }

      showConfirm();
      // setIsModalOpen(false);
   };
   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const cancelAuction = () => {
      CancelAuctionById(id)
         .then(() => {
            message.success('Торг отменен');
            handleCancel();
            callbackOnSuccess();
         })
         .catch(() => message.error('Возникла ошибка'))
         .finally(() => setLoading(false));
   };

   const finishAuction = () => {
      FinishAuctionById(
         {
            winnerId: selectedWinnerAcceptanceId
         },
         id
      )
         .then(() => {
            message.success('Торг завершен, информация отправлена победителю');
            handleCancel();
            callbackOnSuccess();
         })
         .catch(() => message.error('Возникла ошибка'))
         .finally(() => setLoading(false));
   };

   const showConfirm = () => {
      return confirm({
         title: 'Подтвердите действие',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         onOk() {
            finishAuction();
         },
         okText: 'Завершить торг',
         cancelText: 'Отмена'
      });
   };

   const showConfirmCancel = () => {
      return confirm({
         title: 'Подтвердите действие',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         onOk() {
            cancelAuction();
         },
         okText: 'Отменить торг',
         cancelText: 'Отмена'
      });
   };

   const fetchPrices = async () => {
      setLoading(true);

      await GetAuctionPricesList(id, {
         page: 1,
         limit: 1000,
         sort: 'price',
         order: 'desc'
      })
         .then((res) => {
            setPrices(res.data);
         })
         .catch(() => message.error('Возникла ошибка'))
         .finally(() => setLoading(false));
   };

   return (
      <>
         <Button danger type="primary" onClick={showModal}>
            Участники
         </Button>

         <Modal
            title="Выбрать победителя"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={'Завершить торг'}
         >
            <List
               style={{ marginTop: '-10px' }}
               itemLayout="horizontal"
               dataSource={prices}
               renderItem={(item) => (
                  <List.Item
                     onClick={() =>
                        setSelectedWinnerAcceptanceId(item.acceptance.id)
                     }
                     style={{
                        padding: '15px',
                        background: `${
                           selectedWinnerAcceptanceId === item.acceptance.id
                              ? '#0e00802e'
                              : 'transparent'
                        }`
                     }}
                  >
                     <List.Item.Meta
                        title={`${item.acceptance.title}, Ставка: ${item.price} руб.`}
                        description={
                           <>
                              <div>{`Телефон: ${item.acceptance.mobileNumber}, ${item.acceptance.firstName} ${item.acceptance.lastName}`}</div>

                              <div>
                                 <Radio
                                    style={{ marginTop: '10px' }}
                                    checked={
                                       selectedWinnerAcceptanceId ===
                                       item.acceptance.id
                                    }
                                    onClick={() =>
                                       setSelectedWinnerAcceptanceId(
                                          item.acceptance.id
                                       )
                                    }
                                 >
                                    Выбрать
                                 </Radio>
                              </div>
                           </>
                        }
                     />
                  </List.Item>
               )}
            />

            <Button
               style={{ marginTop: '10px' }}
               type="primary"
               danger
               onClick={showConfirmCancel}
               loading={isLoading}
            >
               Отменить торг
            </Button>
         </Modal>
      </>
   );
};

export default AuctionPeersButton;
