const USER_LOCALSTORAGE_KEY = 'user';
const SIDEBAR_LOCALSTORAGE_KEY = 'sidebar';
const PAGINATION_PAGESIZE_LOCALSTORAGE_KEY = 'pageSize';
const LAST_SEEN_NOTIFICATION_ID = 'lastSeenNotificationid';
const LAST_SHOWED_NOTIFICATION_ID = 'lastShowedNotificationId';

export {
   USER_LOCALSTORAGE_KEY,
   SIDEBAR_LOCALSTORAGE_KEY,
   PAGINATION_PAGESIZE_LOCALSTORAGE_KEY,
   LAST_SEEN_NOTIFICATION_ID,
   LAST_SHOWED_NOTIFICATION_ID
};
