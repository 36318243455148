/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Select } from '@shared/ui';
import { statusesOfAuctions } from '@shared/const/statuses';

import { Col, Row, InputNumber, Alert } from 'antd';

import SelectLeads from '@widgets/FormItems/SelectLeads';

const AcceptanceForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const { initialValues, onSuccess, isEditForm } = props;
   const [choosedStatus, setChoosedStatus] = useState(null);
   const [form] = Form.useForm();

   useEffect(() => {
      if (!initialValues?.status) return;
      setChoosedStatus(initialValues?.status);
   }, [initialValues]);

   const onFinish = (values) => {
      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const currentStatus = choosedStatus || 'active';
   const initialStatus = initialValues.status || 'active';

   const isDisabledInput = (inputName) => {
      const list = {
         startPrice: ['active', 'finished'],
         leadId: ['active', 'finished'],
         status: ['archive', 'active', 'finished']
      };

      return !list[inputName].includes(initialStatus);
   };

   const statusesCanBeDoOnCurrent = {
      active: ['active', 'archive'],
      pending: ['pending'],
      finished: ['finished', 'active'],
      archive: ['archive', 'active']
   };

   const isAuctionArchive = currentStatus === 'archive';

   const actualStatuses = initialValues?.status
      ? statusesCanBeDoOnCurrent[currentStatus].map((e) => statusesOfAuctions[e])
      : [statusesOfAuctions.active];

   return (
      <Form
         name="basic"
         style={{
            maxWidth: 900,
            minWidth: 420
         }}
         form={form}
         initialValues={initialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         hideRequiredMark
         layout="vertical"
      >
         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  name="startPrice"
                  min={0}
                  defaultValue={0}
                  rules={
                     !isDisabledInput('startPrice') && [
                        {
                           required: true,
                           message: 'Пропустили поле'
                        }
                     ]
                  }
                  label="Минимальная стоимость"
               >
                  <InputNumber
                     disabled={isDisabledInput('startPrice')}
                     placeholder="Цена"
                     type="number"
                     style={{ width: '100%' }}
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item
                  name="leadId"
                  label="Заявка"
                  rules={
                     !isDisabledInput('leadId') && [
                        {
                           required: true,
                           message: 'Выберите заявку'
                        }
                     ]
                  }
               >
                  <SelectLeads
                     disabled={isDisabledInput('leadId')}
                     mode={false}
                     initialLeads={initialValues?.lead ? [initialValues?.lead] : []}
                     leadIds={[
                        form.getFieldValue('leadId') || initialValues?.leadId
                     ]}
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     placeholder="Выберите заявку"
                     setField={(value) => {
                        form.setFieldValue('leadId', value);
                     }}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={24}>
               <Form.Item
                  name="status"
                  label="Статус"
                  rules={[
                     {
                        required: true,
                        message: 'Выберите статус'
                     }
                  ]}
                  defaultValue={statusesOfAuctions.active}
               >
                  <Select
                     disabled={isDisabledInput('status')}
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={actualStatuses}
                  />
               </Form.Item>
            </Col>
         </Row>
         <Row gutter={[16, 24]}>
            {isAuctionArchive && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col"
               >
                  <Alert
                     message="В статусе в архиве нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}
            <Col className="gutter-row" span={4} className="stats-card-count-col">
               <Form.Item>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={isLoading}
                     disabled={currentStatus === 'pending'}
                  >
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>

            {/* {isAuctionInActive && initialValues?.id && (
               <Col className="gutter-row" span={4} className="stats-card-count-col">
                  <Form.Item>
                     <StartAuctionButton id={auctionId} />
                  </Form.Item>
               </Col>
            )} */}
            {/* {isAuctionInPending && (
               <Col className="gutter-row" span={4} className="stats-card-count-col">
                  <Form.Item>
                     <AuctionPeersButton id={auctionId} />
                  </Form.Item>
               </Col>
            )} */}
         </Row>
      </Form>
   );
};

export default AcceptanceForm;
