import React from 'react';

import { Descriptions, Tag, Space, Typography } from 'antd';

import stringToColor from '@shared/utils/stringToColor';
import { unitSettings } from '@shared/const/units';

const { Text } = Typography;

const createdForm = {
   erp: 'из админки',
   public: 'публичный',
   mobileSellerApp: 'из мобильного приложения продавца'
};

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const leadDescriptionLabelsAndValues = [
   { label: 'Адрес', key: 'address', render: (_) => _?.address },
   {
      label: 'Район',
      key: 'address',
      render: (_) => _?.districtName || 'Не найден'
   },
   {
      label: 'Место вывоза',
      key: 'address',
      render: (_) => _?.typeOfAddress || 'Не найден'
   },
   {
      label: 'Вес',
      key: 'capacity',
      render: (_, record) =>
         _ ? `${_} ${suffix(record.materialCategory.unit)}` : `Не указан`
   },
   {
      label: 'Реальный вес',
      key: 'capacityReal',
      render: (_, record) =>
         _ ? `${_} ${suffix(record.materialCategory.unit)}` : `Не указан`
   },
   {
      label: 'Стоимость',
      key: 'price',
      render: (_, record) => (
         <Space size="small" direction="vertical" wrap>
            {_
               ? `${_} ${record.priceType === 'unit' ? 'руб/кг' : 'руб'}`
               : 'Бесплатно'}

            {_ && record.priceType === 'unit' ? (
               <Tag bordered={false}>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                     {_
                        ? `Общая: ${`${record?.finalPrice} руб` || 'неизвестно'}`
                        : ''}
                  </Text>
               </Tag>
            ) : null}
         </Space>
      )
   },
   {
      label: 'Откуда заявка',
      key: 'createdFrom',
      render: (_) => createdForm[_]
   },
   {
      label: 'Категория сырья',
      key: 'materialCategory',
      render: (_) => _?.name && <Tag color={stringToColor(_.name)}>{_.name}</Tag>
   }
];

const SimpleLeadInfo = ({ data }) => {
   return (
      <>
         <Descriptions size="small">
            {leadDescriptionLabelsAndValues.map((e) => (
               <Descriptions.Item
                  span={3}
                  key={`${data.id}-${e.label}`}
                  label={e.label}>
                  {e.render(data?.[e.key], data)}
               </Descriptions.Item>
            ))}
         </Descriptions>
      </>
   );
};

export default SimpleLeadInfo;
