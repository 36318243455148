import { getDriverCoordinates } from '@shared/api/all/driver';

export const GetDriverCoordinates = async () => {
   try {
      const response = await getDriverCoordinates();
      if (!response.json) throw new Error();
      return { data: response.json.data };
   } catch (e) {
      console.log(e);
   }
};
