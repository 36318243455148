import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import '@shared/assets/styles/main.scss';
import '@shared/assets/styles/override.scss';

import App from './app';
import reportWebVitals from './reportWebVitals';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import TimeAgo from 'javascript-time-ago';
import ru from 'javascript-time-ago/locale/ru';

TimeAgo.addLocale(ru);
TimeAgo.addDefaultLocale(ru);

import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { loadAndSavePaginationPageSize } from '@widgets/Pagination/ui/Pagination';

import { ConfigProvider } from 'antd';
import ruRU from 'antd/locale/ru_RU';

import * as Sentry from '@sentry/react';

const container = document.getElementById('root');
const root = createRoot(container);

dayjs.locale('ru');
dayjs.extend(utc);
dayjs.extend(tz);

dayjs.tz.setDefault('Etc/UCT');

loadAndSavePaginationPageSize();

Sentry.init({
   dsn: 'https://b29af345e4a9f83cd2ed81098a61b9c5@sentry.ecorium.tech/8',
   integrations: [
      new Sentry.BrowserTracing({
         // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
         tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/]
      }),
      new Sentry.Replay({
         maskAllText: false,
         blockAllMedia: false
      })
   ],
   // Performance Monitoring
   tracesSampleRate: 1.0, //  Capture 100% of the transactions
   // Session Replay
   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
   environment: process.env.REACT_APP_MODE
});

root.render(
   <BrowserRouter>
      <ConfigProvider
         locale={ruRU}
         theme={{
            components: {
               Button: {
                  defaultShadow:
                     '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)',
                  primaryShadow:
                     '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)',
                  dangerShadow:
                     '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)'
               },
               Pagination: {
                  itemActiveBg: '#309361',
                  colorPrimary: '#fff'
               }
            },
            token: {
               fontFamily: 'Inter, sans-serif',
               colorError: '#f34141',
               colorPrimary: '#309361',
               colorLink: '#309361',
               boxShadow:
                  '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)',
               boxShadowSecondary:
                  '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)',
               boxShadowTertiary:
                  '0px 1px 1px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 1px rgba(103, 110, 118, 0.16), 0px 2px 5px 0px rgba(103, 110, 118, 0.08)'
            }
         }}>
         <App />
      </ConfigProvider>
   </BrowserRouter>
);

reportWebVitals();
