import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getLeads } from '@shared/api/all/lead';

const LeadCount = ({ sellerId }) => {
   const [count, setCount] = useState(0);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      fetchSellersCount(sellerId);
   }, [sellerId]);

   const fetchSellersCount = async (sellerId) => {
      let rr = 0;
      setLoading(true);

      rr = await getLeads({
         pageSize: 1,
         page: 1,
         sellerId: sellerId
      })
         .then((res) => res?.json?.count || 0)
         .catch((e) => console.error(e));

      setLoading(false);
      setCount(rr);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   return count;
};

export { LeadCount };
