import React from 'react';
import TableRoutes from '@features/list-route/ui/TableRoutes';
import { Title } from '@shared/ui';

const RoutesPage = () => {
   return (
      <div>
         <Title>Список маршрутов</Title>
         <TableRoutes />
      </div>
   );
};

export default RoutesPage;
