import { Drawer, Button } from 'antd';
import { useState, useEffect } from 'react';
import CreateOrEditSeller from './';

const ModalSellerForm = ({ selectedSeller, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!selectedSeller?.id);
   }, [selectedSeller]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!selectedSeller?.id;

   return (
      <Drawer
         title={`Редактировать ${selectedSeller?.firstName} ${selectedSeller?.lastName}`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         onClose={handleCancel}
         footer={null}
         width={900}
         destroyOnClose={true}
         extra={<Button onClick={handleCancel}>Закрыть</Button>}>
         {isHaveData && (
            <CreateOrEditSeller
               id={selectedSeller.id}
               callbackOnSuccess={closeModal}
            />
         )}
      </Drawer>
   );
};

export default ModalSellerForm;
