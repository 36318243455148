import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'antd';
import CanDo from '@shared/lib/CanDo';

import {
   UserSwitchOutlined,
   CheckOutlined,
   UserAddOutlined
} from '@ant-design/icons';

import { getUserAuthData } from '@entitles/User';

import { VerticalSpace } from '@shared/ui';
import { useSelector } from 'react-redux';

import { GetUserById } from '@features/list-user/model/GetUserById';
import { Select, message, Badge } from 'antd';
import { UpdateLead } from '@features/list-lead/model/UpdateLead';
import { UpdateSeller } from '@features/form-seller/model/services/UpdateSeller';
import { getUsersList } from '@features/list-user/model/getUsersList';
import { statusesOfDrivers } from '@shared/const/statuses';

const { Option } = Select;

const getModifiedData = (data) => {
   if (!data) return [];

   return data.map((item) => ({
      ...item,
      selectLabel: `ID: ${item.id} ${item.firstName} ${item.lastName}`
   }));
};

const SelectManagerForm = ({ currentId, disabled = false, onSave, loading }) => {
   const [users, setUsers] = useState([]);
   const [selectedUserId, setSelectedUserId] = useState(null);

   useEffect(() => {
      if (currentId) {
         setSelectedUserId(currentId);
         fetchUserById(currentId);
      }
   }, [currentId]);

   const fetchUserById = async (id) => {
      if (!id) return;
      GetUserById(id).then((res) => {
         setUsers(res?.data ? getModifiedData([res.data]) : []);
      });
   };

   const fetchUsers = () => {
      getUsersList({
         page: 1,
         limit: 1000,
         sort: 'status',
         order: 'desc',
         op: 'or'
      }).then((res) => {
         const modifiedData = getModifiedData(res?.data);
         setUsers(modifiedData);
      });
   };

   const onFocus = () => {
      fetchUsers();
   };

   const onClear = () => {
      setSelectedUserId(null);
   };

   const onChange = (value, option) => {
      setSelectedUserId(value);
   };

   const onFilter = (input, option) => {
      if (!option?.label) {
         return false;
      }

      // eslint-disable-next-line no-unsafe-optional-chaining
      return (option?.label ? option?.label?.toLowerCase() : '').includes(
         input.toLowerCase()
      );
   };

   return (
      <>
         <Select
            style={{ width: '100%' }}
            disabled={disabled}
            showSearch
            allowClear
            onClear={onClear}
            placeholder="Выберите менеджера"
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            value={selectedUserId}
            filterOption={onFilter}
            optionLabelProp="label">
            {users.map((item) => (
               <Option
                  key={item.id + item.firstName}
                  value={item.id}
                  label={item.selectLabel}
                  info={item}>
                  <Badge
                     status={statusesOfDrivers[item.status].badgeStatus}
                     style={{ paddingRight: '8px' }}
                  />
                  {item.selectLabel}
               </Option>
            ))}
         </Select>
         <VerticalSpace />
         <Button
            onClick={() => onSave(selectedUserId)}
            loading={loading}
            disabled={selectedUserId === currentId}>
            Сохранить
         </Button>
      </>
   );
};

const ManageManagerOfSeller = ({ type, data, callback }) => {
   const userData = useSelector(getUserAuthData);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [loading, setLoading] = useState(false);

   if (!userData?.role) {
      return null;
   }

   const handleCancel = () => {
      setIsModalOpen(false);
      callback();
   };

   const save = async (selectedUserId) => {
      setLoading(true);

      if (type === 'lead') {
         await UpdateLead({ leadData: { userId: selectedUserId || null } }, data.id)
            .then(() => {
               message.success('Новый менеджер назначен');
               handleCancel();
            })
            .catch((e) => {
               message.error(e?.message || 'Возникла ошибка при сохранении');
            })
            .finally(() => setLoading(false));
      } else {
         await UpdateSeller({ userId: selectedUserId || null }, data.id)
            .then(() => {
               message.success('Новый менеджер назначен');
               handleCancel();
            })
            .catch((e) => {
               message.error(e?.message || 'Возникла ошибка при сохранении');
            })
            .finally(() => setLoading(false));
      }

      return;
   };

   if (userData?.role === 'manager' && data?.userId === null) {
      return (
         <Button icon={<CheckOutlined />} onClick={() => save(userData.id)}>
            Взять на себя
         </Button>
      );
   }

   return (
      <>
         <CanDo permission="can_edit_managers_of_seller">
            <Modal
               title={
                  data?.userId === null
                     ? 'Назначить менеджера'
                     : 'Поменять менеджера'
               }
               open={isModalOpen}
               onCancel={handleCancel}
               footer={null}
               width={400}
               key={`modal222-${data?.id}`}
               destroyOnClose={true}>
               <SelectManagerForm
                  currentId={data?.userId || null}
                  callback={handleCancel}
                  callBackOnSave={handleCancel}
                  onSave={save}
                  loading={loading}
               />
            </Modal>

            <Button
               icon={
                  data?.userId === null ? (
                     <UserAddOutlined />
                  ) : (
                     <UserSwitchOutlined />
                  )
               }
               loading={loading}
               onClick={() => setIsModalOpen(true)}>
               {data?.userId === null ? 'Назначить менеджера' : 'Поменять менеджера'}
            </Button>
         </CanDo>
      </>
   );
};

export default ManageManagerOfSeller;
