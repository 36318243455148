import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import CreateOrEditNote from './CreateOrEditNote';

const ModalNoteForm = ({ selectedData, closeModal, entityId, entityType }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!selectedData?.id);
   }, [selectedData]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!selectedData?.id;

   return (
      <Modal
         title={`Редактировать заметку ${selectedData?.id}`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         onClose={handleCancel}
         footer={null}
         width={600}
         destroyOnClose={true}>
         {isHaveData && (
            <CreateOrEditNote
               id={selectedData.id}
               callbackOnSuccess={closeModal}
               entityId={entityId}
               entityType={entityType}
            />
         )}
      </Modal>
   );
};

export default ModalNoteForm;
