import React, { useState, useEffect } from 'react';
import LeadForm from './LeadForm';
import { CreateLead } from '../model/CreateLead';
import { UpdateLead } from '../model/UpdateLead';
import { GetLead } from '../model/GetLead';
import { normalizeLeadTimes } from '@shared/lib/leadTimes';

import _ from 'lodash';

import { message } from 'antd';

const CreateOrEditLead = ({ id = null, callbackOnSuccess = () => {} }) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      if (!isEditForm) {
         return;
      }

      GetLead(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });

      return () => {
         setErrorMessage('');
         setLoadForm(false);
      };
   }, [id]);

   const onSuccess = async (values, setLoading) => {
      setLoading(true);

      if (values.price || values.price === 0) {
         values.price = parseFloat(values.price).toFixed(1);
      }

      if (values?.leadTimes?.length > 0) {
         const normalized = normalizeLeadTimes(values?.leadTimes, initialValues);
         values.leadTimes = normalized;
      }

      if (isEditForm) {
         const leadId = initialValues.id;
         const mergedObj = { ...initialValues, ...values };

         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         if (
            (updatedFields.status &&
               updatedFields.status !== initialValues.status) ||
            updatedFields?.statusComment
         ) {
            let obj = {
               status: updatedFields.status || initialValues.status,
               comment: updatedFields?.statusComment || ''
            };

            if (!updatedFields.status && initialValues.statusId) {
               obj.id = initialValues.statusId;
            }

            updatedFields.status = obj;
         }

         await UpdateLead(
            { leadData: updatedFields, images: values?.images || [] },
            leadId
         )
            .then(() => {
               callbackOnSuccess();
               message.success('Заявка изменена');
            })
            .catch((e) => {
               setErrorMessage(e?.message || 'Возникла ошибка при сохранении');
               message.error(e?.message || 'Возникла ошибка при сохранении');
            })
            .finally(() => setLoading(false));

         return;
      }

      if (values.status) {
         values.status = {
            status: values.status || 'active',
            comment: values?.statusComment || ''
         };
      }

      await CreateLead({ leadData: values, images: values?.images || [] })
         .then(() => {
            message.success('Заявка создана');
            callbackOnSuccess();
         })
         .catch((e) => {
            setErrorMessage(e?.message || 'Заявка не создана, возникла ошибка');
            message.error(e?.message || 'Заявка не создана, возникла ошибка');
         })
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <LeadForm
         errorMessage={errorMessage}
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
      />
   );
};

export default CreateOrEditLead;
