import { Form, Input } from '@shared/ui';
import { useState, useEffect } from 'react';
import { checkIsExistMobileNumberOfEntity } from '@shared/api/all/entity';
import { mobileNumberRequired } from '@shared/config/fieldValidatorSettings';
import _ from 'lodash';

const prefixSelector = <Form.Item noStyle>+7</Form.Item>;

const InputUniqueNumberOfEntity = ({
   entityType,
   disabled = false,
   name = 'mobileNumber'
}) => {
   const [searchStr, setSearchStr] = useState('');
   const [isExist, setIsExist] = useState(false);

   const checkNumber = (value) => {
      if (!value) {
         return;
      }

      checkIsExistMobileNumberOfEntity(entityType, value).then((res) => {
         const isExist = !!res?.json?.data;
         setIsExist(isExist);
      });
   };

   useEffect(() => {
      checkNumber(searchStr);
   }, [searchStr]);

   const debouncedSetNumber = _.debounce(setSearchStr, 500);

   return (
      <Form.Item
         // validateStatus={isExist ? 'error' : 'success'}
         // help={isExist ? 'Такой номер уже есть в базе' : ''}
         name={name}
         label="Телефон"
         rules={
            !disabled && [
               ...mobileNumberRequired,
               {
                  validator: () => {
                     if (isExist) {
                        return Promise.reject('Такой номер уже есть в базе');
                     }

                     return Promise.resolve();
                  }
               }
            ]
         }
      >
         <Input
            type="number"
            addonBefore={prefixSelector}
            style={{
               width: '100%'
            }}
            placeholder="Введите телефон"
            disabled={disabled}
            onChange={(e) => debouncedSetNumber(e.target.value)}
         />
      </Form.Item>
   );
};
export default InputUniqueNumberOfEntity;
