import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, Divider, InputNumber } from 'antd';
import { GetCategoriesListByParentId } from '../model/services/GetCategoriesListByParentId';
import { statusesOfCategories } from '@shared/const/statuses';
import { VerticalSpace } from '@shared/ui';
import { unitSettings } from '@shared/const/units';

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const MaterialCategoryForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   // eslint-disable-next-line no-unused-vars
   const [isLoadingCategories, setIsLoadingCategories] = useState(false);
   const [isDisabledButton, setIsDisabledButton] = useState(true);
   const [categories, setCategories] = useState([]);
   const { initialValues, onSuccess, isEditForm } = props;
   const [form] = Form.useForm();

   const fetchCategories = () => {
      setIsLoadingCategories(true);
      GetCategoriesListByParentId(
         0, // Main categories
         {
            page: 1,
            limit: 1000,
            sort: 'id',
            order: 'desc'
         }
      ).then((res) => {
         setCategories(res.data);
         setIsLoadingCategories(false);
      });
   };

   useEffect(() => {
      fetchCategories();
   }, []);

   useEffect(() => {
      form.setFieldsValue(initialValues);
   }, [form, initialValues]);

   const onFinish = (values) => {
      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const isDisabledCategoryChoose = isEditForm && initialValues.parentId === 0; // Если это редактируемая форма, и категория является главной то ее нельзя менять

   return (
      <Form
         name="basic"
         style={{
            maxWidth: 720,
            minWidth: 320
         }}
         form={form}
         initialValues={initialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         <Row gutter={16}>
            <Col span={24}>
               <Form.Item
                  name="name"
                  label="Название"
                  rules={[
                     {
                        required: true,
                        message: 'Введите название'
                     }
                  ]}>
                  <Input placeholder={'Введите название'} />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={24}>
               <Form.Item
                  name="parentId"
                  label="Категория"
                  rules={[
                     {
                        required: true,
                        message: 'Выберите категорию'
                     }
                  ]}>
                  <Select
                     placeholder="Выберите категорию"
                     label="role"
                     name="parentId"
                     onFocus={() => fetchCategories()}
                     disabled={isDisabledCategoryChoose}
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым'
                        }
                     ]}
                     fieldNames={{
                        label: 'name',
                        value: 'id'
                     }}
                     options={categories}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={8}>
               <Form.Item
                  name="unit"
                  label="Единица измерения"
                  rules={[
                     {
                        required: true,
                        message: 'Пропустили поле'
                     }
                  ]}>
                  <Select
                     defaultValue="kg"
                     style={{
                        width: '100%'
                     }}
                     options={unitSettings}
                  />
               </Form.Item>
            </Col>
            <Col span={8}>
               <Form.Item
                  name="priority"
                  label="Приоритет"
                  rules={[
                     {
                        required: true,
                        message: 'Укажите приоритет'
                     }
                  ]}>
                  <Input
                     style={{
                        width: '100%'
                     }}
                     placeholder={'Введите приоритет'}
                     type="number"
                  />
               </Form.Item>
            </Col>
            <Col span={8}>
               <Form.Item
                  name="status"
                  label="Статус"
                  rules={[
                     {
                        required: true,
                        message: 'Выберите статус'
                     }
                  ]}
                  defaultValue={statusesOfCategories.active}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={Object.values(statusesOfCategories)}
                  />
               </Form.Item>
            </Col>
         </Row>

         {/* <Row gutter={16}>
            <Col span={24} style={{ marginBottom: '10px' }}>
               <Form.Item noStyle>
                  <Divider orientation="center" plain>
                     Цена заявки {suffix(initialValues.unit)} в рублях
                  </Divider>
               </Form.Item>
            </Col>

            <Col span={12}>
               <Form.Item name="priceWithoutLoading">
                  <InputNumber
                     style={{
                        width: '100%'
                     }}
                     addonBefore={<Form.Item noStyle>без погрузки</Form.Item>}
                     addonAfter={
                        <Form.Item noStyle>
                           за {suffix(initialValues.unit)}
                        </Form.Item>
                     }
                     defaultValue="0.0"
                     type="number"
                     placeholder="Введите стоимость"
                     min="0"
                     step="0.1"
                     stringMode
                     precision={1}
                     decimalSeparator="."
                  />
               </Form.Item>
            </Col>

            <Col span={12}>
               <Form.Item name="priceWithLoading">
                  <InputNumber
                     style={{
                        width: '100%'
                     }}
                     addonBefore={<Form.Item noStyle>с погрузкой</Form.Item>}
                     addonAfter={
                        <Form.Item noStyle>
                           за {suffix(initialValues.unit)}
                        </Form.Item>
                     }
                     defaultValue="0.0"
                     type="number"
                     placeholder="Введите стоимость"
                     min="0"
                     step="0.1"
                     stringMode
                     precision={1}
                     decimalSeparator="."
                  />
               </Form.Item>
            </Col>
         </Row> */}

         <Row gutter={16}>
            <Col span={24} style={{ marginBottom: '10px' }}>
               <Form.Item noStyle>
                  <Divider orientation="center" plain>
                     Средняя цена за {suffix(initialValues.unit)} в рублях
                  </Divider>
               </Form.Item>
            </Col>

            <Col span={12}>
               <Form.Item name="averageBuyPrice">
                  <InputNumber
                     addonBefore={<Form.Item noStyle>выкупа</Form.Item>}
                     style={{
                        width: '100%'
                     }}
                     defaultValue="0.0"
                     type="number"
                     placeholder="Введите"
                     min="0"
                     step="0.1"
                     stringMode
                     precision={1}
                     decimalSeparator="."
                  />
               </Form.Item>
            </Col>
            <Col span={12}>
               <Form.Item name="averageSellPrice">
                  <InputNumber
                     addonBefore={<Form.Item noStyle>продажи</Form.Item>}
                     style={{
                        width: '100%'
                     }}
                     defaultValue="0.0"
                     type="number"
                     placeholder="Введите"
                     min="0"
                     step="0.1"
                     stringMode
                     precision={1}
                     decimalSeparator="."
                  />
               </Form.Item>
            </Col>
         </Row>

         <Form.Item>
            <Button
               type="primary"
               htmlType="submit"
               loading={isLoading}
               disabled={isDisabledButton}>
               {isEditForm ? 'Сохранить' : 'Создать'}
            </Button>
         </Form.Item>
      </Form>
   );
};

export default MaterialCategoryForm;
