import { updateRoute } from '@shared/api/all/route';

export const UpdateRoute = async (fields, id) => {
   try {
      const response = await updateRoute(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
