import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getRatingById = (id) => get(`/rating/${id}`);

export const deleteRatingById = (id) => _delete(`/rating/${id}`, {}, true);

export const sendSDLRatingById = (id) => get(`/sdl/${id}`);

export const getRatings = (params) => get(generateQueryParams(`/ratings`, params));
export const getRatingsSimple = (params) =>
   get(generateQueryParams(`/ratings/simple`, params));

export const createRating = (fields) =>
   post(
      '/rating',
      {
         ...fields
      },
      true
   );

export const updateRating = (fields, id) =>
   put(
      `/rating/${id}`,
      {
         ...fields
      },
      true
   );
