import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Tooltip } from 'antd';
import { Button, VerticalSpace } from '@shared/ui';
import { GetCategoriesListByParentId } from '../model/services/GetCategoriesListByParentId';
import {
   EditOutlined,
   FilterOutlined,
   DollarOutlined,
   FileImageOutlined
} from '@ant-design/icons';
import ModalMaterialCategoryForm from './ModalMaterialCategoryForm';
import ModalMaterialCategoryCapacityRuleForm from './ModalMaterialCategoryCapacityRuleForm';
import ModalMaterialCategoryPriceRuleForm from './ModalMaterialCategoryPriceRuleForm';

import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalButtonCategoryCreate from './ModalButtonCategoryCreate';
import CanDo from '@shared/lib/CanDo';
import { statusesOfCategories } from '@shared/const/statuses';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import { unitSettings } from '@shared/const/units';
import ModalIcon from './ModalIcon';

import { deleteCategoryById } from '@shared/api/all/category';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';

const columns = [
   {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: '120px',
      render: (_, record) => (
         <>
            {' '}
            {_}
            {record.icon ? (
               <img
                  src={record.icon}
                  className="image-category"
                  style={{ position: 'relative', top: '10px', left: '5px' }}
               />
            ) : null}
         </>
      )
   },
   {
      title: 'Подкатегория',
      dataIndex: 'name',
      key: 'name'
   },
   {
      title: 'Приоритет',
      dataIndex: 'priority',
      key: 'priority'
   },

   {
      title: 'Единица изм.',
      dataIndex: 'unit',
      key: 'unit',
      render: (_) => <>{unitSettings.find((e) => e.value === _)?.label}</>
   },
   {
      title: 'Средняя цена',
      dataIndex: 'capacityPrice',
      key: 'capacityPrice',
      render: (_, record) => (
         <>
            <div>
               {_ !== '0.0'
                  ? `${_} руб за
                     ${unitSettings.find((e) => e.value === record.unit)?.shortLabel}
                  `
                  : null}
            </div>
         </>
      )
   },
   {
      title: 'Средняя цена',
      dataIndex: 'averageBuyPrice',
      key: 'averageBuyPrice',
      render: (_, record) => (
         <>
            <div>
               {record.averageBuyPrice !== '0.0'
                  ? `Выкупа ${record.averageBuyPrice} руб за
                     ${unitSettings.find((e) => e.value === record.unit)?.shortLabel}
                  `
                  : null}
            </div>
            <div>
               {record.averageSellPrice !== '0.0'
                  ? `Продажи ${record.averageSellPrice} руб за
                     ${unitSettings.find((e) => e.value === record.unit)?.shortLabel}
                  `
                  : null}
            </div>

            {record.averageSellPrice === '0.0' && record.averageBuyPrice === '0.0'
               ? 'Не указано'
               : null}
         </>
      )
   },

   // {
   //    title: 'Цена заявки',
   //    dataIndex: 'priceWithoutLoading',
   //    key: 'priceWithoutLoading',
   //    render: (_, record) => (
   //       <>
   //          <div>
   //             {record.priceWithoutLoading !== '0.0'
   //                ? `Без погрузки ${record.priceWithoutLoading} руб за
   //                   ${unitSettings.find((e) => e.value === record.unit)?.shortLabel}
   //                `
   //                : null}
   //          </div>
   //          <div>
   //             {record.priceWithLoading !== '0.0'
   //                ? `С погрузкой ${record.priceWithLoading} руб за
   //                   ${unitSettings.find((e) => e.value === record.unit)?.shortLabel}
   //                `
   //                : null}
   //          </div>
   //       </>
   //    )
   // },
   {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (_) => (
         <Tag color={statusesOfCategories[_]?.color}>
            {statusesOfCategories[_]?.label}
         </Tag>
      )
   }
];

const NestedTableCategories = ({
   id,
   selectedCategory,
   setSelectedCategory,
   setSelectedCategoryForRule,
   setSelectedCategoryForPriceRule
}) => {
   const [data, setData] = useState([]);

   const nestedColumns = [
      ...columns,

      {
         title: 'Действие',
         key: 'action',
         render: (_, record) => (
            <Space size="small">
               <CanDo permission="can_edit_categories">
                  <Tooltip placement="top" title={'Редактировать'}>
                     <Button
                        onClick={() => setSelectedCategory(record)}
                        type="primary">
                        <EditOutlined />
                     </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={'Ограничения к весу'}>
                     <Button
                        onClick={() => setSelectedCategoryForRule(record)}
                        style={{ marginLeft: '10px' }}>
                        <FilterOutlined />
                     </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={'Настройки цен'}>
                     <Button
                        onClick={() => setSelectedCategoryForPriceRule(record)}
                        style={{ marginLeft: '10px' }}>
                        <DollarOutlined />
                     </Button>
                  </Tooltip>{' '}
                  <span>
                     <ModalIcon categoryId={record.id} icon={record.icon} />{' '}
                  </span>
               </CanDo>
               {record.status === 'archive' && (
                  <DeleteEntityButton
                     id={record.id}
                     callbackFetch={deleteCategoryById}
                     update={fetchData}
                     permission="can_delete_categories"
                  />
               )}
            </Space>
         )
      }
   ];

   useEffect(() => {
      fetchData();

      // TODO не срабатывает когда в моменте создаешь категорию при открытом нест
   }, [selectedCategory]);

   const fetchData = () => {
      GetCategoriesListByParentId(id, 1, 1000).then((res) => {
         setData(res.data);
      });
   };

   return (
      <Table
         rowKey="id"
         columns={nestedColumns}
         dataSource={data}
         pagination={false}
      />
   );
};

const TableCategories = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedCategory, setSelectedCategory] = useState(null);
   const [selectedCategoryForRule, setSelectedCategoryForRule] = useState(null);
   const [selectedCategoryForPriceRule, setSelectedCategoryForPriceRule] =
      useState(null);

   const [expandedRowKeys, setExpandedRowKeys] = useState([]);
   const [pagination, setPagination] = useState({ ...initialPaginationSettings() });

   const actions = [
      {
         title: 'Действие',
         key: 'action',
         render: (_, record) => (
            <Space size="small">
               <CanDo permission="can_edit_categories">
                  <Tooltip placement="top" title={'Редактировать'}>
                     <Button
                        onClick={() => setSelectedCategory(record)}
                        type="primary">
                        <EditOutlined />
                     </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={'Ограничения к весу'}>
                     <Button
                        onClick={() => setSelectedCategoryForRule(record)}
                        style={{ marginLeft: '10px' }}>
                        <FilterOutlined />
                     </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={'Настройки цен'}>
                     <Button
                        onClick={() => setSelectedCategoryForPriceRule(record)}
                        style={{ marginLeft: '10px' }}>
                        <DollarOutlined />
                     </Button>
                  </Tooltip>
                  <span>
                     <ModalIcon categoryId={record.id} icon={record.icon} />{' '}
                  </span>
               </CanDo>
               {record.status === 'archive' && (
                  <DeleteEntityButton
                     id={record.id}
                     callbackFetch={deleteCategoryById}
                     update={fetchData}
                     permission="can_delete_categories"
                  />
               )}
            </Space>
         )
      }
   ];

   const closeModal = () => {
      setSelectedCategory(null);
      setSelectedCategoryForRule(null);
      setSelectedCategoryForPriceRule(null);
      fetchData();
   };

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = (
      current = pagination.current,
      pageSize = pagination.pageSize
   ) => {
      setIsLoading(true);
      GetCategoriesListByParentId(0, {
         page: current,
         limit: pageSize,
         sort: 'priority',
         order: 'asc'
      }).then((res) => {
         setIsLoading(false);
         setPagination((prev) => ({
            ...prev,
            total: res.count,
            current,
            pageSize
         }));
         const tableData = res.data.filter((item) => item.id !== 0);
         setData(tableData);
      });
   };

   const onChangePagination = (current, pageSize) => {
      fetchData(current, pageSize);
   };

   const mainCategories = data.filter((item) => item.parentId === 0);

   const onTableRowExpand = (expanded, { id }) => {
      setExpandedRowKeys(expanded ? [id] : []);
   };

   return (
      <>
         <ModalMaterialCategoryForm
            selectedCategory={selectedCategory}
            closeModal={closeModal}
         />

         <ModalMaterialCategoryCapacityRuleForm
            selectedCategory={selectedCategoryForRule}
            closeModal={closeModal}
         />

         <ModalMaterialCategoryPriceRuleForm
            selectedCategory={selectedCategoryForPriceRule}
            closeModal={closeModal}
         />

         <CanDo permission="can_create_categories">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonCategoryCreate closeModal={closeModal} />
            </Space>
         </CanDo>
         <VerticalSpace />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
               </Space>
            </div>
         </div>

         <Table
            rowKey="id"
            columns={[...columns, ...actions]}
            dataSource={mainCategories}
            expandedRowKeys={expandedRowKeys}
            onExpand={onTableRowExpand}
            expandable={{
               expandedRowRender: (record) => (
                  <NestedTableCategories
                     id={record.id}
                     setSelectedCategory={setSelectedCategory}
                     selectedCategory={selectedCategory}
                     setSelectedCategoryForRule={setSelectedCategoryForRule}
                     setSelectedCategoryForPriceRule={
                        setSelectedCategoryForPriceRule
                     }
                  />
               ),
               defaultExpandedRowKeys: ['0']
            }}
            pagination={false}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableCategories;
