const regions = {
   ЦАО: {
      label: 'Центральный',
      value: 'ЦАО'
   },
   САО: {
      label: 'Северный',
      value: 'САО'
   },
   СВАО: {
      label: 'Северо-Восточный',
      value: 'СВАО'
   },
   ВАО: {
      label: 'Восточный',
      value: 'ВАО'
   },
   ЮВАО: {
      label: 'Юго-Восточный',
      value: 'ЮВАО'
   },
   ЮАО: {
      label: 'Южный',
      value: 'ЮАО'
   },
   ЮЗАО: {
      label: 'Юго-Западный',
      value: 'ЮЗАО'
   },
   ЗАО: {
      label: 'Западный',
      value: 'ЗАО'
   },
   СЗАО: {
      label: 'Северо-Западный',
      value: 'СЗАО'
   }
};

export default regions;
