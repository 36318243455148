import React, { useState, useEffect } from 'react';
import { StatusTag } from '@shared/ui';
import { Space, Spin, Button, Typography, Tag, Collapse, Divider } from 'antd';
import { useNavigate } from 'react-router-dom';
import { DownloadOutlined } from '@ant-design/icons';
import { GetRouteList } from '@features/list-route/model/GetRouteList';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';
import ListLeadsOfRoute from '@features/list-route/ui/ListLeadsOfRoute';

const LIMIT = 10;
const ListOfRoutesOfDriver = ({ driverId }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [count, setCount] = useState(1);

   const navigate = useNavigate();

   const fetchData = (driverId, currentPage) => {
      setLoading(true);
      GetRouteList({
         page: currentPage,
         limit: LIMIT,
         sort: 'id',
         order: 'desc',
         driverId: driverId
      })
         .then((res) => {
            setData((e) => [...e, ...res.data]);
            setLoading(false);
            setCurrentPage(currentPage);
            setCount(res.count);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   useEffect(() => {
      fetchData(driverId, currentPage);
   }, [driverId, currentPage]);

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const isDisabled = count - currentPage * LIMIT <= 0;

   return (
      <Space direction="vertical" style={{ width: '100%' }}>
         <Divider orientation="left">
            Маршруты водителя <Tag bordered={false}>{count}</Tag>
         </Divider>

         <Collapse defaultActiveKey={['0']} style={{ width: '100%' }}>
            {data.map((element, i) => (
               <Collapse.Panel
                  header={
                     <div key={`list-leads-route-${element.id}`}>
                        <span
                           className="green-span-url"
                           style={{ padding: '5px 0' }}
                           onClick={() => navigate(`/routes/${element.id}`)}>
                           {`Маршрут №${element.id}`}
                        </span>
                        {' • '}
                        <StatusTag status={element.status} type="route" />
                        {' • '}
                        Заявок: {element.leads.length}
                        {' • '}
                        <Tag bordered={false}>
                           {getTimeAgo(
                              dayjs(element.statusObj.createdAt).unix() * 1000
                           )}
                        </Tag>
                     </div>
                  }
                  key={i}>
                  <div style={{ paddingLeft: '24px' }}>
                     <ListLeadsOfRoute leads={element.leads} />
                  </div>
               </Collapse.Panel>
            ))}
         </Collapse>

         <Space
            direction="horizontal"
            style={{ width: '100%', justifyContent: 'center', marginTop: '15px' }}>
            <Button
               type="primary"
               loading={loading}
               icon={<DownloadOutlined />}
               onClick={() => setCurrentPage((e) => e + 1)}
               disabled={isDisabled}>
               Загрузить больше
            </Button>
         </Space>
      </Space>
   );
};

export default ListOfRoutesOfDriver;
