import { updateAuction } from '@shared/api/all/auction';

export const UpdateAuction = async (fields, id) => {
   try {
      const response = await updateAuction(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
