import { updateNote } from '@shared/api/all/note';

export const UpdateNote = async (fields, id) => {
   try {
      const response = await updateNote(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
