import { Button, Modal, Space, message } from 'antd';
import { useState } from 'react';
import CreateOrEditRating from './CreateOrEditRating';
import { StarOutlined } from '@ant-design/icons';
import { sendSDLRatingById } from '@shared/api/all/rating';
import { Alert } from '@shared/ui';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const ModalButtonRatingCreateToEntityWithSMS = (props) => {
   const { closeModal = () => {}, isSendedSmsRating, buttonText = '' } = props;
   const isEditForm = props?.id;
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [loading, setLoading] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const showConfirm = () => {
      return confirm({
         title: 'Подтвердите действие',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         onOk() {
            sendSms();
         },
         okText: 'Отправить смс',
         cancelText: 'Отмена'
      });
   };

   const sendSms = () => {
      setLoading(true);
      sendSDLRatingById(props?.leadId)
         .then(() => {
            setLoading(false);
            message.success('Смс отправлен');
         })
         .catch((e) => {
            message.error(e.message);
            setLoading(false);
         });
   };

   return (
      <>
         <Button
            type={isEditForm ? 'primary' : 'default'}
            icon={<StarOutlined />}
            onClick={showModal}>
            {buttonText}
         </Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            title={`Оценка водителю за заявку от продавца`}
            footer={null}
            width={400}
            destroyOnClose={true}>
            {isEditForm ? (
               <CreateOrEditRating
                  disabledForEditing={true}
                  callbackOnSuccess={handleCancel}
                  {...props}
               />
            ) : (
               <div style={{ marginBottom: '15px' }}>
                  {isSendedSmsRating ? (
                     <Alert
                        message="Смс отправлен, оценку еще не поставили"
                        type="success"
                        showIcon
                     />
                  ) : (
                     <>
                        <Alert
                           message={
                              <>
                                 <Space size="middle">
                                    Смс рассылка с ссылкой на оценку отправляется
                                    через сутки после завершения заявки
                                    автоматически, и только 1 раз. Можно дожидаться
                                    рассылки, либо нажать на кнопку.
                                 </Space>

                                 <Space size="middle" style={{ marginTop: '10px' }}>
                                    <Button
                                       type="primary"
                                       disabled={loading}
                                       onClick={showConfirm}>
                                       Отправить смс
                                    </Button>{' '}
                                 </Space>
                              </>
                           }
                           type="info"
                        />
                     </>
                  )}
               </div>
            )}
            <Alert
               message="Эти оценки ставятся только с помощью смс рассылки, и можно только частично редактировать."
               type="info"
               showIcon
            />
         </Modal>
      </>
   );
};

export default ModalButtonRatingCreateToEntityWithSMS;
