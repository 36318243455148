import { Select, Badge } from 'antd';
import { useState, useEffect } from 'react';
import { GetDriversList } from '@features/list-driver/model/GetDriversList';
import { getDriverById } from '@shared/api/all/driver';

import { statusesOfDrivers } from '@shared/const/statuses';

const generateSelectLabelString = (driver) => {
   return `${driver?.firstName} ${driver?.lastName} ${driver?.mobileNumber}`;
};

const { Option } = Select;

const SelectDriverSimple = ({ setField, driverId }) => {
   const [drivers, setDrivers] = useState([]);
   const [searchParams, setSearchParams] = useState({});
   const [selectedDriverId, setSelectedDriverId] = useState(null);
   const [choosedDriver, setChoosedDriver] = useState(null);

   useEffect(() => {
      fetchDrivers(searchParams);
   }, [searchParams]);

   useEffect(() => {
      if (driverId) {
         fetchOneDriver(driverId);
      }
   }, [driverId]);

   const fetchOneDriver = (id) => {
      getDriverById(id).then((res) => {
         if (!res?.json?.data?.id) {
            return;
         }
         const modifiedData = [res.json.data].map((item) => ({
            ...item,
            selectLabel: generateSelectLabelString(item)
         }));

         setSelectedDriverId(id);
         setDrivers([...modifiedData]);
         setChoosedDriver(modifiedData[0]);
      });
   };

   const fetchDrivers = (searchParams = {}) => {
      GetDriversList({
         page: 1,
         limit: 10,
         sort: 'status',
         order: 'desc',
         op: 'or',
         ...searchParams
      }).then((res) => {
         const modifiedData = res.data.map((item) => ({
            ...item,
            selectLabel: generateSelectLabelString(item)
         }));
         setDrivers([...modifiedData]);
      });
   };

   const onFocus = () => {
      fetchDrivers(searchParams);
   };

   const onClear = () => {
      setField(null);
   };

   const onChange = (value) => {
      setField(value);
      setSelectedDriverId(value);
   };

   const onSearch = (value) => {
      setSearchParams(
         value ? { mobileNumber: value, firstName: value, lastName: value } : {}
      );
   };

   return (
      <Select
         showSearch
         allowClear
         onClear={onClear}
         placeholder="Введите номер телефона"
         optionFilterProp="children"
         onChange={onChange}
         onFocus={onFocus}
         value={choosedDriver?.selectLabel}
         onSearch={onSearch}
         filterOption={false}
         optionLabelProp="label"
         style={{ width: '100%' }}>
         {drivers.map((item) => (
            <Option
               key={item.id + item.firstName}
               value={item.id}
               label={item.selectLabel}
               info={item}>
               <Badge
                  status={statusesOfDrivers[item.status].badgeStatus}
                  style={{ paddingRight: '8px' }}
               />
               {item.selectLabel}
            </Option>
         ))}
      </Select>
   );
};
export default SelectDriverSimple;
