import { Select } from 'antd';
import { useState, useEffect } from 'react';
import { GetCategoriesList } from '@features/manage-materialcategories/model/services/GetCategoriesList';

const SelectCategoriesOfEntity = ({
   initialCategories,
   setField,
   disabled = false
}) => {
   const [categories, setCategories] = useState([]);
   const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([]);

   useEffect(() => {
      if (initialCategories?.length > 0) {
         const catIds = [];
         const initialCatList = [];
         initialCategories.map((item) => {
            if (!item.materialCategory?.name) {
               return;
            }

            catIds.push(item.materialCategoryId);

            initialCatList.push({
               ...item,
               selectLabel: item.materialCategory.name,
               selectId: item.materialCategory.id
            });
         });

         setCategories(initialCatList);
         setSelectedCategoriesIds(catIds);
      }
   }, [initialCategories]);

   const fetchCategories = () => {
      GetCategoriesList({
         page: 1,
         limit: 1000,
         sort: 'priority',
         order: 'asc',
         status: 'active'
      }).then((res) => {
         const modifiedData = res.data.map((item) => ({
            ...item,
            selectLabel: item.name,
            selectId: item.id
         }));

         setCategories(modifiedData);
      });
   };

   const onFocus = () => {
      fetchCategories();
   };

   const onChange = (value) => {
      setField(value);
      setSelectedCategoriesIds(value);
   };

   return (
      <Select
         mode="multiple"
         disabled={disabled}
         showSearch
         allowClear
         placeholder="Выберите категории"
         optionFilterProp="selectLabel"
         onChange={onChange}
         onFocus={onFocus}
         value={selectedCategoriesIds}
         filterOption={true}
         rules={[
            {
               required: true,
               message: 'Поле не может быть пустым'
            }
         ]}
         fieldNames={{
            label: 'selectLabel',
            value: 'selectId'
         }}
         options={categories}
      />
   );
};
export default SelectCategoriesOfEntity;
