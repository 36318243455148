import { updateFaq } from '@shared/api/all/faq';

export const UpdateFaq = async (fields, id) => {
   try {
      const response = await updateFaq(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
