import React, { useEffect } from 'react';
import { notification } from 'antd';
import { LAST_SHOWED_NOTIFICATION_ID } from '@shared/const/localStorage';
import {
   getLocalStorageByKey,
   setLocalStorageByKey
} from '@shared/lib/localStorage';
import dayjs from 'dayjs';

// NOTIFY ONLY LAST ONE

const NotificationsCreate = ({ data }) => {
   const [api, contextHolder] = notification.useNotification();

   useEffect(() => {
      createNotify();
   }, [data]);

   const openNotification = (obj) => {
      api.info({
         message: obj.title,
         description: obj.description,
         placement: 'topRight'
      });
   };

   const createNotify = async () => {
      const lastShowedId = await getLocalStorageByKey(LAST_SHOWED_NOTIFICATION_ID);

      if (lastShowedId > data?.[0]?.id) {
         setLocalStorageByKey(LAST_SHOWED_NOTIFICATION_ID, data[0].id);
      }

      if (data?.length >= 1 && data?.[0]?.createdAt) {
         const nowTime = dayjs();
         const notifyTime = dayjs(data[0]?.createdAt);

         const minutes = nowTime.diff(notifyTime) / 60000;

         if (minutes > 3) {
            return;
         }

         if (data[0].id == lastShowedId) {
            return;
         }

         openNotification(data[0]);
         setLocalStorageByKey(LAST_SHOWED_NOTIFICATION_ID, data[0].id);
      }
   };

   return <>{contextHolder}</>;
};

export default NotificationsCreate;
