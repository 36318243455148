import { Button, Modal } from 'antd';
import { useState } from 'react';
import CreateOrEditRating from './CreateOrEditRating';
import { StarOutlined } from '@ant-design/icons';

const ModalButtonRatingCreateToEntity = (props) => {
   const { closeModal = () => {} } = props;
   const isEditForm = props?.id;
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   return (
      <>
         <Button
            type={isEditForm ? 'primary' : 'default'}
            icon={<StarOutlined />}
            onClick={showModal}
         ></Button>
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            title={`Оценка водителю за маршрут`}
            footer={null}
            width={400}
            destroyOnClose={true}
         >
            <CreateOrEditRating callbackOnSuccess={handleCancel} {...props} />
         </Modal>
      </>
   );
};

export default ModalButtonRatingCreateToEntity;
