import React, { useState } from 'react';
import { Button } from '@shared/ui';
import { GetAuction } from './../model/GetAuction';

import { message, Modal } from 'antd';
import { List } from 'antd';

const AuctionResultsButton = ({ id }) => {
   // eslint-disable-next-line no-unused-vars
   const [isLoading, setLoading] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [price, setPrice] = useState(0);
   const [data, setData] = useState([]);

   const showModal = () => {
      setIsModalOpen(true);
      fetchData();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const fetchData = async () => {
      setLoading(true);

      await GetAuction(id)
         .then((res) => {
            const winnerId = res.winnerId;
            const winnerObj = res.auctionPrices.find(
               (e) => e.acceptanceId === winnerId
            );

            setData([winnerObj?.acceptance || {}]);
            setPrice(winnerObj?.price || 0);
         })
         .catch(() => message.error('Возникла ошибка'))
         .finally(() => setLoading(false));
   };

   return (
      <>
         <Button danger type="primary" onClick={showModal}>
            Результаты торга
         </Button>

         <Modal
            title="Результаты"
            open={isModalOpen}
            onCancel={handleCancel}
            footer={null}
         >
            <List
               style={{ marginTop: '-10px' }}
               itemLayout="horizontal"
               dataSource={data}
               renderItem={(item) => (
                  <List.Item
                     style={{
                        padding: '15px'
                     }}
                  >
                     <List.Item.Meta
                        title={`Выиграла приемка №${item.id}`}
                        description={
                           <div style={{ color: '#000', fontSize: '14px' }}>
                              <div>{`Название: ${item.title}`}</div>
                              <div>{`Имя: ${item.firstName}, ${item.lastName}`}</div>
                              <div>{`Выигрышная ставка: ${price} руб.`}</div>
                              <div>{`Телефон: ${item.mobileNumber}`}</div>
                           </div>
                        }
                     />
                  </List.Item>
               )}
            />
         </Modal>
      </>
   );
};

export default AuctionResultsButton;
