import { useState, useEffect } from 'react';
import { Spin } from '@shared/ui';

const LigaPMScript = () => {
   const [isLoaded, setIsLoaded] = useState(false);

   useEffect(() => {
      setTimeout(() => {
         setIsLoaded(true);
      }, 1000);
   }, []);

   if (!isLoaded) {
      return (
         <div className="liga-pm-iframe">
            <Spin />
         </div>
      );
   }

   return (
      <div>
         <iframe
            className="liga-pm-iframe"
            src="/widget.html"
            frameBorder="0"
            style={{ overflowX: 'hidden' }}
         ></iframe>
      </div>
   );
};

export default LigaPMScript;
