import { Modal } from 'antd';
import { useState } from 'react';
import CreateOrEditRating from './CreateOrEditRating';

const ModalButtonRatingCreate = ({ closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   return (
      <>
         {/* <Button type="primary" onClick={showModal} icon={<PlusOutlined />}>
            Создать рейтинг
         </Button> */}
         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            title={`Создать рейтинг`}
            footer={null}
            width={400}
            destroyOnClose={true}
         >
            <CreateOrEditRating id={null} callbackOnSuccess={handleCancel} />
         </Modal>
      </>
   );
};

export default ModalButtonRatingCreate;
