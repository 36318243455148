import { useState, useEffect } from 'react';
import { Empty, Row, Col, Rate, Space, Button, Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { GetRatingSimpleList } from '@features/list-rating/model/GetRatingSimpleList';

const RatingListOfEntity = ({ settings }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [count, setCount] = useState(null);
   const [data, setData] = useState([]);
   const [pagination, setPagination] = useState({
      page: 0,
      sorter: {
         sort: 'id',
         order: 'desc'
      }
   });

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = () => {
      if (isLoading) {
         return;
      }

      setIsLoading(true);

      let page = pagination.page + 1;

      GetRatingSimpleList({
         page,
         // type,
         ...settings,
         limit: 5,
         ...pagination.sorter
      }).then((res) => {
         setData([...data, ...res.data]);
         setIsLoading(false);
         setCount(res.count);
         setPagination({ ...pagination, page });
      });
   };

   if (!data?.length) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
   }

   return (
      <>
         <div
            className="description-comment-block"
            style={{ whiteSpace: 'pre-wrap' }}>
            {data.map((e) => (
               <>
                  <Row gutter={24}>
                     <Col span={24}>
                        <Rate
                           allowHalf
                           disabled={true}
                           allowClear={true}
                           defaultValue={0}
                           value={e?.value / 10}
                        />
                     </Col>

                     <Col span={24} style={{ marginTop: '15px' }}>
                        Комментарий к оценке: {e?.comment || 'Без комментариев'}
                     </Col>
                  </Row>
                  <Divider />
               </>
            ))}

            {count !== 0 && (
               <Space
                  direction="horizontal"
                  style={{ width: '100%', justifyContent: 'center' }}>
                  <Button
                     type="primary"
                     loading={isLoading}
                     icon={<DownloadOutlined />}
                     onClick={() => fetchData()}
                     disabled={count === data.length}>
                     Загрузить больше
                  </Button>
                  {count && `Всего ${count}`}
               </Space>
            )}
         </div>
      </>
   );
};

export default RatingListOfEntity;
