/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { statusesOfRatings } from '@shared/const/statuses';

// import { useSelector } from 'react-redux';
import { Col, Row, Rate, Space } from 'antd';
// import SelectSeller from '@widgets/FormItems/SelectSeller';
import dayjs from 'dayjs';
// import SelectLeads from '@widgets/FormItems/SelectLeads';
// import SelectDriver from '@widgets/FormItems/SelectDriver';
// import SelectUser from '@widgets/FormItems/SelectUser';
// import SelectRoute from '@widgets/FormItems/SelectRoute';
// import { getUserAuthData } from '@entitles/User';

// const typesOfEntitiesFromRating = [
//    {
//       label: 'Продавец',
//       value: 'seller'
//    },
//    {
//       label: 'Пользователь',
//       value: 'user'
//    }
// ];

// const typesOfEntitiesToRating = [
//    {
//       label: 'Продавец',
//       value: 'seller'
//    },
//    {
//       label: 'Водитель',
//       value: 'driver'
//    }
// ];

// const typesOfActionsOfRating = [
//    {
//       label: 'Маршрут',
//       value: 'route'
//    },
//    {
//       label: 'Заявка',
//       value: 'lead'
//    }
// ];

// const typesOfRatings = [
//    {
//       label: 'Общая',
//       value: 1
//    }
// ];

const { TextArea } = Input;

const RatingForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const {
      initialValues,
      onSuccess,
      isEditForm,
      valuesOfRating = {},
      disabledForEditing = false
   } = props;
   const simpleRatingKeys = Object.keys(valuesOfRating);

   const [form] = Form.useForm();
   // const userInfo = useSelector(getUserAuthData);
   // const userId = userInfo?.id;

   const onFinish = (values) => {
      if (valuesOfRating) {
         values = { ...valuesOfRating, ...values };
      }

      values.value = values.valueInFloat * 10;

      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      ...initialValues
   };

   const currentStatus = initialValues?.status || 'active';

   const isDisabledInput = (inputName) => {
      if (disabledForEditing) {
         return true;
      }

      const list = {
         addressId: ['active'],
         sellerId: ['active'],
         valueInFloat: ['active'],
         capacity: ['active', 'inwork', 'finished'],
         materialCategoryId: ['active'],
         status: ['active', 'inwork', 'finished'],
         comment: ['active', 'inwork', 'finished'],
         images: ['active', 'inwork']
      };

      return !list[inputName].includes(currentStatus);
   };

   const actualStatuses = [statusesOfRatings.active, statusesOfRatings.archive];

   // const EntitySelect = ({ type, fieldName, disabled = false }) => {
   //    const entityId = form.getFieldValue(fieldName) || initialValues?.[fieldName];

   //    if (type === 'driver') {
   //       return (
   //          <SelectDriver
   //             disabled={disabled}
   //             driver={{}}
   //             driverId={entityId}
   //             setField={(value) => {
   //                form.setFieldValue(fieldName, value);
   //             }}
   //          />
   //       );
   //    }

   //    if (type === 'seller') {
   //       return (
   //          <SelectSeller
   //             disabled={disabled}
   //             seller={null}
   //             sellerId={entityId}
   //             setField={(value) => {
   //                form.setFieldValue(fieldName, value);
   //             }}
   //          />
   //       );
   //    }

   //    if (type === 'user') {
   //       return (
   //          <SelectUser
   //             disabled={disabled}
   //             user={userInfo || null}
   //             userId={entityId}
   //             setField={(value) => {
   //                form.setFieldValue(fieldName, value);
   //             }}
   //          />
   //       );
   //    }
   // };

   // const ActionSelect = ({ type, fieldName, disabled }) => {
   //    const entityId = form.getFieldValue(fieldName) || initialValues?.[fieldName];

   //    if (type === 'lead') {
   //       return (
   //          <SelectLeads
   //             mode={false}
   //             disabled={disabled}
   //             initialLeads={[]}
   //             leadIds={[entityId]}
   //             setField={(value) => {
   //                form.setFieldValue(fieldName, value);
   //             }}
   //          />
   //       );
   //    }

   //    if (type === 'route') {
   //       return (
   //          <SelectRoute
   //             route={null}
   //             disabled={disabled}
   //             routeId={entityId}
   //             setField={(value) => {
   //                form.setFieldValue(fieldName, value);
   //             }}
   //          />
   //       );
   //    }
   // };

   // const selectYourself = () => {
   //    form.setFieldValue('fromEntityType', 'user');
   //    form.setFieldValue('fromEntityId', userId);
   // };

   return (
      <Form
         style={{
            maxWidth: 720,
            minWidth: 320
         }}
         form={form}
         initialValues={modifiedInitialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         hideRequiredMark
         layout="vertical">
         {() => (
            <>
               {/* <Row gutter={24}>
                  {!simpleRatingKeys.includes('fromEntityId') && (
                     <Col span={12}>
                        <Divider orientation="center">От</Divider>
                        <Row gutter={24}>
                           <Col span={24}>
                              <Form.Item
                                 name="fromEntityType"
                                 label={
                                    <span>
                                       Тип сущности
                                        <Button
                                          onClick={() => selectYourself()}
                                          type="link"
                                          style={{
                                             padding: 0,
                                             margin: 0,
                                             marginLeft: '10px',
                                             height: 'auto'
                                          }}>
                                          Выбрать себя
                                       </Button> 
                                    </span>
                                 }
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Пропустили поле'
                                    }
                                 ]}>
                                 <Select
                                    disabled={true}
                                    allowClear
                                    style={{
                                       width: '100%'
                                    }}
                                    placeholder="Выберите тип"
                                    options={typesOfEntitiesFromRating}
                                    onChange={() =>
                                       form.setFieldValue('fromEntityId', undefined)
                                    }
                                 />
                              </Form.Item>
                           </Col>

                           <Col span={24}>
                              {form.getFieldValue('fromEntityType') && (
                                 <Form.Item
                                    name="fromEntityId"
                                    label="Cущность"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Пропустили поле'
                                       }
                                    ]}>
                                    <EntitySelect
                                       disabled={true}
                                       fieldName="fromEntityId"
                                       type={form.getFieldValue('fromEntityType')}
                                    />
                                 </Form.Item>
                              )}
                           </Col>
                        </Row>
                     </Col>
                  )}
                  {!simpleRatingKeys.includes('toEntityId') && (
                     <Col span={12}>
                        <Divider orientation="center">Кому</Divider>

                        <Row gutter={24}>
                           <Col span={24}>
                              <Form.Item
                                 name="toEntityType"
                                 label="Тип сущности"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Пропустили поле'
                                    },
                                    {
                                       validator: () => {
                                          if (
                                             form.getFieldValue('fromEntityType') ===
                                             form.getFieldValue('toEntityType')
                                          ) {
                                             return Promise.reject(
                                                'Нельзя ставить одинаковым группам сущностям оценку'
                                             );
                                          }

                                          return Promise.resolve();

                                          // if (form.getFieldValue("driverId")) {
                                          //    return Promise.resolve();
                                          // } else {
                                          //    return Promise.reject("Some message here");
                                          // }
                                       }
                                    }
                                 ]}>
                                 <Select
                                    allowClear
                                    style={{
                                       width: '100%'
                                    }}
                                    disabled={true}
                                    placeholder="Выберите тип"
                                    options={typesOfEntitiesToRating}
                                    onChange={() =>
                                       form.setFieldValue('toEntityId', undefined)
                                    }
                                 />
                              </Form.Item>
                           </Col>

                           <Col span={24}>
                              {form.getFieldValue('toEntityType') && (
                                 <Form.Item
                                    name="toEntityId"
                                    label="Cущность"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Пропустили поле'
                                       }
                                    ]}>
                                    <EntitySelect
                                       disabled={true}
                                       fieldName="toEntityId"
                                       type={form.getFieldValue('toEntityType')}
                                    />
                                 </Form.Item>
                              )}
                           </Col>
                        </Row>
                     </Col>
                  )}
               </Row> */}

               {/* {!simpleRatingKeys.includes('actionForEntityId') && (
                  <Row gutter={24}>
                     <Divider>Повод</Divider>
                     <Col span={12}>
                        <Row gutter={24}>
                           <Col span={24}>
                              <Form.Item
                                 name="actionForEntityType"
                                 label="Тип услуги"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Пропустили поле'
                                    }
                                 ]}>
                                 <Select
                                    allowClear
                                    style={{
                                       width: '100%'
                                    }}
                                    disabled={true}
                                    placeholder="Выберите тип"
                                    options={typesOfActionsOfRating}
                                    onChange={() =>
                                       form.setFieldValue(
                                          'actionForEntityId',
                                          undefined
                                       )
                                    }
                                 />
                              </Form.Item>
                           </Col>
                        </Row>
                     </Col>

                     <Col span={12}>
                        <Row gutter={24}>
                           <Col span={24}>
                              {form.getFieldValue('actionForEntityType') && (
                                 <Form.Item
                                    name="actionForEntityId"
                                    label="Выберите из списка"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Пропустили поле'
                                       }
                                    ]}>
                                    <ActionSelect
                                       disabled={true}
                                       fieldName="actionForEntityId"
                                       type={form.getFieldValue(
                                          'actionForEntityType'
                                       )}
                                    />
                                 </Form.Item>
                              )}
                           </Col>
                        </Row>
                     </Col>
                  </Row>
               )} */}

               <Row gutter={24}>
                  <Space
                     direction="horizontal"
                     style={{
                        width: '100%',
                        justifyContent: 'center',
                        marginTop: '15px'
                     }}>
                     <Form.Item
                        name="valueInFloat"
                        rules={[
                           {
                              required: true,
                              message: 'Поставьте оценку'
                           },
                           {
                              type: 'number',
                              min: 0.1,
                              message: 'Поставьте оценку выше.'
                           }
                        ]}>
                        <Rate
                           className="rate-big"
                           allowHalf
                           disabled={isDisabledInput('valueInFloat')}
                           allowClear={true}
                           defaultValue={0}
                           value={form.getFieldValue('valueInFloat')}
                        />
                     </Form.Item>
                  </Space>

                  {/* <Col span={!simpleRatingKeys.includes('status') ? 12 : 24}> */}
                  <Col span={24}>
                     <Row gutter={24}>
                        <Col span={24}>
                           <Form.Item name="comment" label="Комментарий">
                              <TextArea
                                 disabled={isDisabledInput('comment')}
                                 rows={5}
                                 placeholder="Максимум 500 символов"
                                 maxLength={500}
                              />
                           </Form.Item>
                        </Col>
                     </Row>
                  </Col>
                  <Col span={24}>
                     <Row gutter={24}>
                        {/* {!simpleRatingKeys.includes('categoryOfRating') && (
                           <Col span={24}>
                              <Form.Item name="categoryOfRating" label="Тип оценки">
                                 <Select
                                    allowClear
                                    defaultValue={1}
                                    style={{
                                       width: '100%'
                                    }}
                                    disabled={true}
                                    showSearch
                                    placeholder="Выберите тип"
                                    options={typesOfRatings}
                                 />
                              </Form.Item>
                           </Col>
                        )} */}
                        {!simpleRatingKeys.includes('status') && (
                           <Col span={24}>
                              <Form.Item
                                 name="status"
                                 label="Статус"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Выберите статус'
                                    }
                                 ]}
                                 defaultValue={statusesOfRatings.active}>
                                 <Select
                                    style={{
                                       width: '100%'
                                    }}
                                    placeholder="Выберите статус"
                                    rules={[
                                       {
                                          required: true,
                                          message: 'Поле не может быть пустым '
                                       }
                                    ]}
                                    options={actualStatuses}
                                 />
                              </Form.Item>
                           </Col>
                        )}
                     </Row>
                  </Col>
               </Row>
               <Row gutter={[16, 24]}>
                  <Col
                     className="gutter-row"
                     span={24}
                     className="stats-card-count-col">
                     <Form.Item>
                        <Button type="primary" htmlType="submit" loading={isLoading}>
                           {isEditForm ? 'Сохранить' : 'Создать'}
                        </Button>
                     </Form.Item>
                  </Col>
               </Row>
            </>
         )}
      </Form>
   );
};

export default RatingForm;
