import { updateSeller } from '@shared/api/all/seller';

export const UpdateSeller = async (fields, sellerId) => {
   try {
      // TODO по хорошему диспатч должен быть здесь
      const response = await updateSeller(fields, sellerId);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
