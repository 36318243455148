import React from 'react';
import TableAuctions from '@features/list-auction/ui/TableAuctions';
import { Title } from '@shared/ui';

const AuctionsPage = () => {
   return (
      <div>
         <Title>Список торгов</Title>
         <TableAuctions />
      </div>
   );
};

export default AuctionsPage;
