/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { statusesOfLeads } from '@shared/const/statuses';
import { Col, Row, DatePicker, Alert, InputNumber, Radio, Card } from 'antd';
import { GetCategoriesList } from '@features/manage-materialcategories/model/services/GetCategoriesList';
import UploadPicturesWall from '@widgets/PicturesWall/UploadPicturesWall';
import SelectSeller from '@widgets/FormItems/SelectSeller';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { TimePicker } from 'antd';
import SelectAddressOfSeller from '@widgets/FormItems/SelectAddressOfSeller';
import { leadExtraFieldsToExport } from '@shared/const/leadExtraFields';
import { unitSettings } from '@shared/const/units';
import dayjs from 'dayjs';
import { message } from 'antd';

const { TextArea } = Input;

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
   // Can not select days before today and today s
   return current && current < dayjs().startOf('day');
};
const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const LeadForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();
   const [categories, setCategories] = useState([]);
   const [categoryPrice, setCategoryPrice] = useState(null);
   const [currentSellerId, setCurrentSellerId] = useState(null);
   // eslint-disable-next-line no-unused-vars
   const [addressList, setAddressList] = useState([]);
   const [capacityCategoryRules, setCapacityCategoryRules] = useState([]);

   useEffect(() => {
      GetCategoriesList({
         page: 1,
         limit: 1000,
         sort: 'priority',
         order: 'asc',
         status: 'active'
      }).then((res) => {
         setCategories(res.data);
      });

      const addressOfSeller = initialValues?.seller?.addresses;
      if (initialValues?.address?.seller?.id) {
         setCurrentSellerId(initialValues?.address?.seller?.id);
      }

      if (addressOfSeller) {
         setAddressList(addressOfSeller);
      }
   }, []);

   useEffect(() => {
      getAveragePrice();
   }, [categories]);

   const onFinish = (values) => {
      if (values?.leadTimes?.length === 0) {
         message.error('Заполните даты вызова');
         return;
      }

      if (window.cantSubmitBecauseImageUploadingNow) {
         message.error('Картинки грузятся, подождите');
         return;
      }

      if (typeof values?.price === 'string') {
         if (!values.price.includes('.')) {
            values.price = values.price + '.00';
         } else {
            values.price = parseFloat(values.price).toFixed(1);
         }
      }

      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const setImagesIds = (arr) => {
      form.setFieldValue('images', arr);
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const suffixSelector = <Form.Item noStyle>кг</Form.Item>;
   const suffixSelectorPrice = <Form.Item noStyle>руб</Form.Item>;

   const modifiedInitialValues = {
      price: 0,
      priceType: 'unit',
      ...initialValues,
      statusComment: initialValues?.statusObj?.comment || '',
      leadTimes: initialValues?.leadsTimes
         ? initialValues?.leadsTimes.map((e) => ({
              ...e,
              fromTimestamp: dayjs(e.fromTimestamp),
              toTimestamp: dayjs(e.toTimestamp),
              timeArr: [dayjs(e.fromTimestamp), dayjs(e.toTimestamp)]
           }))
         : [],
      finishDate: initialValues?.finishDate ? dayjs(initialValues.finishDate) : null
   };

   const currentStatus = initialValues?.status || 'active';

   const isDisabledInput = (inputName) => {
      const list = {
         addressId: ['active'],
         sellerId: ['active'],
         capacity: ['active', 'inwork', 'finished'],
         price: ['active', 'inwork', 'finished'],
         priceType: ['active', 'inwork', 'finished'],
         finishDate: ['finished'],
         leadTimes: ['active', 'inwork'],
         materialCategoryId: ['active'],
         status: [
            'active',
            'inwork',
            'finished',
            'refused',
            'canceled',
            'deleted',
            'expired'
         ],
         comment: ['active', 'inwork', 'finished'],
         statusComment: [
            'active',
            'inwork',
            'finished',
            'blocked',
            'refused',
            'canceled',
            'deleted',
            'expired'
         ],
         images: ['active', 'inwork']
      };

      return !list?.[inputName]?.includes(currentStatus);
   };

   const statusesCanBeDoOnCurrent = {
      active: ['active', 'inwork', 'blocked', 'refused', 'canceled', 'deleted'],
      inwork: ['inwork', 'active', 'finished'],
      finished: ['finished', 'inwork', 'blocked', 'deleted'],
      blocked: ['blocked', 'active', 'deleted'],
      inauction: ['inauction'],
      refused: ['refused', 'deleted', 'active'],
      canceled: ['canceled', 'deleted', 'active'],
      deleted: ['blocked', 'deleted', 'active'],
      expired: ['blocked', 'deleted', 'active', 'expired']
   };

   const isLeadBlocked = currentStatus === 'blocked';
   const isLeadInWork = currentStatus === 'inwork';

   const actualStatuses = initialValues?.status
      ? statusesCanBeDoOnCurrent[currentStatus].map((e) => statusesOfLeads[e])
      : [statusesOfLeads.active];

   const getAveragePrice = () => {
      const categoryId =
         form.getFieldValue('materialCategoryId') ||
         initialValues?.materialCategoryId;

      if (!categoryId) {
         return null;
      }

      const record = categories.find((e) => e.id === categoryId);

      if (!record) {
         return null;
      }

      getCapacityRules(record);

      let text = '';

      // if (
      //    record.priceWithLoading === '0.0' &&
      //    record.priceWithoutLoading === '0.0'
      // ) {
      //    text = '';
      //    setCategoryPrice(text);
      //    return;
      // }

      text = '';

      // if (record.priceWithLoading !== '0.0') {
      //    text += `цена с погрузкой ${record.priceWithLoading} `;
      // }

      // if (record.priceWithoutLoading !== '0.0') {
      //    text += ` цена без погрузки ${record.priceWithoutLoading} `;
      // }

      // text += `руб за ${
      //    unitSettings.find((e) => e.value === record.unit)?.shortLabel
      // }`;

      // setCategoryPrice(text);
   };

   const getCapacityRules = (record) => {
      if (
         !record ||
         !Array.isArray(record.capacityRules) ||
         record.capacityRules.length === 0
      ) {
         setCapacityCategoryRules([]);
         return null;
      }

      setCapacityCategoryRules(record.capacityRules);
   };

   return (
      <Form
         style={{
            maxWidth: '100%',
            minWidth: 320
         }}
         form={form}
         initialValues={modifiedInitialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         hideRequiredMark
         layout="vertical">
         <Row gutter={16}>
            <Col span={7}>
               <Form.Item
                  label="Продавец"
                  rules={
                     !isDisabledInput('sellerId')
                        ? [
                             {
                                required: true,
                                message: 'Поле пропущено!'
                             }
                          ]
                        : []
                  }>
                  <SelectSeller
                     disabled={isDisabledInput('sellerId')}
                     seller={initialValues?.address?.seller || null}
                     sellerId={
                        form.getFieldValue('sellerId') ||
                        initialValues?.address?.seller?.id
                     }
                     setField={(value) => {
                        setCurrentSellerId(value);
                        form.setFieldValue('sellerId', value);
                        form.setFieldValue('addressId', null);
                     }}
                     setAddressList={setAddressList}
                  />
               </Form.Item>
               <Form.Item
                  label="Адрес продавца"
                  name="addressId"
                  rules={
                     !isDisabledInput('addressId')
                        ? [{ required: true, message: 'Укажите адрес' }]
                        : []
                  }>
                  <SelectAddressOfSeller
                     disabled={isDisabledInput('addressId')}
                     allowClear
                     currentAddress={initialValues?.address || null}
                     sellerId={
                        currentSellerId ||
                        form.getFieldValue('sellerId') ||
                        initialValues?.address?.seller?.id
                     }
                  />
               </Form.Item>
               <Form.Item
                  name="materialCategoryId"
                  label="Категория сырья"
                  rules={
                     !isDisabledInput('materialCategoryId')
                        ? [
                             {
                                required: true,
                                message: 'Выберите категории'
                             }
                          ]
                        : []
                  }
                  extra={categoryPrice}>
                  <Select
                     allowClear
                     style={{
                        width: '100%'
                     }}
                     showSearch
                     disabled={isDisabledInput('materialCategoryId')}
                     optionFilterProp="name"
                     filterOption={(input, option) =>
                        (option?.name ?? '')
                           .toLowerCase()
                           .includes(input.toLowerCase())
                     }
                     placeholder="Выберите категорию"
                     onChange={getAveragePrice}
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым'
                        }
                     ]}
                     fieldNames={{
                        label: 'name',
                        value: 'id'
                     }}
                     options={categories}
                  />
               </Form.Item>
               {capacityCategoryRules.length > 0 ? (
                  <div className="list-ofcapacities-forinput">
                     {capacityCategoryRules.map((rule, index) => (
                        <div key={index}>
                           {rule.paramOfCapacity === 'ot' &&
                              `от ${rule.capacityFrom} кг`}
                           {rule.paramOfCapacity === 'otido' &&
                              `от ${rule.capacityFrom} кг и до ${rule.capacityTo} кг`}
                           {rule.paramOfCapacity === 'do' &&
                              `до ${rule.capacityTo} кг`}

                           {rule.priceType === 'free' && ` - бесплатно`}
                           {rule.priceType === 'export' && ` позвонить менеджеру`}
                           {rule.priceType === 'ransom' && ` c выкупом (платим мы)`}
                        </div>
                     ))}
                  </div>
               ) : null}{' '}
               <Row gutter={24}>
                  <Col span={12}>
                     <Form.Item
                        label="Стоимость"
                        name="priceType"
                        defaultValue="unit"
                        rules={
                           !isDisabledInput('priceType')
                              ? [
                                   {
                                      required: true,
                                      message: 'Поле пропущено!'
                                   }
                                ]
                              : []
                        }>
                        <Radio.Group buttonStyle="solid">
                           <Radio.Button value="unit">За кг</Radio.Button>
                           <Radio.Button value="total">Общая</Radio.Button>
                        </Radio.Group>
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        name="price"
                        label="Значение"
                        rules={
                           !isDisabledInput('price')
                              ? [
                                   {
                                      required: true,
                                      message: 'Поле пропущено!'
                                   }
                                ]
                              : []
                        }>
                        <InputNumber
                           disabled={isDisabledInput('price')}
                           addonAfter={suffixSelectorPrice}
                           style={{
                              width: '100%'
                           }}
                           defaultValue="0.0"
                           type="number"
                           placeholder="Введите стоимость"
                           min="0"
                           step="0.1"
                           stringMode
                           precision={1}
                           decimalSeparator="."
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <Row gutter={24}>
                  <Col span={12}>
                     <Form.Item name="finishDate" label="Дата завершения">
                        <DatePicker
                           disabled={isDisabledInput('finishDate')}
                           style={{ width: '100%' }}
                           format="YYYY-MM-DD HH:mm"
                           disabledDate={disabledDate}
                           disabledTime={disabledDateTime}
                           showTime={{
                              defaultValue: dayjs('00:00', 'HH:mm')
                           }}
                        />
                     </Form.Item>
                  </Col>
                  <Col span={12}>
                     <Form.Item
                        name="capacity"
                        label="Вес"
                        rules={
                           !isDisabledInput('capacity')
                              ? [
                                   {
                                      required: true,
                                      message: 'Поле пропущено!'
                                   }
                                ]
                              : []
                        }>
                        <Input
                           disabled={isDisabledInput('capacity')}
                           addonAfter={suffixSelector}
                           style={{
                              width: '100%'
                           }}
                           type="number"
                           placeholder="Введите вес"
                        />
                     </Form.Item>
                  </Col>
               </Row>
               <Form.Item name="images" label="Картинки">
                  <UploadPicturesWall
                     isDisabledEditButton={isDisabledInput('images')}
                     maxCount={10}
                     images={initialValues?.images || []}
                     setImagesIds={setImagesIds}
                  />
               </Form.Item>
            </Col>

            <Col span={6}>
               {' '}
               <Form.Item name="comment" label="Комментарий к заявке">
                  <TextArea
                     disabled={isDisabledInput('comment')}
                     rows={2}
                     placeholder="Максимум 500 символов"
                     maxLength={500}
                  />
               </Form.Item>
               <Form.Item
                  name="status"
                  label="Статус"
                  rules={[
                     {
                        required: true,
                        message: 'Выберите статус'
                     }
                  ]}
                  defaultValue={statusesOfLeads.active}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     onChange={() => form.setFieldValue('statusComment', '')}
                     options={actualStatuses}
                  />
               </Form.Item>
               <Form.Item name="statusComment" label="Комментарий к статусу">
                  <TextArea
                     disabled={isDisabledInput('statusComment')}
                     rows={2}
                     placeholder="Максимум 500 символов"
                     maxLength={500}
                  />
               </Form.Item>
               <Form.Item name="capacityReal" label="Реальный вес при вывозе">
                  <Input
                     addonAfter={suffixSelector}
                     style={{
                        width: '100%'
                     }}
                     type="number"
                     placeholder="Введите реальный вес"
                  />
               </Form.Item>
            </Col>

            <Col span={5}>
               <Form.Item label="Даты вывоза">
                  <Form.List name="leadTimes">
                     {(fields, { add, remove }) => (
                        <>
                           {fields.map(({ key, name, ...restField }) => (
                              <Row gutter={24} key={`leadTimes${key}`}>
                                 <Col span={24}>
                                    <Form.Item
                                       {...restField}
                                       name={[name, 'fromTimestamp']}
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Укажите дату вывоза'
                                          }
                                       ]}>
                                       <DatePicker
                                          style={{ width: '100%' }}
                                          format="DD-MM-YYYY"
                                          disabledDate={disabledDate}
                                          disabledTime={disabledDateTime}
                                       />
                                    </Form.Item>

                                    <Form.Item
                                       {...restField}
                                       name={[name, 'timeArr']}
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Укажите дату вывоза'
                                          }
                                       ]}>
                                       <TimePicker.RangePicker
                                          format="HH:mm"
                                          minuteStep="5"
                                          showNow
                                          style={{ width: '100%' }}
                                       />
                                    </Form.Item>
                                 </Col>

                                 <Col span={24}>
                                    <Button
                                       disabled={isDisabledInput('leadTimes')}
                                       style={{
                                          float: 'right',
                                          marginBottom: '20px'
                                       }}
                                       danger
                                       type="primary"
                                       icon={<MinusCircleOutlined />}
                                       onClick={() => remove(name)}>
                                       Удалить время
                                    </Button>
                                 </Col>
                              </Row>
                           ))}

                           <Row gutter={24}>
                              <Col span={24}>
                                 <Form.Item>
                                    <Button
                                       disabled={isDisabledInput('leadTimes')}
                                       type="dashed"
                                       onClick={() => add()}
                                       block
                                       icon={<PlusOutlined />}>
                                       Добавить
                                    </Button>
                                 </Form.Item>
                              </Col>
                           </Row>
                        </>
                     )}
                  </Form.List>
               </Form.Item>
            </Col>

            <Col span={6}>
               {Object.values(leadExtraFieldsToExport).map((e) => (
                  <Form.Item
                     label={e.label}
                     name={e.value}
                     key={e.value + e.label + 'form'}>
                     {e.type === 'boolean' ? (
                        <Radio.Group name={e.value}>
                           <Radio value={true}>Да</Radio>
                           <Radio value={false}>Нет</Radio>
                        </Radio.Group>
                     ) : null}

                     {e.type === 'integer' ? (
                        <Input
                           style={{
                              width: '100%'
                           }}
                           type="number"
                        />
                     ) : null}
                  </Form.Item>
               ))}
            </Col>
         </Row>

         <Row gutter={[16, 24]}>
            {isLeadBlocked && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе заблокирован нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {isLeadInWork && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе 'водитель в пути' нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {errorMessage && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message={errorMessage.replace('Error: ', '')}
                     type="error"
                     showIcon
                  />
               </Col>
            )}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default LeadForm;
