import React, { useState, useEffect } from 'react';
import RouteForm from './RouteForm';
import { CreateRoute } from '../model/CreateRoute';
import { UpdateRoute } from '../model/UpdateRoute';
import { GetRoute } from '../model/GetRoute';
import _ from 'lodash';

import { message } from 'antd';

const CreateOrEditRoute = ({ id = null, callbackOnSuccess = () => {} }) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      if (!isEditForm) {
         return;
      }

      GetRoute(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });

      return () => {
         setErrorMessage('');
         setLoadForm(false);
      };
   }, [id]);

   const onSuccess = async (values, setLoading) => {
      setLoading(true);

      if (isEditForm) {
         const routeId = initialValues.id;
         const mergedObj = { ...initialValues, ...values };

         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         if (
            (updatedFields.status &&
               updatedFields.status !== initialValues.status) ||
            updatedFields?.statusComment
         ) {
            let obj = {
               status: updatedFields.status || initialValues.status,
               comment: updatedFields?.statusComment || ''
            };

            if (!updatedFields.status && initialValues.statusId) {
               obj.id = initialValues.statusId;
            }

            updatedFields.status = obj;
         }

         await UpdateRoute(updatedFields, routeId)
            .then(() => {
               callbackOnSuccess();
               message.success('Маршрут изменен');
            })
            .catch((e) => {
               setErrorMessage(e?.message || 'Возникла ошибка при сохранении');
               message.error(e?.message || 'Возникла ошибка при сохранении');
            })
            .finally(() => setLoading(false));

         return;
      }

      if (values.status) {
         values.status = {
            status: values.status || 'active',
            comment: values?.statusComment || ''
         };
      }

      await CreateRoute(values)
         .then(() => {
            message.success('Маршрут создан');
            callbackOnSuccess();
         })
         .catch((e) => {
            setErrorMessage(e?.message || 'Маршрут не создан, возникла ошибка');
            message.error(e?.message || 'Маршрут не создан, возникла ошибка');
         })
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <RouteForm
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
         errorMessage={errorMessage}
      />
   );
};

export default CreateOrEditRoute;
