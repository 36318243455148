import { Button, Drawer } from 'antd';
import { useState } from 'react';
import CreateCopyLead from './CreateCopyLead';

import { CopyOutlined } from '@ant-design/icons';

const ModalButtonLeadCopy = ({ id, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   return (
      <>
         <Button onClick={showModal} icon={<CopyOutlined />}>
            Создать копию
         </Button>
         <Drawer
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            title={`Создать копию заявки №${id}`}
            footer={null}
            width={1200}
            destroyOnClose={true}
            extra={<Button onClick={handleCancel}>Закрыть</Button>}>
            <CreateCopyLead id={id} callbackOnSuccess={handleCancel} />
         </Drawer>
      </>
   );
};

export default ModalButtonLeadCopy;
