import React, { useEffect, useState } from 'react';
import { GetRoute } from '@features/list-route/model/GetRoute';
import { Typography, Avatar, Tag } from 'antd';
import { useNavigate } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import TooltipCustom from '@shared/ui/Tooltips/TooltipCustom';

import DriverSimpleDescriptions from '@features/list-driver/ui/DriverSimpleDescriptions';
import { UserOutlined } from '@ant-design/icons';
const { Text } = Typography;

const roles = [
   {
      value: 'manager',
      label: 'Менеджер'
   },
   {
      value: 'support',
      label: 'Поддержка'
   },
   {
      value: 'admin',
      label: 'Админ'
   },
   {
      value: 'superadmin',
      label: 'Супер Админ'
   }
];

const DriverInfoByRouteId = ({ id }) => {
   const [data, setData] = useState([]);
   const navigate = useNavigate();

   useEffect(() => {
      fetchById();
   }, [id]);

   const fetchById = async () => {
      if (!id) return;

      GetRoute(id).then((res) => {
         setData(res?.driver ? [res.driver] : []);
      });
   };

   if (!id) return `Не указано`;

   return (
      <>
         {data?.map((item) => (
            <TooltipCustom
               key={item.id}
               text={<DriverSimpleDescriptions data={item} />}>
               <div
                  onClick={() => navigate(`/drivers/${item.id}`)}
                  style={{ marginLeft: '0px' }}
                  className="green-span-url">
                  {item?.firstName || 'Не указано'} {item?.lastName}
               </div>
            </TooltipCustom>
         ))}
      </>
   );
};

export default DriverInfoByRouteId;
