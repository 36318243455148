import React from 'react';
import { Descriptions, Tag } from 'antd';

import { StarFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import { statusesOfDrivers } from '@shared/const/statuses';

const DriverSimpleDescriptions = ({ data }) => {
   const navigate = useNavigate();

   const driverLittleDescription = [
      {
         label: 'ID',
         key: 'id',
         render: (_, record) => (
            <>
               {_}

               <Tag
                  color={statusesOfDrivers[record.status]?.color}
                  style={{ marginLeft: '10px' }}>
                  {statusesOfDrivers[record.status]?.label}
               </Tag>

               <span
                  className="green-span-url"
                  style={{}}
                  onClick={() => navigate(`/drivers/${_}`)}>
                  Перейти
               </span>
            </>
         )
      },
      {
         label: 'ФИО',
         key: 'firstName',
         render: (_, record) =>
            `${record.lastName} ${record.firstName} ${record?.surName || ''}`
      },
      {
         label: 'Номер телефона',
         key: 'mobileNumber',
         render: (_) => _
      },
      {
         label: 'Почта',
         key: 'email',
         render: (_) => _ || 'Не задано'
      }
      // {
      //    label: 'Рейтинг',
      //    key: 'rating',
      //    render: (_) => (
      //       <Tag bordered={false}>
      //          <StarFilled style={{ color: '#ffca00' }} /> {_ ? _ / 10 : 0}
      //       </Tag>
      //    )
      // }
      // {
      //    label: 'Почта',
      //    key: 'email',
      //    render: (_) => _ || 'Не задано'
      // },
   ];

   if (!data) return null;

   return (
      <Descriptions size="small">
         {driverLittleDescription.map((e) => (
            <Descriptions.Item
               key={`${data?.id}-${e?.label}`}
               label={e?.label}
               span={3}>
               {e.render(data[e.key], data)}
            </Descriptions.Item>
         ))}
      </Descriptions>
   );
};

export default DriverSimpleDescriptions;
