import React from 'react';
import { Tooltip } from 'antd';

const TooltipTextEllipsis = ({ text, children, placement = 'bottomLeft' }) => {
   return (
      <Tooltip placement={placement} title={text} overlayClassName="tooltip-white">
         {children}
      </Tooltip>
   );
};

export default TooltipTextEllipsis;
