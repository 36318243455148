import { Tag } from 'antd';
import {
   statusesOfLeads,
   statusesOfSellers,
   statusesOfDrivers,
   statusesOfRoutes
} from '@shared/const/statuses';

const StatusTag = ({ status, type }) => {
   const types = {
      lead: statusesOfLeads,
      seller: statusesOfSellers,
      route: statusesOfRoutes,
      driver: statusesOfDrivers
   };

   const currentStatuses = types[type];

   return (
      <Tag className="status-tag" color={currentStatuses[status]?.color}>
         {currentStatuses[status]?.label}
      </Tag>
   );
};

export default StatusTag;
