import { Drawer, Button, Form, Row, Col, Space, Alert } from 'antd';
import VerticalSpace from '@shared/ui/VerticalSpace';
import React, { useState } from 'react';
import { Input } from '@shared/ui';
import { Radio, message } from 'antd';
import { createCustomPushNotification } from '@shared/api/all/pushNotification';

import SelectSeller from '@widgets/FormItems/SelectSeller';
import SelectDriver from '@widgets/FormItems/SelectDriver';

const { TextArea } = Input;

const ModalFormSendPushNotification = () => {
   const [form] = Form.useForm();
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   const onFinish = async (values) => {
      if (values.adresat === 'one' && !values.entityId) {
         setErrorMessage('Вы не выбрали водителя');
         return;
      }

      setIsLoading(true);

      await createCustomPushNotification(values)
         .then(() => {
            message.success('Отправлено успешно!');
            setIsLoading(false);
            setTimeout(() => {
               form.resetFields();
               setIsModalOpen(false);
            }, 500);
         })
         .catch((e) => setErrorMessage(e.message))
         .finally(() => setIsLoading(false));
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      entityType: 'driver'
   };

   const handleOk = () => {
      setIsModalOpen(false);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      form.resetFields();
   };

   return (
      <>
         <Button onClick={() => setIsModalOpen(true)}>
            Отправить push-уведомление на приложения
         </Button>
         <Drawer
            title={`Создать push-уведомление на приложения`}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            onClose={handleCancel}
            footer={null}
            width={640}
            destroyOnClose={true}
            extra={<Button onClick={handleCancel}>Закрыть</Button>}>
            <Form
               style={{
                  maxWidth: '100%',
                  minWidth: 320
               }}
               form={form}
               initialValues={modifiedInitialValues}
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
               onValuesChange={() => {
                  setErrorMessage('');
               }}
               hideRequiredMark
               layout="vertical">
               {() => (
                  <>
                     <Row gutter={16}>
                        <Col span={24}>
                           <Form.Item
                              label="Выберите приложение"
                              name="entityType" // Впринципе это и значит какое приложение
                              defaultValue="driver">
                              <Radio.Group
                                 defaultValue="driver"
                                 onChange={() => {
                                    form.setFieldValue('entityId', null);
                                    form.setFieldValue('adresat', null);
                                 }}>
                                 <Radio.Button value="driver" defaultChecked>
                                    Водители
                                 </Radio.Button>
                                 <Radio.Button value="seller" defaultChecked>
                                    Продавец
                                 </Radio.Button>
                              </Radio.Group>
                           </Form.Item>

                           <Form.Item name="adresat" label="Выберите адресат">
                              <Radio.Group>
                                 <Space direction="vertical">
                                    <Radio value="all">
                                       Всем
                                       {form.getFieldValue('entityType') === 'driver'
                                          ? ' водителям'
                                          : ' продавцам'}
                                    </Radio>
                                    {/* <Radio value="status">
                                       Только водителям со статусом{' '}
                                    </Radio>

                                    {form.getFieldValue('adresat') === 'status' ? (
                                       <Select
                                          style={{
                                             width: '200px',
                                             marginLeft: '10px'
                                          }}
                                          placeholder="Выберите статус"
                                          options={Object.values(statusesOfDrivers)}
                                       />
                                    ) : null} */}

                                    <Radio value="one">
                                       Одному
                                       {form.getFieldValue('entityType') === 'driver'
                                          ? ' водителю'
                                          : ' продавцу'}
                                    </Radio>
                                 </Space>
                              </Radio.Group>
                           </Form.Item>

                           {form.getFieldValue('adresat') === 'one' &&
                           form.getFieldValue('entityType') === 'driver' ? (
                              <Form.Item name="entityId" label="Выберите водителя">
                                 <SelectDriver
                                    driver={{}}
                                    driverId={form.getFieldValue('entityId')}
                                    setField={(value) => {
                                       form.setFieldValue('entityId', value);
                                    }}
                                 />
                              </Form.Item>
                           ) : null}

                           {form.getFieldValue('adresat') === 'one' &&
                           form.getFieldValue('entityType') === 'seller' ? (
                              <Form.Item name="entityId" label="Выберите продавца">
                                 <SelectSeller
                                    disabled={false}
                                    seller={{}}
                                    sellerId={form.getFieldValue('entityId')}
                                    setField={(value) => {
                                       form.setFieldValue('entityId', value);
                                    }}
                                 />
                              </Form.Item>
                           ) : null}

                           <Form.Item
                              name="title"
                              label="Заголовок уведомления"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Поле не может быть пустым'
                                 },
                                 {
                                    max: 30,
                                    message: 'Максимум 50 символов'
                                 }
                              ]}>
                              <Input
                                 style={{
                                    width: '100%'
                                 }}
                                 placeholder="Введите уведомления"
                              />
                           </Form.Item>
                           <Form.Item
                              name="message"
                              label="Содержание уведомления"
                              rules={[
                                 {
                                    required: true,
                                    message: 'Поле не может быть пустым'
                                 }
                              ]}>
                              <TextArea
                                 rows={2}
                                 placeholder="Максимум 500 символов"
                                 maxLength={500}
                              />
                           </Form.Item>
                        </Col>
                     </Row>

                     {errorMessage && (
                        <Col
                           className="gutter-row"
                           span={24}
                           className="stats-card-count-col">
                           <Alert
                              message={errorMessage.replace('Error: ', '')}
                              type="error"
                              showIcon
                           />
                           <VerticalSpace />
                        </Col>
                     )}

                     <Row gutter={[16, 24]}>
                        <Col
                           className="gutter-row"
                           span={24}
                           className="stats-card-count-col">
                           <Form.Item>
                              <Button
                                 type="primary"
                                 htmlType="submit"
                                 loading={isLoading}>
                                 Отправить уведомление
                              </Button>
                           </Form.Item>
                        </Col>
                     </Row>
                  </>
               )}
            </Form>
         </Drawer>
      </>
   );
};

export default ModalFormSendPushNotification;
