const statuses = {
   active: {
      label: 'Активный',
      value: 'active',
      color: 'green',
      badgeStatus: 'success',
      showInMain: true
   },
   pending: {
      label: 'Не активирован',
      value: 'pending',
      color: 'grey',
      badgeStatus: 'default',
      showInMain: true
   },
   blocked: {
      label: 'Заблокирован',
      value: 'blocked',
      color: 'red',
      badgeStatus: 'error',
      showInMain: true
   }
};

const statusesOfAcceptances = {
   active: {
      label: 'Активный',
      value: 'active',
      color: 'green',
      badgeStatus: 'success',
      showInMain: true
   },
   pending: {
      label: 'Не активирован',
      value: 'pending',
      color: 'grey',
      badgeStatus: 'default',
      showInMain: true
   },
   blocked: {
      label: 'Заблокирован',
      value: 'blocked',
      color: 'red',
      badgeStatus: 'error',
      showInMain: true
   }
};

const statusesOfSellers = {
   active: {
      label: 'Активный',
      value: 'active',
      color: '#20B2AA',
      badgeStatus: 'success',
      showInMain: true
   },
   blocked: {
      label: 'Заблокирован',
      value: 'blocked',
      badgeStatus: 'error',
      color: '#FA8072',
      showInMain: true
   },
   deactivated: {
      label: 'Удален',
      value: 'deactivated',
      color: '#A9A9A9',
      badgeStatus: 'default',
      showInMain: true
   }
};

const statusesOfRatings = {
   active: {
      label: 'Активный',
      value: 'active',
      color: 'green',
      badgeStatus: 'success',
      showInMain: true
   },
   archive: {
      label: 'Отключен',
      value: 'archive',
      color: 'red',
      badgeStatus: 'error',
      showInMain: true
   }
};

const statusesOfNotes = {
   active: {
      label: 'Активный',
      value: 'active',
      color: 'green',
      badgeStatus: 'success',
      showInMain: true
   },
   archive: {
      label: 'Удален',
      value: 'archive',
      color: 'red',
      badgeStatus: 'error',
      showInMain: true
   }
};

const statusesOfCategories = {
   active: { label: 'Активный', value: 'active', color: 'green', showInMain: true },
   archive: { label: 'Скрыт', value: 'archive', color: 'grey', showInMain: true }
};

const statusesOfAuctions = {
   active: { label: 'Активный', value: 'active', color: 'green', showInMain: true },
   pending: {
      label: 'Торг запущен',
      value: 'pending',
      color: 'purple',
      showInMain: true
   },
   finished: {
      label: 'Завершен',
      value: 'finished',
      color: 'blue',
      showInMain: true
   },
   archive: { label: 'В архиве', value: 'archive', color: 'grey', showInMain: true }
};

const statusesOfLeads = {
   active: {
      label: 'Активный',
      value: 'active',
      color: '#20B2AA',
      progress: 30,
      level: 1,
      stages: ['active', 'inwork', 'finished'],
      showInMain: true
   },
   inwork: {
      label: 'Водитель в пути',
      value: 'inwork',
      color: '#000000',
      progress: 70,
      level: 4,
      stages: ['active', 'inwork', 'finished'],
      showInMain: true
   },
   finished: {
      label: 'Завершен',
      value: 'finished',
      color: '#6495ED',
      progress: 100,
      level: 5,
      stages: ['active', 'inwork', 'finished'],
      showInMain: true
   },
   inauction: {
      label: 'В торге',
      value: 'inauction',
      color: '#FF8C00',
      progress: 40,
      level: 2,
      stages: ['active', 'inauction', 'wininauction', 'inwork', 'finished'],
      showInMain: false
   },
   wininauction: {
      label: 'Выигран в торгах',
      value: 'wininauction',
      color: '#8B008B',
      progress: 80,
      level: 4,
      showInMain: false,
      stages: ['active', 'inauction', 'wininauction', 'inwork', 'finished']
   },
   refused: {
      label: 'Отменен водителем',
      value: 'refused',
      color: '#b22020',
      progress: 100,
      stages: ['active', 'refused'],
      showInMain: true
   },
   canceled: {
      label: 'Отменен продавцом',
      value: 'canceled',
      color: '#2075b2',
      progress: 100,
      stages: ['active', 'canceled'],
      showInMain: true
   },
   blocked: {
      label: 'Заблокирован',
      value: 'blocked',
      color: '#FA8072',
      progress: 100,
      stages: ['active', 'blocked'],
      showInMain: true
   },
   deleted: {
      label: 'Отменена менеджером',
      value: 'deleted',
      color: '#2055b2',
      progress: 100,
      stages: ['active', 'deleted'],
      showInMain: true
   },
   expired: {
      label: 'Просрочен',
      value: 'expired',
      color: '#A9A9A9',
      progress: 100,
      stages: ['active', 'expired'],
      showInMain: true
   }
};

const statusesOfDrivers = {
   active: {
      label: 'Активный',
      value: 'active',
      color: '#20B2AA',
      badgeStatus: 'success',
      showInMain: true
   },
   inwork: {
      label: 'В работе',
      value: 'inwork',
      color: '#000000',
      badgeStatus: 'processing',
      showInMain: true
   },
   blocked: {
      label: 'Заблокирован',
      value: 'blocked',
      color: '#FA8072',
      badgeStatus: 'error',
      showInMain: true
   },
   deactivated: {
      label: 'Удален',
      value: 'deactivated',
      color: '#A9A9A9',
      badgeStatus: 'default',
      showInMain: true
   }
   // deleted: {
   //    label: 'Удален',
   //    value: 'deleted',
   //    color: '#A9A9A9',
   //    badgeStatus: 'default',
   //    showInMain: false
   // }
};

const statusesOfFaqs = {
   published: {
      label: 'Опубликован',
      value: 'published',
      color: 'green',
      showInMain: true
   },
   archive: { label: 'В архиве', value: 'archive', color: 'grey', showInMain: true }
};

const statusesOfRoutes = {
   active: {
      label: 'Активный',
      value: 'active',
      color: '#20B2AA',
      progress: 30,
      level: 1,
      stages: ['active', 'inwork', 'finished'],
      showInMain: true
   },
   inwork: {
      label: 'В работе',
      value: 'inwork',
      color: '#000000',
      progress: 70,
      level: 4,
      stages: ['active', 'inwork', 'finished'],
      showInMain: true
   },
   finished: {
      label: 'Завершен',
      value: 'finished',
      color: '#6495ED',
      progress: 100,
      level: 5,
      stages: ['active', 'inwork', 'finished'],
      showInMain: true
   },
   blocked: {
      label: 'Заблокирован',
      value: 'blocked',
      color: '#FA8072',
      progress: 100,
      stages: ['active', 'blocked'],
      showInMain: true
   },
   deleted: {
      label: 'Удален',
      value: 'deleted',
      color: '#A9A9A9',
      progress: 100,
      stages: ['active', 'deleted'],
      showInMain: true
   }
};

export default statuses;
export {
   statusesOfLeads,
   statusesOfRoutes,
   statusesOfFaqs,
   statusesOfDrivers,
   statusesOfAcceptances,
   statusesOfAuctions,
   statusesOfSellers,
   statusesOfCategories,
   statusesOfRatings,
   statusesOfNotes
};
