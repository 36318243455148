import { Space, Tag, Typography } from 'antd';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';

const { Text } = Typography;

const DateWithAgo = ({ date, showDate = true, showAgo = true }) => {
   if (!date) {
      return 'Неизвестно';
   }

   return (
      <Space direction="vertical">
         {showAgo ? (
            <Tag bordered={false}>{getTimeAgo(dayjs(date).unix() * 1000)}</Tag>
         ) : null}
         {showDate ? (
            <Text type="secondary">{dayjs(date).format('YYYY.MM.DD HH:mm')}</Text>
         ) : null}
      </Space>
   );
};

export default DateWithAgo;
