/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Alert } from '@shared/ui';
import TableRatings from '@features/list-rating/ui/TableRatings';
import { Title, Select } from '@shared/ui';
import {
   ChatContainer,
   ConversationHeader,
   MessageList,
   Message,
   MessageSeparator,
   MainContainer,
   Sidebar,
   Search,
   Conversation,
   Avatar,
   VoiceCallButton,
   VideoCallButton,
   InfoButton,
   TypingIndicator,
   MessageInput,
   ExpansionPanel,
   ConversationList
} from '@chatscope/chat-ui-kit-react';
import { getChatSupportGroup } from '@shared/api/all/chat';
import { Button, Space, message as messageMethod } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { getSellerById } from '@shared/api/all/seller';
import { getDriverById } from '@shared/api/all/driver';
import SimpleSellerDescription from '@features/list-seller/ui/SimpleSellerDescription';
import SimpleDriverDescription from '@features/list-driver/ui/SimpleDriverDescription';
import MessageListComponent from '@widgets/Chat/MessageListComponent';
import { Empty } from 'antd';
import { createMessage, readMessages, unreadChatCount } from '@shared/api/all/chat';
import { useNavigate } from 'react-router-dom';
import DriverPhoto from '@shared/assets/images/eco-driver.png';
import SellerPhoto from '@shared/assets/images/eco-seller.png';
import createTextVersion from 'textversionjs';

const WhatIsEntity = ({
   entityToType,
   entityFromType,
   entityToId,
   entityFromId
}) => {
   if (entityToType === 'driver' || entityToType === 'seller') {
      return { type: entityToType, id: entityToId };
   }

   if (entityFromType === 'driver' || entityFromType === 'seller') {
      return { type: entityFromType, id: entityFromId };
   }
};

const getAvatar = (type) => {
   return type === 'driver' ? DriverPhoto : SellerPhoto;
};

const getName = (type, id) => {
   return type === 'driver' ? `Водитель №${id}` : `Продавец №${id}`;
};

const NavigateToEntityButton = ({ id, type }) => {
   const navigate = useNavigate();
   const link = type === 'driver' ? `/drivers/${id}` : `/sellers/${id}`;

   return (
      <Button type="primary" onClick={() => navigate(link)}>
         Перейти к {type === 'driver' ? 'водителю' : 'продавцу'}
      </Button>
   );
};

const ConverstationHeader = (props) => {
   const {
      message,
      id,
      entityToType,
      entityFromType,
      messageType,
      selectedEntity,
      onClick
   } = props;
   const mainEntity = WhatIsEntity(props);
   if (!mainEntity?.type) return null;
   const [data, setData] = useState([]);

   const imageSrc = getAvatar(mainEntity?.type);
   const name = getName(mainEntity?.type, mainEntity?.id);

   const lastSenderName = entityFromType === 'user' ? 'Вы' : null;

   const trimmedMessage =
      messageType === 'image'
         ? 'Изображение'
         : message.length > 20
         ? `${message.slice(0, 20)}...`
         : message;

   return (
      <Conversation
         key={id}
         onClick={() => onClick(id)}
         info={trimmedMessage}
         lastSenderName={lastSenderName}
         unreadCnt={0}
         active={
            selectedEntity.id === mainEntity?.id &&
            selectedEntity.type === mainEntity?.type
         }
         name={name}>
         <Avatar name={name} src={imageSrc} />
      </Conversation>
   );
};

const ConverstationComponent = (props) => {
   const {
      message,
      id,
      entityToType,
      entityFromType,
      messageType,
      selectedEntity,
      onClick
   } = props;

   const [data, setData] = useState(0);

   const mainEntity = WhatIsEntity(props);
   if (!mainEntity?.type) return null;

   useEffect(() => {
      let intervalD = null;

      if (!mainEntity?.id) return;

      if (intervalD) {
         return;
      }

      fetchUnreadCount();

      intervalD = setInterval(() => {
         fetchUnreadCount();
      }, 10000);

      return () => clearInterval(intervalD);
   }, [mainEntity, data]);

   const fetchUnreadCount = () => {
      unreadChatCount(
         {
            entityFromType: mainEntity.type,
            entityFromId: mainEntity.id,
            entityToType: 'user',
            entityToId: '0'
         },
         'support',
         '0'
      ).then((res) => {
         setData(res?.json?.data || 0);
      });
   };

   const imageSrc = getAvatar(mainEntity.type);

   const currentName = getName(mainEntity.type, mainEntity.id);
   const lastSenderName = entityFromType === 'user' ? 'Вы' : null;

   const trimmedMessage =
      messageType === 'image'
         ? 'Изображение'
         : message.length > 20
         ? `${message.slice(0, 20)}...`
         : message;

   return (
      <Conversation
         key={id}
         onClick={() => onClick(mainEntity?.id, mainEntity?.type)}
         info={trimmedMessage}
         lastSenderName={lastSenderName}
         unreadCnt={data}
         active={
            selectedEntity.id === mainEntity?.id &&
            selectedEntity.type === mainEntity?.type
         }
         name={currentName}>
         <Avatar name={currentName} src={imageSrc} />
      </Conversation>
   );
};

const SupportPage = () => {
   const [data, setData] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [selectedEntity, setSelectedEntity] = useState({ id: null, type: null });
   const [message, setMessage] = useState('');
   const [doUpdate, setDoUpdate] = useState(false);

   useEffect(() => {
      fetchData(currentPage);

      const interval = setInterval(() => {
         fetchData(currentPage);
      }, 10000);

      return () => clearInterval(interval);
   }, []);

   useEffect(() => {
      onChangeEntity();
   }, [selectedEntity]);

   const onChangeEntity = () => {
      setMessage('');
      const { id, type } = selectedEntity;

      if (id && type) {
         readMessages(
            {
               entityFromId: id,
               entityFromType: type,
               entityToId: '0',
               entityToType: 'user'
            },
            'support',
            '0'
         );
      }
   };

   const sendMessage = async () => {
      try {
         await createMessage(
            {
               entityFromId: '0',
               entityFromType: 'user',
               entityToId: selectedEntity.id,
               entityToType: selectedEntity.type,
               message: createTextVersion(message),
               messageType: 'text'
            },
            'support',
            '0'
         );

         fetchData(1);

         setMessage('');
         setDoUpdate((e) => !e);
      } catch (error) {
         console.log(error);
      }
   };

   const fetchData = async (page = currentPage) => {
      try {
         const response = await getChatSupportGroup({
            page,
            limit: 10
         });
         if (page === 1) {
            setData((e) => [...(response?.json?.data || [])]);
         } else {
            setData((e) => [...e, ...(response?.json?.data || [])]);
         }

         setCurrentPage(page);
         setDoUpdate((e) => !e);
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <div className="erp-chat support">
         <Title>Чат поддержки</Title>

         <MainContainer
            responsive
            style={{
               height: '70vh'
            }}>
            <Sidebar position="left">
               <ConversationList>
                  {data.length === 0 ? (
                     <div
                        style={{
                           height: '600px',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center'
                        }}>
                        <Empty
                           image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description={'Выберите слева чат'}
                        />
                     </div>
                  ) : (
                     <>
                        {data.map((item) => (
                           <ConverstationComponent
                              {...item}
                              key={item.id}
                              selectedEntity={selectedEntity}
                              onClick={(id, type) => setSelectedEntity({ id, type })}
                           />
                        ))}
                     </>
                  )}
               </ConversationList>
               <Space
                  direction="horizontal"
                  style={{
                     width: '100%',
                     justifyContent: 'center',
                     margin: '10px 0'
                  }}>
                  <Button
                     type="primary"
                     icon={<DownloadOutlined />}
                     onClick={() => fetchData(currentPage + 1)}>
                     Загрузить больше
                  </Button>
               </Space>
            </Sidebar>

            {selectedEntity.id === null ? (
               <ChatContainer>
                  <MessageList>
                     {' '}
                     <div
                        style={{
                           height: '600px',
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center'
                        }}>
                        <Empty
                           image={Empty.PRESENTED_IMAGE_SIMPLE}
                           description={'Выберите слева чат'}
                        />
                     </div>
                  </MessageList>
               </ChatContainer>
            ) : (
               <ChatContainer>
                  <ConversationHeader>
                     <Avatar
                        name={'Картинка'}
                        src={getAvatar(selectedEntity?.type)}
                     />
                     <ConversationHeader.Content
                        userName={getName(selectedEntity.type, selectedEntity.id)}
                     />

                     <ConversationHeader.Actions>
                        <NavigateToEntityButton
                           id={selectedEntity.id}
                           type={selectedEntity.type}
                        />
                     </ConversationHeader.Actions>
                  </ConversationHeader>
                  <MessageList>
                     <MessageListComponent
                        chatType={'support'}
                        chatTypeId={'0'}
                        entityFromType={'user'}
                        entityFromId={'0'}
                        entityToType={selectedEntity.type}
                        entityToId={selectedEntity.id}
                        doUpdate={doUpdate}
                     />
                  </MessageList>

                  <MessageInput
                     placeholder="Наберите сообщение"
                     attachButton={false}
                     autoFocus={true}
                     onChange={(e) => setMessage(e)}
                     onSend={sendMessage}
                     sendButton={true}
                  />
               </ChatContainer>
            )}

            <Sidebar position="right">
               <div style={{ padding: '15px 10px' }}>
                  {selectedEntity.id && selectedEntity.type ? (
                     <>
                        {selectedEntity.type === 'driver' && (
                           <SimpleDriverDescription id={selectedEntity.id} />
                        )}

                        {selectedEntity.type === 'seller' && (
                           <SimpleSellerDescription id={selectedEntity.id} />
                        )}
                     </>
                  ) : null}{' '}
               </div>
            </Sidebar>
         </MainContainer>
      </div>
   );
};

export default SupportPage;
