import React, { useState } from 'react';
import { Button, Drawer, Space } from 'antd';
import NotificationsButton from './NotificationsButton';
import NotificationList from './NotificationList';

const NotificationDrawer = () => {
   const [open, setOpen] = useState(false);
   const showDrawer = () => {
      setOpen(true);
   };

   const onClose = () => {
      setOpen(false);
   };

   return (
      <>
         <NotificationsButton onClick={showDrawer} />
         <Drawer
            title="Уведомления"
            placement="right"
            width={400}
            onClose={onClose}
            open={open}
            destroyOnClose={true}
            extra={
               <Space>
                  <Button onClick={onClose}>Закрыть</Button>
               </Space>
            }
         >
            <NotificationList />
         </Drawer>
      </>
   );
};
export default NotificationDrawer;
