import { Progress, Tag, Tooltip } from 'antd';
import { statusesOfLeads, statusesOfRoutes } from '@shared/const/statuses';

const statusesKeys = {
   lead: statusesOfLeads,
   route: statusesOfRoutes
};

const StatusProgress = ({
   status,
   type,
   isShowProgress = true,
   showInfo = true,
   isQueue = false
}) => {
   const statusesOfEntity = statusesKeys?.[type] || statusesOfLeads;
   const stepsLength = Object.values(statusesOfEntity[status]?.stages)?.length || 5;

   return (
      <>
         {isShowProgress && (
            <>
               <div>
                  <Progress
                     steps={stepsLength}
                     style={{ marginBottom: 5 }}
                     strokeColor={statusesOfEntity[status]?.color}
                     percent={statusesOfEntity[status]?.progress}
                     size={[20, 10]}
                     showInfo={showInfo}
                  />
               </div>
            </>
         )}

         <Tag className="status-tag" color={statusesOfEntity[status]?.color}>
            {statusesOfEntity[status]?.label}{' '}
         </Tag>

         {statusesOfEntity[status].value === 'active' && isQueue ? (
            <Tooltip
               placement="top"
               title={'Маршрут находится в очереди у водителя'}>
               <Tag className="status-tag" color="rgb(95 95 95)">
                  В очереди
               </Tag>
            </Tooltip>
         ) : (
            ''
         )}
      </>
   );
};

export default StatusProgress;
