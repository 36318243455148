import React, { useEffect, useState } from 'react';
import { Divider, Descriptions, Tag, Row, Col, Button, Space, Tabs } from 'antd';
import CanDo from '@shared/lib/CanDo';
import { ReloadOutlined, EditOutlined, StarFilled } from '@ant-design/icons';
import ModalDriverForm from '@features/form-driver/ui/ModalDriverForm';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Title, StatusTag, DateWithAgo } from '@shared/ui';
import stringToColor from '@shared/utils/stringToColor';
import { GetDriver } from '@features/form-driver/model/services/GetDriver';
import RatingListOfEntity from '@widgets/RatingListOfEntity';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { useNavigate } from 'react-router-dom';
import { FinishedRouteCount } from './DriverComponents';
import ListOfPushNotifications from '@features/list-pushes/ui/ListOfPushNotifications';
import ListOfRoutesOfDriver from '@features/list-route/ui/ListOfRoutesOfDriver';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';

import { Spin } from '@shared/ui';

const SimpleDriverDescription = ({ id }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const [modalShow, setModalShow] = useState(null);

   const autoDescriptionLabelsAndValues = [
      {
         label: 'Бренд авто',
         key: 'carBrand',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Номер авто',
         key: 'carNumber',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'СТС авто',
         key: 'carSTS',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Грузоподъемность',
         key: 'carCapacity',
         render: (_) => `${_} кг` || 'Не задано'
      }
   ];

   const driverDescriptionLabelsAndValues = [
      {
         label: 'ФИО',
         key: 'firstName',
         render: (_, record) =>
            `${record.lastName} ${record.firstName} ${record?.surName || ''}`
      },
      {
         label: 'Телефон',
         key: 'mobileNumber',
         render: (_) => _
      },
      {
         label: 'Почта',
         key: 'email',
         render: (_) => _ || 'Не задано'
      },
      {
         label: 'Рейтинг',
         key: 'rating',
         render: (_) => (
            <Tag bordered={false}>
               <StarFilled style={{ color: '#ffca00' }} /> {_ ? _ / 10 : 0}
            </Tag>
         )
      },
      {
         label: 'Дата регистрации',
         key: 'createdAt',
         render: (_) => `${timestampToNormalDDMMYY(_)} в ${timestampToNormalTime(_)}`
      },
      {
         label: 'Округи',
         key: 'districts',
         render: (_) => (_?.length > 0 ? _?.join(', ') : 'Не задано')
      },

      {
         label: 'Был в приложении',
         key: 'lastSeenAt',
         render: (_) => <DateWithAgo date={_} showDate={false} />
      }
   ];

   const fetchData = () => {
      setLoading(true);
      GetDriver(id).then((res) => {
         setData({ ...res });
         setLoading(false);
      });
   };

   useEffect(() => {
      fetchData();
   }, [id]);

   if (loading && !data?.id) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '300px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   return (
      <>
         <Row gutter={24}>
            <Col span={24}>
               <Row gutter={24}>
                  <Col span={24}>
                     <ListOfPushNotifications
                        entityId={id}
                        entityType={'driver'}
                        limit={0}
                     />
                  </Col>
                  <Col span={24}>
                     <Divider orientation="left">Информация</Divider>
                     <Descriptions size="small">
                        {driverDescriptionLabelsAndValues.map((e) => (
                           <Descriptions.Item
                              key={`${data.id}-${e.label}`}
                              label={e.label}
                              span={3}>
                              {e.render(data[e.key], data)}
                           </Descriptions.Item>
                        ))}

                        <Descriptions.Item
                           key={`meow-driver ${id}`}
                           label={`Количество вывезенных маршрутов`}
                           span={3}>
                           <FinishedRouteCount driverId={id} />
                        </Descriptions.Item>
                     </Descriptions>
                  </Col>{' '}
                  <Col span={24}>
                     <Divider orientation="left">Автомобиль</Divider>

                     <Descriptions size="small">
                        {autoDescriptionLabelsAndValues.map((e) => (
                           <Descriptions.Item
                              key={`${data.id}-${e.label}`}
                              label={e.label}
                              span={3}>
                              {e.render(data[e.key], data)}
                           </Descriptions.Item>
                        ))}
                     </Descriptions>

                     <Divider orientation="left">Комментарий</Divider>
                     {data?.notes || 'Нет комментариев'}
                  </Col>
                  <Col span={24}>
                     <Divider orientation="left">Оценки водителя</Divider>

                     <RatingListOfEntity
                        settings={{ toEntityType: 'driver', toEntityId: id }}
                     />
                  </Col>{' '}
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default SimpleDriverDescription;
