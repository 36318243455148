import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getAcceptanceById = (id) => get(`/acceptance/${id}`);
export const getAcceptances = (params) =>
   get(generateQueryParams(`/acceptances`, params));

export const deleteAcceptanceById = (id) => _delete(`/acceptance/${id}`, {}, true);

export const createAcceptance = (fields) =>
   post(
      '/acceptance',
      {
         ...fields
      },
      true
   );

export const updateAcceptance = (fields, id) =>
   put(
      `/acceptance/${id}`,
      {
         ...fields
      },
      true
   );
