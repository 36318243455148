import React, { useEffect, useState } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import {
   Divider,
   Descriptions,
   Tag,
   Row,
   Col,
   Button,
   Space,
   Rate,
   Steps,
   Typography,
   Collapse,
   Empty
} from 'antd';
import { useNavigate } from 'react-router-dom';
import { Title } from '@shared/ui';
import { statusesOfRoutes } from '@shared/const/statuses';
import { ArrowLeftOutlined } from '@ant-design/icons';
import CanDo from '@shared/lib/CanDo';
import RatingOfEntity from '@features/list-rating/ui/RatingOfEntity';
import { StatusProgressSteps } from '@shared/ui';
import Notes from '@features/notes/ui/index';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';

import { deleteRouteById } from '@shared/api/all/route';
import { VerticalSpace } from '@shared/ui';
import {
   ReloadOutlined,
   EditOutlined,
   StopOutlined,
   CaretRightOutlined
} from '@ant-design/icons';
import ModalRouteForm from '@features/list-route/ui/ModalRouteForm';
import DriverSimpleDescriptions from '@features/list-driver/ui/DriverSimpleDescriptions';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';

import stringToColor from '@shared/utils/stringToColor';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import { GetRoute } from '@features/list-route/model/GetRoute';
import { UpdateStatusOfRoute } from '@features/list-route/model/UpdateStatusOfRoute';
import SellerSimpleDescriptions from '@features/list-seller/ui/SellerSimpleDescriptions';
import ListLeadsOfRoute from '@features/list-route/ui/ListLeadsOfRoute';
import ListLeadsOnMap from '@features/list-route/ui/ListLeadsOnMap';

import routeCapacity from '@shared/utils/routeCapacity';
import routePriceToBuy from '@shared/utils/routePriceToBuy';

const { Text } = Typography;

const getCategoriesList = (leads) => {
   const list = new Set();

   leads.forEach((e) => {
      list.add(e.materialCategory.name);
   });

   return [...list];
};

const RouteDescription = ({ id }) => {
   const [data, setData] = useState({});
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();
   const [modalShow, setModalShow] = useState(null);

   const routeDescriptionLabelsAndValues = [
      {
         label: 'Общий вес',
         key: 'fullCapacity',
         render: (_, record) => <>{routeCapacity(record?.leads || [])} кг</>
      },
      {
         label: 'Стоимость выкупа',
         key: 'fullPriceToBuy',
         render: (_, record) => <>{routePriceToBuy(record?.leads || [])}</>
      },
      {
         label: 'Создан автоматически',
         key: 'isAutoCreated',
         render: (_) => <>{_ ? 'Да' : 'Нет'}</>
      },
      {
         label: 'Комиссия',
         key: 'commission',
         render: (_) => (_ ? `${_} рублей` : 'Не указана')
      },
      {
         label: 'Категории сырья',
         key: 'materialCategories',
         render: (_, record) => {
            const r = [];

            getCategoriesList(record?.leads || []).forEach((e) =>
               r.push(<Tag color={stringToColor(e)}>{e}</Tag>)
            );

            return (
               <Space size="small" wrap>
                  {r}
               </Space>
            );
         }
      }
   ];

   const fetchData = () => {
      setLoading(true);
      GetRoute(id).then((res) => {
         setData({ ...res });
         setLoading(false);
      });
   };

   useEffect(() => {
      fetchData();
      const intervalId = setInterval(() => {
         fetchData();
      }, 10000);

      return () => {
         clearInterval(intervalId);
      };
   }, [id]);

   const closeModal = () => {
      fetchData();
      setModalShow(null);
   };

   const update = () => {
      fetchData();
   };

   const blockRoute = (routeId) => {
      UpdateStatusOfRoute({ status: 'blocked' }, routeId).then(() => fetchData());
   };

   // if (loading) {
   //    return (
   //       <Space
   //          style={{
   //             display: 'flex',
   //             justifyContent: 'center',
   //             alignItems: 'center',
   //             height: '300px',
   //             width: '100%'
   //          }}>
   //          <Spin />
   //       </Space>
   //    );
   // }

   const getStatusOfStep = (curStat, index) => {
      if (
         index === data?.routeStatuses?.length - 1 &&
         curStat !== 'finished' &&
         curStat !== 'blocked'
      ) {
         return 'process';
      }

      if (curStat === 'finished') {
         return 'finish';
      }

      if (curStat === 'active') {
         return 'wait';
      }

      if (curStat === 'blocked') {
         return 'error';
      }

      if (curStat === 'inwork') {
         return 'active';
      }

      return 'process';
   };

   return (
      <>
         <div style={{ margin: '25px 0' }}>
            <Space size="large" align="center" wrap>
               <Button
                  icon={<ArrowLeftOutlined />}
                  onClick={() => navigate(-1)}></Button>
               <Title
                  style={{
                     padding: 0,
                     margin: 0
                  }}>{`Маршрут №${id}`}</Title>
               <div style={{ paddingTop: '12px' }}>
                  <NewEntityTag date={data?.createdAt} />
                  <StatusProgressSteps
                     showInfo={false}
                     status={data?.status}
                     isQueue={
                        (data?.driver?.id &&
                           data?.status === 'active' &&
                           !data?.driver?.inWorkRoute?.id) ||
                        (data?.driver?.status === 'inwork' &&
                           data?.driver?.inWorkRoute?.id !== data.id &&
                           data?.status === 'active')
                     }
                     type="route"
                     isShowProgress={true}
                  />
               </div>
            </Space>
         </div>

         <ModalRouteForm selectedRoute={modalShow} closeModal={closeModal} />
         <Row gutter={24}>
            <Col span={24}>
               <Row gutter={24}>
                  <Space size="middle" style={{ marginLeft: '10px' }}>
                     <Button
                        onClick={() => update()}
                        icon={<ReloadOutlined />}
                        loading={loading}>
                        Обновить
                     </Button>

                     <CanDo permission="can_edit_routes">
                        <Button
                           onClick={() => setModalShow(data)}
                           type="primary"
                           icon={<EditOutlined />}>
                           Редактировать
                        </Button>
                     </CanDo>

                     <CanDo permission="can_edit_routes">
                        {/* {data.status === 'active' && (
                           <Button
                              type="primary"
                              danger
                              icon={<StopOutlined />}
                              onClick={() => blockRoute(data.id)}>
                              Заблокировать
                           </Button>
                        )} */}

                        {/* {data.status === 'blocked' && (
                           <DeleteEntityButton
                              id={data.id}
                              buttonText="Удалить"
                              callbackFetch={deleteRouteById}
                              update={() => navigate('/routes')}
                              permission="can_delete_routes"
                           />
                        )} */}
                     </CanDo>
                  </Space>
               </Row>
            </Col>
            <Col span={24}>
               <Row gutter={24}>
                  <Col span={24}>
                     <Row gutter={24}>
                        <Col span={7} style={{ minWidth: '200px' }}>
                           <Divider orientation="left">
                              Информация о маршруте
                           </Divider>
                           <Descriptions size="small">
                              {routeDescriptionLabelsAndValues.map((e) => (
                                 <Descriptions.Item
                                    span={3}
                                    key={`${data.id}-${e.label}`}
                                    label={e.label}>
                                    {e.render(data?.[e.key], data)}
                                 </Descriptions.Item>
                              ))}
                           </Descriptions>
                           {data?.driver?.id && (
                              <>
                                 <Divider orientation="left">
                                    Водитель маршрута
                                 </Divider>
                                 <DriverSimpleDescriptions data={data?.driver} />
                              </>
                           )}
                           <Divider orientation="left">Оценка маршруту</Divider>
                           <Divider orientation="center" plain>
                              Оценка от менеджера, водителю за маршрут
                           </Divider>
                           <div
                              className="description-comment-block"
                              style={{ whiteSpace: 'pre-wrap' }}>
                              {data?.driverId ? (
                                 <RatingOfEntity
                                    actionForEntityType="route"
                                    actionForEntityId={data?.id}
                                    fromEntityType={'user'}
                                    fromEntityId={undefined}
                                    toEntityType={'driver'}
                                    toEntityId={data?.driverId}
                                    status={'active'}
                                    categoryOfRating={1}
                                 />
                              ) : (
                                 'Водитель не назнчаен'
                              )}
                           </div>
                           <Divider orientation="left">
                              История изменения статусов
                           </Divider>{' '}
                           <Text type="secondary">
                              <Space
                                 size="small"
                                 key={`leadroutes2-${data.id}-${data.createdAt}`}>
                                 Дата создания:
                                 {timestampToNormalDDMMYY(data.createdAt)}
                                 {timestampToNormalTime(data.createdAt)}
                              </Space>
                           </Text>
                           <VerticalSpace />
                           {data?.routeStatuses?.length && (
                              <Steps
                                 direction="vertical"
                                 current={data?.routeStatuses?.length}
                                 status="process"
                                 items={data?.routeStatuses
                                    .reverse()
                                    .map((e, index) => ({
                                       title: (
                                          <>
                                             {statusesOfRoutes[e.status]?.label}{' '}
                                             <Text
                                                type="secondary"
                                                style={{ fontSize: '12px' }}>
                                                {timestampToNormalDDMMYY(
                                                   e.createdAt
                                                )}{' '}
                                                {timestampToNormalTime(e.createdAt)}
                                             </Text>
                                          </>
                                       ),
                                       color: statusesOfRoutes[e.status]?.color,
                                       status: getStatusOfStep(e.status, index),
                                       ...{
                                          ...(e?.comment && {
                                             description: `Комментарий: ${e.comment}`
                                          })
                                       }
                                    }))}
                              />
                           )}
                        </Col>
                        <Col span={10}>
                           <Divider orientation="left">
                              Список заявок в маршруте
                           </Divider>
                           <ListLeadsOfRoute leads={data?.leads || []} />

                           <Divider orientation="left">На карте</Divider>
                           <ListLeadsOnMap leads={data?.leads || []} />
                        </Col>

                        <Col span={7}>
                           <Notes entityId={id} entityType="route" />
                        </Col>
                     </Row>
                  </Col>
               </Row>
            </Col>
         </Row>
      </>
   );
};

export default RouteDescription;
