import { updateRating } from '@shared/api/all/rating';

export const UpdateRating = async (fields, id) => {
   try {
      const response = await updateRating(fields, id);
      return response;
   } catch (e) {
      console.log(e);
      throw new Error(e);
   }
};
