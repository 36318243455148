import React, { useEffect, useState } from 'react';
import { NotificationOutlined } from '@ant-design/icons';
import { Badge, Space, Button, Tooltip } from 'antd';
import { getNewNotificationsCount } from '@shared/api/all/notification';
import { LAST_SEEN_NOTIFICATION_ID } from '@shared/const/localStorage';
import { getLocalStorageByKey } from '@shared/lib/localStorage';
import NotificationsCreate from './NotificationsCreate';
import NotificationIcon from '@shared/assets/icons/NotificationIcon';

const NotificationsButton = ({ onClick }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);

   const [count, setCount] = useState(0);

   useEffect(() => {
      fetchData();
   }, []);

   const fetchData = async () => {
      if (loading) {
         return;
      }

      const lastSeenId = await getLocalStorageByKey(LAST_SEEN_NOTIFICATION_ID);

      setLoading(true);
      getNewNotificationsCount({
         id: lastSeenId || 0
      })
         .then((res) => {
            setData(res.json.data);
            setLoading(false);
            setCount(res.json.count);

            setTimeout(() => {
               fetchData();
            }, 5000);
         })
         .catch(() => setLoading(false));
   };

   return (
      <Space>
         <NotificationsCreate data={data} />
         <Tooltip placement="top" title={'Уведомления'} arrow={false}>
            <Badge
               style={{ backgroundColor: '#52c41a' }}
               count={count}
               overflowCount={9}>
               <Button icon={<NotificationIcon />} onClick={onClick}></Button>
            </Badge>
         </Tooltip>
      </Space>
   );
};

export default NotificationsButton;
