import { getNoteCauses } from '@shared/api/all/note';

export const GetNoteCauses = async () => {
   try {
      const response = await getNoteCauses();

      if (!response.json) throw new Error();
      return { data: response.json.data };
   } catch (e) {
      console.log(e);
   }
};
