import React, { useState, useEffect } from 'react';
import { Space, Spin, Button, Card, Modal, Input, message, Tooltip } from 'antd';
import {
   DownloadOutlined,
   SendOutlined,
   CheckOutlined,
   ClockCircleOutlined
} from '@ant-design/icons';
import { GetPushList } from '@features/list-pushes/model/GetPushList';

import { DateWithAgo } from '@shared/ui';
import { createCustomPushNotification } from '@shared/api/all/pushNotification';
const { TextArea } = Input;

const ListOfPushNotifications = ({ entityType, entityId, limit = 3 }) => {
   const [loading, setLoading] = useState(false);
   const [data, setData] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [modalForm, setModalForm] = useState({
      title: '',
      message: ''
   });
   const [count, setCount] = useState(0);
   const LIMIT = limit;

   const fetchData = (entityType, entityId, pg) => {
      if (LIMIT === 0) return;

      setLoading(true);
      GetPushList({
         page: pg,
         limit: LIMIT,
         entityId,
         entityType
      })
         .then((res) => {
            setData((e) => [...e, ...res.data]);
            setLoading(false);
            setCurrentPage(pg);
            setCount(res?.count || 0);
         })
         .catch((err) => {
            console.error(err);
            setLoading(false);
         });
   };

   useEffect(() => {
      fetchData(entityType, entityId, currentPage);
   }, [entityType, entityId, currentPage]);

   if (loading) {
      return (
         <Space
            style={{
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               height: '100px',
               width: '100%'
            }}>
            <Spin />
         </Space>
      );
   }

   const isDisabled = count - currentPage * LIMIT <= 0;

   const showModal = () => {
      setIsModalOpen(true);
      setModalForm({
         title: '',
         message: ''
      });
   };

   const handleOk = async () => {
      if (!modalForm.title || !modalForm.message) {
         message.error('Заполните все поля!');
         return;
      }

      setLoading(true);

      await createCustomPushNotification({
         adresat: 'one',
         entityId: entityId,
         entityType: entityType,
         title: modalForm.title,
         message: modalForm.message
      })
         .then((res) => {
            if (res?.json?.message !== 'Отправлено') {
               message.error(res?.json?.message);
               setLoading(false);
               return;
            }

            message.success(res?.json?.message);

            setIsModalOpen(false);
            setLoading(false);
         })
         .catch((e) => message.error(e.message))
         .finally(() => setLoading(false));
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   return (
      <Space direction="vertical" style={{ width: '100%' }}>
         <Space
            direction="horizontal"
            style={{
               width: '100%',
               justifyContent: 'center',
               marginBottom: '10px'
            }}>
            <Button loading={loading} onClick={showModal} icon={<SendOutlined />}>
               Отправить push уведомление
            </Button>
         </Space>

         <Modal
            title="Отправить push уведомление"
            open={isModalOpen}
            onOk={handleOk}
            confirmLoading={loading}
            cancelText="Отмена"
            okText="Отправить"
            onCancel={handleCancel}>
            <Space
               direction="vertical"
               style={{
                  width: '100%'
               }}>
               <div>
                  <Input
                     style={{
                        width: '100%'
                     }}
                     placeholder="Введите заголовок уведомления"
                     value={modalForm.title}
                     onChange={(e) =>
                        setModalForm({ ...modalForm, title: e.target.value })
                     }
                  />
               </div>
               <div>
                  <TextArea
                     rows={4}
                     placeholder="Введите текст уведомления, максимум 300 символов"
                     maxLength={300}
                     value={modalForm.message}
                     onChange={(e) =>
                        setModalForm({ ...modalForm, message: e.target.value })
                     }
                  />
               </div>
            </Space>
         </Modal>

         {limit === 0 ? null : (
            <>
               {data.length > 0 ? (
                  <>
                     {data.map((element) => (
                        <Card
                           size="small"
                           key={element.id}
                           style={{
                              background: element.read ? '#e7e7e7' : '#cbefdd'
                           }}
                           title={
                              <>
                                 <Tooltip
                                    placement="topRight"
                                    title={
                                       element.read ? 'Прочитано' : 'Не прочитано'
                                    }>
                                    {element.read ? (
                                       <CheckOutlined />
                                    ) : (
                                       <ClockCircleOutlined />
                                    )}
                                    <span style={{ marginLeft: '10px' }}>
                                       {element.title}
                                    </span>
                                 </Tooltip>
                              </>
                           }>
                           {element.message}
                           <div style={{ marginTop: '5px' }}>
                              <DateWithAgo
                                 date={element.createdAt}
                                 showDate={false}
                              />
                           </div>
                        </Card>
                     ))}

                     <Space
                        direction="horizontal"
                        style={{
                           width: '100%',
                           justifyContent: 'center',
                           marginTop: '15px'
                        }}>
                        <Button
                           type="primary"
                           loading={loading}
                           icon={<DownloadOutlined />}
                           onClick={() => setCurrentPage((e) => e + 1)}>
                           Загрузить больше
                        </Button>
                     </Space>
                  </>
               ) : (
                  <Space
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100px',
                        width: '100%'
                     }}>
                     <span>Нет уведомлений</span>
                  </Space>
               )}{' '}
            </>
         )}
      </Space>
   );
};

export default ListOfPushNotifications;
