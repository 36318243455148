import { finishAuctionById } from '@shared/api/all/auction';

export const FinishAuctionById = async (fields, id) => {
   try {
      const response = await finishAuctionById(fields, id);
      if (!response.json) throw new Error();
      return response.json.data;
   } catch (e) {
      console.log(e);
      throw new Error(e);
   }
};
