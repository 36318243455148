import { get, generateQueryParams } from '../fetch.js';

export const getCountOfService = (service, params) =>
   get(generateQueryParams(`/stats/count/${service}`, params));

export const getProgressionsOfEntitiesLast30Days = () => get(`/stats/progression`);

export const getAllRoutesCommission = () => get(`/stats/routes/commissions`);
export const getAllLeadsCapacities = () => get(`/stats/leads/capacities`);

export const getFreeCars = () => get(`/stats/cars/free`);
export const getWorkCars = () => get(`/stats/cars/work`);
