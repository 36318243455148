import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, List, Tooltip, Badge, Typography } from 'antd';
import { GetRouteList } from '../model/GetRouteList';
import { UpdateStatusOfRoute } from '../model/UpdateStatusOfRoute';
import { Button, VerticalSpace, DateWithAgo, StatusProgress } from '@shared/ui';
import { EditOutlined } from '@ant-design/icons';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalButtonRouteCreate from './ModalButtonRouteCreate';
import { statusesOfRoutes, statusesOfLeads } from '@shared/const/statuses';
import ModalRouteForm from './ModalRouteForm';
import ModalButtonRatingCreateToEntity from '@features/list-rating/ui/ModalButtonRatingCreateToEntity';
import { statusesOfDrivers } from '@shared/const/badgeStatuses';
import { useSelector } from 'react-redux';
import { getUserAuthData } from '@entitles/User';
import EnterToEntityButton from '@widgets/Button/EnterToEntityButton';

import ModalButtonNoteCreate from '@features/notes/ui/ModalButtonNoteCreate';
import { StatusTag } from '@shared/ui';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange,
   getColumnSearchDriverProps
} from '@shared/ui/Table';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import CanDo from '@shared/lib/CanDo';
import { useNavigate } from 'react-router-dom';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { isHaveUrlListSettings } from '@shared/utils/browser';
import { changeUrl } from '@shared/utils/browser';
import ExportToCSVModalButton from '@widgets/ExportToCSV/ExportToCSVModalButton';
import RouteEarningById from '@widgets/Table/RouteEarningById';
import {
   StatusesDateTimeLine,
   StatusesDateTimeLineXronology
} from '@widgets/Table/StatusesDateTimeLine';
import TooltipCustom from '@shared/ui/Tooltips/TooltipCustom';
import { unitSettings } from '@shared/const/units';
import SimpleLeadInfo from '@features/list-lead/ui/SimpleLeadInfo';
import qs from 'qs';

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const TableRoutes = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedRoute, setSelectedRoute] = useState(null);
   const navigate = useNavigate();
   const userInfo = useSelector(getUserAuthData);
   const userId = userInfo?.id;

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const parsedFilters = qs.parse(location.search, {
            ignoreQueryPrefix: true
         });

         const sorterFields = ['sort', 'order'];
         const paginationFields = ['current', 'pageSize'];

         let params = {
            pagination: {},
            filters: {},
            sorter: {}
         };

         Object.keys(parsedFilters).forEach((key) => {
            if (sorterFields.includes(key)) {
               params.sorter[key] = parsedFilters[key];
            } else if (paginationFields.includes(key)) {
               params.pagination[key] = parseInt(parsedFilters[key]);
            } else {
               params.filters[key] = parsedFilters[key];
            }
         });

         fetchData(params);
      }
   }, []);

   const columns = [
      {
         title: 'Маршрут',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         width: '270px',
         fixed: 'left',
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         filtered: !!filters?.['id'],
         render: (_, record) => (
            <Space direction="horizontal">
               <span
                  className="green-span-url"
                  style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                  onClick={() => navigate(`/routes/${_}`)}>
                  {`Маршрут №${_}`}
               </span>
               <NewEntityTag date={record.createdAt} />

               {record?.isAutoCreated ? (
                  <Tooltip
                     placement="top"
                     title={
                        'Автоматически созданный маршрут, заявка с мобильного приложения поступила'
                     }>
                     <Tag className="status-tag" color="rgb(178 32 67)">
                        авто
                     </Tag>
                  </Tooltip>
               ) : null}
            </Space>
         ),
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      }
   ];

   const actions = [
      {
         title: 'Водитель',
         dataIndex: 'driverId',
         key: 'driverId',
         width: '320px',
         fixed: 'left',
         filtered: !!filters?.['driverId'],
         filterMode: true,
         filteredValue: filters?.['driverId'] || null,
         ...getColumnSearchDriverProps({
            dataIndex: 'driverId',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),

         render: (_, record) => (
            <Space size="small" wrap>
               <div className="address_list">
                  {record?.driver && (
                     <List
                        itemLayout="horizontal"
                        dataSource={[record.driver]}
                        renderItem={(item) => (
                           <List.Item key={item.toString()}>
                              <List.Item.Meta
                                 title={
                                    <Space>
                                       <span>
                                          {item?.firstName
                                             ? `${item?.firstName} ${
                                                  item?.lastName || ''
                                               }`
                                             : 'ФИО отсутствует'}
                                       </span>
                                       <Tooltip
                                          placement="topRight"
                                          title={
                                             statusesOfDrivers[item?.status]?.label
                                          }
                                          arrow={false}>
                                          <Badge
                                             status={
                                                statusesOfDrivers[item?.status]
                                                   ?.badgeStatus
                                             }
                                             className="driver-status-badge"
                                          />
                                       </Tooltip>
                                    </Space>
                                 }
                                 description={`№${record.driver.id} | Телефон: ${item?.mobileNumber}`}
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                 }}
                              />
                           </List.Item>
                        )}
                     />
                  )}
               </div>
            </Space>
         )
      },
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         align: 'left',
         width: '300px',
         fixed: 'left',
         filterMode: true,
         filteredValue: Array.isArray(paramsTable?.filters?.status)
            ? paramsTable?.filters?.status
            : paramsTable?.filters?.status
            ? [paramsTable?.filters?.status]
            : [],
         filters: Object.values(statusesOfRoutes).map((e) => ({
            text: <StatusTag status={e.value} type="route" />,
            value: e.value
         })),
         render: (_, record) => (
            <StatusesDateTimeLine
               createdAt={record.createdAt}
               updatedAt={record.updatedAt}
               entityId={record.id}
               entityType="route"
               isReverse={true}
               isQueue={
                  (record?.driverId &&
                     record?.status === 'active' &&
                     !record?.driver?.inWorkRoute?.id) ||
                  (record?.driver?.status === 'inwork' &&
                     record?.driver?.inWorkRoute?.id !== record.id &&
                     record?.status === 'active')
               }
            />
         )
         // <StatusProgress status={_} type="route" showInfo={false} />
         // filtered: !!filters?.['status'],
         // ...getStatusColumnFilterProps({
         //    options: Object.values(statusesOfRoutes),
         //    dataIndex: 'status',
         //    handleSearch: (searchObj) => onSearchTable(searchObj)
         // })
      },
      // {
      //    title: 'Комментарий к статусу',
      //    dataIndex: 'commentStatus',
      //    render: (_, record) => (
      //       <div style={{ fontSize: '12px' }}>
      //          <Text type="secondary" style={{ fontSize: '12px' }}>
      //             {timestampToNormalDDMMYY(record?.statusObj.updatedAt)}{' '}
      //             {timestampToNormalTime(record?.statusObj.updatedAt)}
      //          </Text>
      //          <div>{record?.statusObj?.comment || 'Без пометок'} </div>
      //       </div>
      //    )
      // },
      // {
      //    title: 'Общий вес',
      //    dataIndex: 'fullCapacity',
      //    key: 'fullCapacity',
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          {routeCapacity(record.leads)} кг
      //       </Space>
      //    )
      // },
      // {
      //    title: 'Комиссия',
      //    dataIndex: 'commission',
      //    key: 'commission',
      //    sorter: true,
      //    render: (_) => (
      //       <Space size="small" wrap>
      //          {_ ? `${_} рублей` : 'Не указана'}
      //       </Space>
      //    )
      // },
      // {
      //    title: 'Дата создания',
      //    dataIndex: 'createdAt',
      //    key: 'createdAt',
      //    sorter: true,
      //    sortOrder:
      //       paramsTable?.sorter?.sort === 'createdAt'
      //          ? `${paramsTable?.sorter?.order}end`
      //          : null,
      //    render: (_) => <DateWithAgo date={_} />
      // },
      {
         title: 'Заявки',
         dataIndex: 'leads',
         key: 'leads',
         width: '400px',
         render: (_) => (
            <Space size="small" wrap>
               {_.map((item) => (
                  <TooltipCustom key={item.id} text={<SimpleLeadInfo data={item} />}>
                     <Tag
                        className="green-span-url"
                        key={item.id}
                        color={statusesOfLeads[item.status].color}
                        onClick={() => navigate(`/leads/${item.id}`)}>
                        {`Заявка №${item?.id}`}
                     </Tag>
                  </TooltipCustom>
               ))}
            </Space>
         )
      },
      {
         title: 'Хронология событий',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         width: '320px',
         align: 'left',
         render: (_, record) => (
            <StatusesDateTimeLineXronology
               createdAt={record.createdAt}
               updatedAt={record.updatedAt}
               entityId={record.id}
               entityType="route"
               isReverse={true}
            />
         )
      },
      {
         title: 'Итоговый заработок водителя 1',
         key: 'earnings1',
         width: '300px',
         render: (_, record) => <RouteEarningById routeId={record.id} type="1" />
      },
      {
         title: 'Итоговый заработок водителя 2',
         key: 'earnings2',
         width: '300px',
         render: (_, record) => <RouteEarningById routeId={record.id} type="2" />
      },
      {
         title: 'Действие',
         key: 'action',
         fixed: 'right',
         width: 240,
         render: (_, record) => (
            <Space size="middle">
               <EnterToEntityButton id={record.id} entity="routes" />
               <CanDo permission="can_edit_routes">
                  <Space size="middle">
                     <Tooltip placement="top" title={'Редактирование'}>
                        <Button
                           onClick={() => setSelectedRoute(record)}
                           type="primary">
                           <EditOutlined />
                        </Button>
                     </Tooltip>

                     <Tooltip placement="top" title={'Добавить заметку'}>
                        <ModalButtonNoteCreate
                           closeModal={closeModal}
                           entityId={record.id}
                           entityType={'route'}
                           buttonText={null}
                        />{' '}
                     </Tooltip>

                     {(record.status === 'finished' ||
                        record.status === 'blocked') &&
                        record.driver && (
                           <Tooltip placement="top" title={'Оценить'}>
                              <ModalButtonRatingCreateToEntity
                                 id={record?.rating?.id || null}
                                 valuesOfRating={{
                                    actionForEntityType: 'route',
                                    actionForEntityId: record.id,
                                    fromEntityType: 'user',
                                    fromEntityId: userId,
                                    toEntityType: 'driver',
                                    toEntityId: record.driver.id,
                                    status: 'active',
                                    categoryOfRating: 1
                                 }}
                                 closeModal={fetchData}
                              />
                           </Tooltip>
                        )}

                     {/* {record.status === 'active' && (
                        <Tooltip placement="top" title={'Заблокировать'}>
                           <Button
                              onClick={() => updateRouteStatusRequest(record.id)}
                              type="primary"
                              danger>
                              <StopOutlined />
                           </Button>
                        </Tooltip>
                     )} */}

                     {/* {record.status === 'blocked' && (
                        <DeleteEntityButton
                           id={record.id}
                           callbackFetch={deleteRouteById}
                           update={fetchData}
                           permission="can_delete_routes"
                        />
                     )} */}
                  </Space>
               </CanDo>
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const updateRouteStatusRequest = (routeId) => {
      UpdateStatusOfRoute({ status: 'blocked' }, routeId).then(() => fetchData());
   };

   const closeModal = () => {
      setSelectedRoute(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      if (
         JSON.stringify(params.filters).length !==
         JSON.stringify(paramsTable.filters).length
      ) {
         params.pagination.current = 1;
      }

      const current = params.pagination.current || paramsTable.pagination.current;
      const pageSize = params.pagination.pageSize || paramsTable.pagination.pageSize;
      const sorter = params.sorter?.sort ? params?.sorter : initialSorter;

      GetRouteList({
         page: current,
         limit: pageSize,
         ...sorter,
         ...params.filters
      }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, current, pageSize, total: res.count }
         });

         changeUrl(params);
         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   return (
      <>
         <ModalRouteForm selectedRoute={selectedRoute} closeModal={closeModal} />
         <CanDo permission="can_create_routes">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonRouteCreate closeModal={closeModal} />
            </Space>
         </CanDo>
         <VerticalSpace />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
                  <ExportToCSVModalButton
                     type="route"
                     fetchData={GetRouteList}
                     dataParams={paramsTable}
                  />
               </Space>
            </div>
         </div>

         <Table
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            rowKey="id"
            onChange={handleTableChange}
            loading={isLoading}
            scroll={{ x: 1500 }}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableRoutes;
