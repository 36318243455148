import { getNoteById } from '@shared/api/all/note';

export const GetNote = async (id) => {
   try {
      const response = await getNoteById(id);
      if (!response.json) throw new Error();
      return response.json.data;
   } catch (e) {
      console.log(e);
   }
};
