import React, { useState, useEffect } from 'react';
import {
   ChatContainer,
   MessageList,
   Message,
   MessageSeparator
} from '@chatscope/chat-ui-kit-react';
import { getChat } from '@shared/api/all/chat';
import { Empty, Tooltip } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { getTimeAgo } from '@shared/utils/timeAgo';
import dayjs from 'dayjs';
import ModalImage from 'react-modal-image';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

const getImageSrc = (message) => {
   if (message.includes('data:image')) {
      return message;
   }

   return `data:image/jpeg;charset=utf-8;base64, ${message}`;
};

function groupMessagesByDay(messages) {
   return messages.reduce((groups, message) => {
      const date = new Date(message.createdAt);
      const day = date.toISOString().split('T')[0]; // Получаем дату в формате YYYY-MM-DD

      if (!groups[day]) {
         groups[day] = [];
      }

      groups[day].push(message);

      return groups;
   }, {});
}

const MessageListComponent = (props) => {
   const [loading, setLoading] = useState(false);
   const [messages, setMessages] = useState([]);
   const [upd, setUpd] = useState(false);

   const {
      chatType,
      chatTypeId,
      entityFromType,
      entityFromId,
      entityToType,
      entityToId,
      doUpdate
   } = props;

   useEffect(() => {
      if (upd !== doUpdate) {
         fetchData();
         setUpd(doUpdate);
      }
   }, [doUpdate]);

   useEffect(() => {
      if (chatType && chatTypeId && entityFromType && entityFromId && entityToId) {
         fetchData();
      }
   }, [
      chatType,
      chatTypeId,
      entityFromType,
      entityToType,
      entityFromId,
      entityToId
   ]);

   const fetchData = () => {
      setLoading(true);
      getChat(
         {
            page: 1,
            limit: 1000,
            entityFromType: entityFromType,
            entityFromId: entityFromId,
            entityToType: entityToType,
            entityToId: entityToId
         },
         chatType,
         chatTypeId
      ).then((response) => {
         if (response?.json?.data.length > 0) {
            setMessages(response?.json?.data || []);
         }

         setLoading(false);
      });
   };

   const groupedMessages = groupMessagesByDay(messages);

   return (
      <MessageList>
         {messages.length === 0 && !loading ? (
            <div
               style={{
                  height: '340px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center'
               }}>
               <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </div>
         ) : (
            <>
               <br />
               {Object.keys(groupedMessages).map((date, index) => (
                  <>
                     <MessageSeparator content={date} />
                     {groupedMessages[date].map((message, index) => (
                        <>
                           {message.messageType === 'image' ? (
                              <Message
                                 key={index}
                                 model={{
                                    direction:
                                       message.entityFromType === 'user' ? 1 : 0,
                                    type: 'custom',
                                    payload: (
                                       <div style={{ width: '100px' }}>
                                          <ModalImage
                                             small={getImageSrc(message.message)}
                                             large={getImageSrc(message.message)}
                                             alt="Картинка"
                                          />
                                       </div>
                                    )
                                 }}>
                                 <Message.Footer style={{ gap: '5px' }}>
                                    {message.readed ? (
                                       <Tooltip
                                          placement="bottom"
                                          title={`Прочтено`}>
                                          <EyeOutlined />
                                       </Tooltip>
                                    ) : (
                                       <Tooltip
                                          placement="bottom"
                                          title={`Не прочтено`}>
                                          <EyeInvisibleOutlined />
                                       </Tooltip>
                                    )}
                                    <Tooltip
                                       placement="top"
                                       title={`${dayjs(message.createdAt).format(
                                          'YYYY.MM.DD HH:mm'
                                       )}`}>
                                       {`${getTimeAgo(
                                          dayjs(message.createdAt).unix() * 1000
                                       )}`}
                                    </Tooltip>
                                 </Message.Footer>
                              </Message>
                           ) : (
                              <Message
                                 key={index}
                                 model={{
                                    direction:
                                       message.entityFromType === 'user' ? 1 : 0,
                                    type: 'text',
                                    message: message.message,
                                    position: 'normal'
                                 }}>
                                 <Message.Footer style={{ gap: '5px' }}>
                                    {message.readed ? (
                                       <Tooltip
                                          placement="bottom"
                                          title={`Прочтено`}>
                                          <EyeOutlined />
                                       </Tooltip>
                                    ) : (
                                       <Tooltip
                                          placement="bottom"
                                          title={`Не прочтено`}>
                                          <EyeInvisibleOutlined />
                                       </Tooltip>
                                    )}
                                    <Tooltip
                                       placement="top"
                                       title={`${dayjs(message.createdAt).format(
                                          'YYYY.MM.DD HH:mm'
                                       )}`}>
                                       {`${getTimeAgo(
                                          dayjs(message.createdAt).unix() * 1000
                                       )}`}
                                    </Tooltip>
                                 </Message.Footer>
                              </Message>
                           )}
                        </>
                     ))}
                  </>
               ))}
            </>
         )}
      </MessageList>
   );
};

export default MessageListComponent;
