/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, Alert, DatePicker } from 'antd';
import { statusesOfNotes } from '@shared/const/statuses';
import dayjs from 'dayjs';

import { GetNoteCauses } from '../model/GetNoteCauses';
const { TextArea } = Input;

const disabledDateTime = () => ({
   disabledHours: () => [0, 1, 2, 3, 4, 5]
});

const NoteForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [isDisabledButton, setIsDisabledButton] = useState(true);
   const [causes, setCauses] = useState([]);
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();

   useEffect(() => {
      fetchCauses();
   }, []);

   const fetchCauses = async () => {
      GetNoteCauses().then((res) => {
         setCauses(res?.data || []);
      });
   };

   const onFinish = (values) => {
      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const modifiedInitialValues = {
      status: statusesOfNotes.active.value,
      ...initialValues,
      noteTs: initialValues?.noteTs ? dayjs(initialValues?.noteTs) : dayjs()
   };

   console.log(modifiedInitialValues);

   return (
      <Form
         name="basic"
         style={{
            minWidth: 320
         }}
         form={form}
         onFinish={onFinish}
         initialValues={modifiedInitialValues}
         onFinishFailed={onFinishFailed}
         onValuesChange={() => setIsDisabledButton(false)}
         hideRequiredMark
         layout="vertical">
         <Row gutter={24}>
            <Col span={24}>
               <Form.Item
                  name="cause"
                  label="Причина заметки"
                  rules={[
                     {
                        required: true,
                        message: 'Поле не может быть пустым '
                     }
                  ]}>
                  <Select
                     allowClear
                     showSearch
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите причину"
                     options={Object.values(causes).map((e) => ({
                        label: e,
                        value: e
                     }))}
                  />
               </Form.Item>

               <Form.Item
                  name="noteTs"
                  label="Дата заметки"
                  rules={[
                     {
                        required: true,
                        message: 'Поле не может быть пустым '
                     }
                  ]}>
                  <DatePicker
                     showTime
                     style={{ width: '100%' }}
                     format="YYYY-MM-DD HH:mm"
                     disabledTime={disabledDateTime}
                  />
               </Form.Item>
               <Form.Item
                  name="message"
                  label="Заметка"
                  rules={[
                     {
                        required: true,
                        message: 'Поле не может быть пустым '
                     }
                  ]}>
                  <TextArea
                     rows={8}
                     placeholder="Максимум 800 символов"
                     maxLength={800}
                  />
               </Form.Item>

               {/* <Form.Item
                  name="status"
                  label="Статус"
                  rules={[
                     {
                        required: true,
                        message: 'Выберите статус'
                     }
                  ]}
                  defaultValue={statusesOfNotes.active}>
                  <Select
                     style={{
                        width: '100%'
                     }}
                     defaultValue={statusesOfNotes.active}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     options={Object.values(statusesOfNotes)}
                  />
               </Form.Item> */}
            </Col>
         </Row>

         <Row gutter={[16, 24]}>
            {errorMessage && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message={errorMessage.replace('Error: ', '')}
                     type="error"
                     showIcon
                  />
               </Col>
            )}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button
                     type="primary"
                     htmlType="submit"
                     loading={isLoading}
                     disabled={isDisabledButton}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default NoteForm;
