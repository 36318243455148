import React from 'react';
import TableAcceptances from '@features/list-acceptance/ui/TableAcceptances';
import { Title } from '@shared/ui';

const AcceptancesPage = () => {
   return (
      <div>
         <Title>Список приемок</Title>
         <TableAcceptances />
      </div>
   );
};

export default AcceptancesPage;
