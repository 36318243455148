import { get, generateQueryParams, post } from '../fetch.js';

export const getChat = (params, chatType, chatId) =>
   get(generateQueryParams(`/chat/${chatType}/${chatId}`, params));

export const getChatSupportGroup = (params) =>
   get(generateQueryParams(`/supportchat`, params));

export const createMessage = (fields, chatType, chatId) =>
   post(
      `/chat/${chatType}/${chatId}`,
      {
         ...fields
      },
      true
   );

export const readMessages = (fields, chatType, chatId) =>
   post(
      `/readchat/${chatType}/${chatId}`,
      {
         ...fields
      },
      true
   );

export const unreadChatCount = (params, chatType, chatId) =>
   get(generateQueryParams(`/unreadchat/${chatType}/${chatId}`, params));
