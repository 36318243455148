import { Select } from 'antd';
import { useState, useEffect } from 'react';
import { GetAddressSuggestions } from './model/GetAddressSuggestions';
import _ from 'lodash';

const SelectAddress = (props) => {
   const [addresses, setAddresses] = useState([]);
   const [searchStr, setSearchStr] = useState('');
   const { address, setField } = props;

   const fetchAddresses = (str) => {
      GetAddressSuggestions({
         count: 10,
         address: str
      }).then((res) => {
         const modifiedData = res.data.map((item) => ({
            ...item,
            label: item,
            value: item
         }));
         setAddresses(modifiedData);
      });
   };

   const debouncedSetSearch = _.debounce(setSearchStr, 500);

   useEffect(() => {
      setAddresses([
         {
            value: address,
            label: address
         }
      ]);
      setSearchStr(address);
   }, [address]);

   useEffect(() => {
      if (searchStr) {
         fetchAddresses(searchStr);
      }
   }, [searchStr]);

   // eslint-disable-next-line no-unused-vars
   const onChange = (value, option) => {
      setField(value);
   };

   return (
      <Select
         {...props}
         showSearch
         placeholder="Введите адрес"
         // onChange={onChange}
         // value={searchStr}
         onSearch={(value) => debouncedSetSearch(value)}
         filterOption={false}
         options={addresses}
      />
   );
};
export default SelectAddress;
