import React, { useState, useEffect } from 'react';
import DriverForm from './DriverForm';
import { CreateDriver } from '../model/services/CreateDriver';
import { UpdateDriver } from '../model/services/UpdateDriver';

import { GetDriver } from '../model/services/GetDriver';

import { message } from 'antd';
import _ from 'lodash';

const CreateOrEditDriver = ({ id = null, callbackOnSuccess = () => {} }) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');

   useEffect(() => {
      if (!isEditForm) {
         return;
      }

      GetDriver(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });

      return () => {
         setErrorMessage('');
         setLoadForm(false);
      };
   }, [id]);

   const onSuccess = async (values, setLoading) => {
      setLoading(true);

      if (isEditForm) {
         const driverId = initialValues.id;
         const mergedObj = { ...initialValues, ...values };
         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         await UpdateDriver(updatedFields, driverId)
            .then(() => {
               callbackOnSuccess();
               message.success('Водитель изменен');
            })
            .catch((e) => {
               const errMessage = e?.message || 'Возникла ошибка при сохранении';
               setErrorMessage(errMessage);
               message.error(errMessage);
            })
            .finally(() => setLoading(false));

         return;
      }

      await CreateDriver(values)
         .then(() => {
            message.success('Водитель создан');
            callbackOnSuccess();
         })
         .catch((e) => {
            const errMessage = e?.message || 'Водитель не создан, возникла ошибка';
            setErrorMessage(errMessage);
            message.error(errMessage);
         })
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <DriverForm
         errorMessage={errorMessage}
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
      />
   );
};

export default CreateOrEditDriver;
