import React from 'react';
import { useParams } from 'react-router-dom';
import { Title } from '@shared/ui';
import LeadDescription from '@features/list-lead/ui/LeadDescription';
import RouteDescription from '@features/list-route/ui/RouteDescription';
// import CreateOrEditDriver from '@features/form-driver/ui';
import SellerDescription from '@features/list-seller/ui/SellerDescription';
import DriverDescription from '@features/list-driver/ui/DriverDescription';

// import CreateOrEditRoute from '@features/list-route/ui/CreateOrEditRoute';
// import CreateOrEditAcceptance from '@features/list-acceptance/ui/CreateOrEditAcceptance';
// import CreateOrEditAuction from '@features/list-auction/ui/CreateOrEditAuction';

import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';

const formTypes = {
   leads: {
      title: (id) => `Заявка №${id}`,
      children: (id) => <LeadDescription id={id} />
   },
   // drivers: {
   //    title: (id) => `Водитель №${id}`,
   //    children: (id) => <CreateOrEditDriver id={id} callbackOnSuccess={() => {}} />
   // },
   sellers: {
      title: (id) => `Продавец №${id}`,
      children: (id) => <SellerDescription id={id} />
   },
   drivers: {
      title: (id) => `Водитель №${id}`,
      children: (id) => <DriverDescription id={id} />
   },
   routes: {
      title: (id) => `Маршрут №${id}`,
      children: (id) => <RouteDescription id={id} />
   }
   // acceptances: {
   //    title: (id) => `Приемка №${id}`,
   //    children: (id) => (
   //       <CreateOrEditAcceptance id={id} callbackOnSuccess={() => {}} />
   //    )
   // },
   // auctions: {
   //    title: (id) => `Торг №${id}`,
   //    children: (id) => <CreateOrEditAuction id={id} callbackOnSuccess={() => {}} />
   // }
};

const EntityPage = ({ type }) => {
   const { id } = useParams();
   const navigate = useNavigate();

   return (
      <div>
         {/* <div style={{ margin: '25px 0' }}>
            <Space size="large" align="center">
               <Button
                  icon={<ArrowLeftOutlined />}
                 onClick={() => navigate(-1)}></Button>
               <Title style={{ padding: 0, margin: 0 }}>
                  {formTypes[type].title(id)}
               </Title>
            </Space>
         </div> */}
         {formTypes[type].children(id)}
      </div>
   );
};

export default EntityPage;
