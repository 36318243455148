import { get, post, put, patch, generateQueryParams, _delete } from '../fetch.js';

export const getLeadById = (id) => get(`/lead/${id}`);
export const getLeads = (params) => get(generateQueryParams(`/leads`, params));

export const deleteLeadById = (id) => _delete(`/lead/${id}`, {}, true);

export const updateStatusOfField = (fields, id) =>
   patch(
      `/lead/${id}`,
      {
         ...fields
      },
      true
   );

export const createLead = (fields) =>
   post(
      '/lead',
      {
         ...fields
      },
      true
   );

export const createPublicLead = (fields) =>
   post(
      '/public/lead',
      {
         ...fields
      },
      true
   );

export const updateLead = (fields, id) =>
   put(
      `/lead/${id}`,
      {
         ...fields
      },
      true
   );
