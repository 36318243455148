import { Steps, Tag, Tooltip } from 'antd';
import { statusesOfLeads, statusesOfRoutes } from '@shared/const/statuses';

const statusesKeys = {
   lead: statusesOfLeads,
   route: statusesOfRoutes
};

const StatusProgressSteps = ({ status, type, isQueue = false }) => {
   const statusesOfEntity = statusesKeys?.[type] || statusesOfLeads;
   const stepArr = statusesOfEntity[status]?.stages?.map((e) => {
      return {
         title: (
            <>
               <Tag className="status-tag" color={statusesOfEntity[e].color}>
                  {statusesOfEntity[e].label}{' '}
               </Tag>
               {statusesOfEntity[e].value === 'active' && isQueue ? (
                  <Tooltip
                     placement="top"
                     title={'Маршрут находится в очереди у водителя'}>
                     <Tag
                        className="status-tag"
                        color="rgb(95 95 95)"
                        style={{ marginTop: '5px' }}>
                        В очереди
                     </Tag>
                  </Tooltip>
               ) : (
                  ''
               )}
            </>
         ),
         value: statusesOfEntity[e].value
      };
   });

   const currentIndex = stepArr?.findIndex((item) => item.value === status) || 0;

   return <Steps size="small" current={currentIndex} progressDot items={stepArr} />;
};

export default StatusProgressSteps;
