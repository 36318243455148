import { useSelector } from 'react-redux';
import { getUserAuthData } from '@entitles/User';
import { Alert } from 'antd';

const CanDoManager = ({ children, userId, alertMessage }) => {
   const userData = useSelector(getUserAuthData);
   const currentUserId = userData?.id;

   if (['admin', 'superadmin'].includes(userData?.role)) {
      return children;
   }

   if (!userId || !currentUserId || currentUserId !== userId) {
      if (alertMessage && currentUserId && userId && currentUserId !== userId) {
         return <Alert showIcon message={alertMessage} type="warning" />;
      }

      return null;
   }

   return children;
};

export default CanDoManager;
