import { post, get, generateQueryParams } from '../fetch.js';

export const createCustomPushNotification = (fields) =>
   post(
      '/push-notification',
      {
         ...fields
      },
      true
   );

export const getNotifications = (params) =>
   get(generateQueryParams(`/push-notifications`, params));
