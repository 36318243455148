import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, Divider, message, Alert } from 'antd';
import { UpdateCategory } from '../model/services/UpdateCategory';
import { v4 as uuidv4 } from 'uuid';

import { VerticalSpace } from '@shared/ui';

const initialObj = () => ({
   id: uuidv4(),
   paramOfCapacity: 'ot',
   priceType: 'free'
});

const ModalMaterialCategoryCapacityRuleForm = (props) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const { selectedCategory, closeModal } = props;

   useEffect(() => {
      setIsModalOpen(!!selectedCategory?.id);
      setData(selectedCategory?.capacityRules || []);
   }, [selectedCategory]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const save = () => {
      let errorMessage = '';

      data.forEach((item) => {
         if (
            (item.paramOfCapacity === 'ot' || item.paramOfCapacity === 'otido') &&
            !item.capacityFrom
         ) {
            errorMessage = 'Укажите вес от';
         }

         if (
            (item.paramOfCapacity === 'do' || item.paramOfCapacity === 'otido') &&
            !item.capacityTo
         ) {
            errorMessage = 'Укажите вес от';
         }

         // if (item.priceType === 'export' && !item.price) {
         //    errorMessage = 'Укажите цену';
         // }
      });

      if (errorMessage) {
         message.error(errorMessage);
         return;
      }

      setIsLoading(true);
      UpdateCategory({ capacityRules: data }, selectedCategory?.id)
         .then(() => {
            closeModal();
            message.success('Настройки обновлены');
         })
         .catch((e) => message.error(e?.message || 'Возникла ошибка при сохранении'))
         .finally(() => setIsLoading(false));
   };

   const addEmptyRow = () => {
      setData([...data, initialObj()]);
   };

   const deleteRow = (id) => {
      setData(data.filter((e) => e.id !== id));
   };

   const onChange = (id, type, value) => {
      const newData = [...data];
      newData.find((e) => e.id === id)[type] = value;

      if (type === 'paramOfCapacity') {
         if (value === 'ot') {
            newData.find((e) => e.id === id).capacityTo = null;
         }

         if (value === 'do') {
            newData.find((e) => e.id === id).capacityFrom = null;
         }
      }

      // if (type === 'priceType') {
      //    if (value !== 'export') {
      //       newData.find((e) => e.id === id).price = null;
      //    }
      // }

      setData(newData);
   };

   const diapasonOfCapacitys = data.map((item) => item.paramOfCapacity);

   return (
      <Modal
         title={`Ограничитель веса для категории`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         footer={null}
         forceRender={true}
         destroyOnClose={true}
         width={1200}>
         {data.map((item) => (
            <Row gutter={24} key={`ok-${item.id}`}>
               <Col span={4}>
                  <Form.Item
                     name="paramOfCapacity"
                     label="Диапазон веса"
                     rules={[
                        {
                           required: true,
                           message: 'Пропустили поле'
                        }
                     ]}>
                     <Select
                        defaultValue={item?.paramOfCapacity || 'ot'}
                        value={item.paramOfCapacity}
                        style={{
                           width: '100%'
                        }}
                        onChange={(value) =>
                           onChange(item.id, 'paramOfCapacity', value)
                        }
                        options={[
                           {
                              label: 'от',
                              value: 'ot'
                           },
                           {
                              label: 'от и до',
                              value: 'otido'
                           },
                           {
                              label: 'до',
                              value: 'do'
                           }
                        ].map((e) => ({
                           ...e,
                           disabled: !!diapasonOfCapacitys.includes(e.value)
                        }))}
                     />
                  </Form.Item>
               </Col>
               {item.paramOfCapacity === 'otido' || item.paramOfCapacity === 'ot' ? (
                  <Col span={4}>
                     <Form.Item
                        name="capacityFrom"
                        label="Вес от"
                        rules={[
                           {
                              required: true,
                              message: 'Укажите вес'
                           }
                        ]}>
                        <Input
                           style={{
                              width: '100%'
                           }}
                           defaultValue={item.capacityFrom}
                           onChange={(e) =>
                              onChange(item.id, 'capacityFrom', e.target.value)
                           }
                           suffix="кг"
                           type="number"
                        />
                     </Form.Item>
                  </Col>
               ) : null}

               {item.paramOfCapacity === 'otido' || item.paramOfCapacity === 'do' ? (
                  <Col span={4}>
                     <Form.Item
                        name="capacityTo"
                        label="Вес до"
                        rules={[
                           {
                              required: true,
                              message: 'Укажите приоритет'
                           }
                        ]}>
                        <Input
                           style={{
                              width: '100%'
                           }}
                           defaultValue={item.capacityTo}
                           onChange={(e) =>
                              onChange(item.id, 'capacityTo', e.target.value)
                           }
                           suffix="кг"
                           type="number"
                        />
                     </Form.Item>
                  </Col>
               ) : null}

               <Col span={5}>
                  <Form.Item
                     name="priceType"
                     label="Выбор типа цены"
                     rules={[
                        {
                           required: true,
                           message: 'Выберите тип цены'
                        }
                     ]}
                     defaultValue="free">
                     <Select
                        style={{
                           width: '100%'
                        }}
                        defaultValue={item?.priceType || 'free'}
                        placeholder="Выберите тип цены"
                        onChange={(value) => onChange(item.id, 'priceType', value)}
                        rules={[
                           {
                              required: true,
                              message: 'Поле не может быть пустым '
                           }
                        ]}
                        options={[
                           {
                              label: 'Бесплатно',
                              value: 'free'
                           },
                           {
                              label: 'Позвонить менеджеру',
                              value: 'export'
                           },
                           {
                              label: 'С выкупом (платим мы)',
                              value: 'ransom'
                           }
                        ]}
                     />
                  </Form.Item>
               </Col>

               {/* {item.priceType === 'export' ? (
                  <Col span={3}>
                     <Form.Item
                        name="price"
                        label="Цена"
                        rules={[
                           {
                              required: true,
                              message: 'Укажите цену'
                           }
                        ]}>
                        <Input
                           style={{
                              width: '100%'
                           }}
                           defaultValue={item.price}
                           suffix="руб"
                           onChange={(e) =>
                              onChange(item.id, 'price', e.target.value)
                           }
                           type="number"
                        />
                     </Form.Item>
                  </Col>
               ) : null} */}

               <Col span={3}>
                  <Form.Item
                     label="Действие"
                     rules={[
                        {
                           required: true,
                           message: 'Укажите'
                        }
                     ]}>
                     <Button
                        type="primary"
                        danger
                        onClick={() => deleteRow(item.id)}>
                        Удалить
                     </Button>
                  </Form.Item>
               </Col>
               <Divider style={{ margin: '5px 5px 20px 5px' }} />
            </Row>
         ))}

         <Col className="gutter-row" span={24} className="stats-card-count-col">
            <Alert
               message="Для типа цены 'с выкупом', цены настраиваются в Настройке цен, в другом окне"
               type="info"
               showIcon
            />
         </Col>
         <VerticalSpace />
         <Button type="primary" onClick={addEmptyRow}>
            Добавить ограничение
         </Button>

         <VerticalSpace />

         <Form.Item>
            <Button type="primary" onClick={save} loading={isLoading}>
               Сохранить
            </Button>
         </Form.Item>
      </Modal>
   );
};

export default ModalMaterialCategoryCapacityRuleForm;
