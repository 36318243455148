import { Pagination, Typography } from 'antd';
import React from 'react';
import { PAGINATION_PAGESIZE_LOCALSTORAGE_KEY } from '@shared/const/localStorage';
import {
   getLocalStorageByKey,
   setLocalStorageByKey
} from '@shared/lib/localStorage';
const { Text } = Typography;

const initialPaginationSettings = () => ({
   current: 1,
   pageSize: 10,
   // pageSize: window.paginationPageSize || 10,
   total: 0,
   defaultCurrent: 1
});

const loadAndSavePaginationPageSize = () => {
   getLocalStorageByKey(PAGINATION_PAGESIZE_LOCALSTORAGE_KEY).then((res) => {
      if (res) {
         window.paginationPageSize = 10;
      }
   });
};

const PaginationComponent = ({
   isLoading = false,
   onChangePagination,
   pagination
}) => {
   if (pagination.total === 0) {
      return <></>;
   }

   const onChange = (current) => {
      window.paginationPageSize = 10; // pageSize
      setLocalStorageByKey(PAGINATION_PAGESIZE_LOCALSTORAGE_KEY, 10);
      onChangePagination(current, 10);
   };

   // let pag = {};
   // Object.keys(pagination).map((e) => (pag[e] = parseInt(pagination[e])));

   return (
      <Pagination
         {...pagination}
         direction="vertical"
         onChange={onChange}
         disabled={isLoading}
         // showQuickJumper
         showSizeChanger={false}
      />
   );
};

export { initialPaginationSettings, loadAndSavePaginationPageSize };
export default PaginationComponent;
