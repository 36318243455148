import { useState, useEffect } from 'react';
import { getCountOfService } from '@shared/api/all/stats';
import { Tag, Card, Space, Typography } from 'antd';
import {
   statusesOfLeads,
   statusesOfSellers,
   statusesOfDrivers,
   statusesOfRoutes
} from '@shared/const/statuses';

import { StatusTag } from '@shared/ui';
const { Text } = Typography;

const types = {
   lead: statusesOfLeads,
   seller: statusesOfSellers,
   route: statusesOfRoutes,
   driver: statusesOfDrivers
};

const ServiceCountCard = (props) => {
   const { serviceName, serviceTitle, icon } = props;
   const [statuses, setStatuses] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      getAllStatuses();
   }, []);

   const getAllStatuses = () => {
      if (!types[serviceName]) {
         return;
      }

      const statusesArr = Object.values(types[serviceName]);
      if (statusesArr) {
         statusesArr.map((status) => {
            if (status.showInMain) {
               fetchData(status);
            }
         });
      }
   };

   const fetchData = (status) => {
      setIsLoading(true);
      getCountOfService(serviceName, { status: status.value })
         .then((res) => {
            setStatuses((e) => [
               ...e,
               {
                  label: status.label,
                  value: status.value,
                  count: res?.json?.data || 0
               }
            ]);
            setIsLoading(false);
         })
         .catch(() => setIsLoading(false));
   };

   const summOfAllStauses = statuses.reduce((acc, cur) => acc + cur.count, 0);

   return (
      <Card
         bordered={false}
         loading={isLoading}
         title={
            <Space size="small">
               {icon} {serviceTitle}
               <Text
                  type="secondary"
                  style={{ fontSize: '12px', marginLeft: '5px' }}>
                  Всего: {summOfAllStauses}
               </Text>
            </Space>
         }>
         {statuses.map((element) => (
            <div key={`stats-${element?.value}`}>
               <Space
                  direction="horizontal"
                  size="small"
                  style={{ marginBottom: '5px' }}>
                  <Tag style={{ minWidth: '40px', textAlign: 'center' }}>
                     {element?.count}
                  </Tag>
                  <StatusTag status={element?.value} type={serviceName} />
               </Space>
            </div>
         ))}
      </Card>
   );
};

export default ServiceCountCard;
