import React from 'react';
import { Button, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';

const EnterToEntityButton = (props) => {
   const { id, entity = '' } = props;
   const navigate = useNavigate();

   const open = () => {
      navigate(`/${entity}/${id}`);
   };

   return (
      <Tooltip placement="top" title="Открыть">
         <Button onClick={open}>
            <EyeOutlined />
         </Button>
      </Tooltip>
   );
};

export default EnterToEntityButton;
