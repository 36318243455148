import { updateAcceptance } from '@shared/api/all/acceptance';

export const UpdateAcceptance = async (fields, id) => {
   try {
      const response = await updateAcceptance(fields, id);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
