import dayjs from 'dayjs';

export const normalizeLeadTimes = (leadTimes, initialValues) => {
   return leadTimes.map((e) => {
      const firstTime = dayjs(e?.timeArr[0]);
      const secondTime = dayjs(e?.timeArr[1]);
      let date = e?.fromTimestamp || initialValues.fromTimestamp;

      const timeObj = {
         fromTimestamp: dayjs(date)
            .set('hour', firstTime.hour())
            .set('minute', firstTime.minute())
            .utc()
            .format(),
         toTimestamp: dayjs(date)
            .set('hour', secondTime.hour())
            .set('minute', secondTime.minute())
            .utc()
            .format()
      };

      if (e?.id) {
         timeObj.id = e.id;
      }

      return timeObj;
   });
};
