import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, List, Tooltip, Switch } from 'antd';

import { GetLeadList } from '../model/GetLeadList';
import { UpdateStatusOfLead } from '../model/UpdateStatusOfLead';
import { Button, VerticalSpace, DateWithAgo, StatusProgress } from '@shared/ui';
import DriverInfoByRouteId from '@features/list-driver/ui/DriverInfoByRouteId';

import { EditOutlined, StopOutlined, ClockCircleOutlined } from '@ant-design/icons';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalButtonLeadCreate from './ModalButtonLeadCreate';
import { statusesOfLeads } from '@shared/const/statuses';
import { unitSettings } from '@shared/const/units';
import ModalButtonLeadsMap from './ModalButtonLeadsMap';

import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import { StatusTag } from '@shared/ui';
import ModalLeadForm from './ModalLeadForm';
import EnterToEntityButton from '@widgets/Button/EnterToEntityButton';
import ModalButtonRatingCreateToEntityWithSMS from '@features/list-rating/ui/ModalButtonRatingCreateToEntityWithSMS';
import CanDo from '@shared/lib/CanDo';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import { changeUrl } from '@shared/utils/browser';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import CanDoManager from '@shared/lib/CanDoManager';
import { getUserAuthData } from '@entitles/User';
import { useSelector } from 'react-redux';
import ExportToCSVModalButton from '@widgets/ExportToCSV/ExportToCSVModalButton';
import {
   StatusesDateTimeLine,
   StatusesDateTimeLineXronology
} from '@widgets/Table/StatusesDateTimeLine';

import UserCardById from '@features/list-user/ui/UserCardById';
import ModalButtonNoteCreate from '@features/notes/ui/ModalButtonNoteCreate';
const { Text } = Typography;

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const TableLeads = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedLead, setSelectedLead] = useState(null);
   const userData = useSelector(getUserAuthData);
   const navigate = useNavigate();

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const params = parseUrlListSettings();

         fetchData(params);
      }
   }, []);

   const columns = [
      {
         title: 'Заявка',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         fixed: 'left',
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         filtered: !!filters?.['id'],
         width: '220px',
         filteredValue: filters?.['id'] || null,
         render: (_, record) => (
            <Space direction="vertical">
               <Space direction="horizontal">
                  <span
                     className="green-span-url"
                     style={{ padding: '5px 0', whiteSpace: 'nowrap' }}
                     onClick={() => navigate(`/leads/${_}`)}>
                     {`Заявка №${_}`}
                  </span>

                  <NewEntityTag date={record.createdAt} />
               </Space>

               <Tag bordered={false}>
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                     {record.materialCategory.name}:{' '}
                     {record?.capacity
                        ? `${record.capacity} ${suffix(
                             record.materialCategory.unit
                          )}`
                        : `Не указан вес`}
                  </Text>
               </Tag>

               {/* {record?.materialCategory?.name && (
                  <Tag color={stringToColor(record.materialCategory.name)}>
                     {record.materialCategory.name}:{' '}
                     {record?.capacity && `${record.capacity} кг`}
                  </Tag>
               )} */}
            </Space>
         ),
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      },
      // {
      //    title: 'Заявка',
      //    dataIndex: 'id',
      //    key: 'id',
      //    render: (_) => (
      //       <Space size="small" wrap>
      //          {`Заявка №${_}`}
      //       </Space>
      //    )
      // },
      // {
      //    title: "Район",
      //    dataIndex: "districtId",
      //    key: "districtId",
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          {record?.addresses?.districtInfo?.city_district}
      //       </Space>
      //    ),
      //    filtered: !!filters?.["districtId"],
      //    ...getConstColumnSearchFilterProps({
      //       options: districtList,
      //       dataIndex: "districtId",
      //       handleSearch: (searchObj) => onSearchTable(searchObj),
      //    }),
      // },
      // {
      //    title: 'Адрес',
      //    dataIndex: 'address',
      //    key: 'address',
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          {record?.address?.address}
      //       </Space>
      //    )
      // },
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         width: '300px',
         fixed: 'left',
         filterMode: true,
         filteredValue: Array.isArray(paramsTable?.filters?.status)
            ? paramsTable?.filters?.status
            : paramsTable?.filters?.status
            ? [paramsTable?.filters?.status]
            : [],
         filters: Object.values(statusesOfLeads).map((e) => ({
            text: <StatusTag status={e.value} type="lead" />,
            value: e.value
         })),
         // filteredValue: filteredInfo.name || null,
         // onFilter: (value, record) => record.name.includes(value),
         // sorter: (a, b) => a.name.length - b.name.length,
         // sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
         // ellipsis: true,
         render: (_, record) => (
            <StatusesDateTimeLine
               createdAt={record.createdAt}
               updatedAt={record.updatedAt}
               entityId={record.id}
               entityType="lead"
               isReverse={true}
            />
         )
         // <StatusProgress status={_} type="lead" showInfo={false} />
         // filtered: !!filters?.['status'],
         // ...getStatusColumnFilterProps({
         //    options: Object.values(statusesOfLeads),
         //    dataIndex: 'status',
         //    handleSearch: (searchObj) => onSearchTable(searchObj)
         // })
      },
      // {
      //    title: 'Комментарий к статусу',
      //    dataIndex: 'commentStatus',
      //    width: '180px',
      //    render: (_, record) => (
      //       <div style={{ fontSize: '12px' }}>
      //          <Text type="secondary" style={{ fontSize: '12px' }}>
      //             {timestampToNormalDDMMYY(record?.statusObj.updatedAt)}{' '}
      //             {timestampToNormalTime(record?.statusObj.updatedAt)}
      //          </Text>
      //          <div>{record?.statusObj?.comment || 'Без пометок'} </div>
      //       </div>
      //    )
      // },
      {
         title: 'Стоимость',
         dataIndex: 'price',
         key: 'price',
         width: '180px',
         render: (_, record) => (
            <Space size="small" direction="vertical" wrap>
               {_
                  ? `${_} ${record.priceType === 'unit' ? 'руб/кг' : 'руб'}`
                  : 'Бесплатно'}

               {record.priceType === 'unit' && (
                  <Tag bordered={false}>
                     <Text type="secondary" style={{ fontSize: '12px' }}>
                        {_
                           ? `Общая: ${`${record?.finalPrice} руб` || 'неизвестно'}`
                           : ''}
                     </Text>
                  </Tag>
               )}
            </Space>
         )
      },
      {
         title: 'Маршрут',
         dataIndex: 'routeId',
         key: 'routeId',
         width: '140px',
         filtered: !!filters?.['routeId'],
         filteredValue: filters?.['routeId'] || null,
         render: (_) => (
            <Space size="small" wrap>
               {_ ? (
                  <span
                     className="green-span-url"
                     style={{ padding: '5px 0' }}
                     onClick={() => navigate(`/routes/${_}`)}>
                     {`№${_}`}
                  </span>
               ) : (
                  'Нет'
               )}
            </Space>
         ),
         ...getColumnSearchProps({
            dataIndex: 'routeId',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      },
      // {
      //    title: 'Вес',
      //    dataIndex: 'capacity',
      //    key: 'capacity',
      //    sorter: true,
      //    render: (_) => (
      //       <Space size="small" wrap>
      //          {`${_} кг`}
      //       </Space>
      //    )
      // },
      // {
      //    title: 'Дата создания',
      //    dataIndex: 'createdAt',
      //    key: 'createdAt',
      //    align: 'center',
      //    width: '140px',
      //    sorter: true,
      //    sortOrder:
      //       paramsTable?.sorter?.sort === 'createdAt'
      //          ? `${paramsTable?.sorter?.order}end`
      //          : null,
      //    render: (_) => <DateWithAgo date={_} />
      // },
      {
         title: 'Даты вывоза',
         dataIndex: 'leadsTimes',
         key: 'leadsTimes',
         width: '320px',
         align: 'left',
         render: (_, record) => (
            <>
               {record?.leadsTimes?.length > 0 && (
                  <Space size="small" direction="vertical">
                     {record.leadsTimes.map((item) => (
                        <Space
                           size="small"
                           key={`leadtimes-${item.id}-${item.createdAt}`}>
                           <Tag>{timestampToNormalDDMMYY(item.fromTimestamp)}</Tag>

                           <ClockCircleOutlined style={{ color: '#00000073' }} />
                           <Text type="secondary" style={{ fontSize: '12px' }}>
                              {`с ${timestampToNormalTime(
                                 item.fromTimestamp
                              )} по ${timestampToNormalTime(item.toTimestamp)}`}
                           </Text>
                        </Space>
                     ))}
                  </Space>
               )}
            </>
         )
      },
      {
         title: 'Назначенный водитель',
         dataIndex: 'updatedTs',
         key: 'updatedTs',
         width: '300px',
         render: (_, record) => <DriverInfoByRouteId id={record?.routeId} />
      }

      // {
      //    title: "Комментарий",
      //    dataIndex: "comment",
      //    key: "comment",
      //    width: 240,
      //    render: (_) => <div className="block-break-word">{_}</div>,
      // },
   ];

   const actions = [
      // {
      //    title: 'Категория',
      //    dataIndex: 'materialCategoryId',
      //    key: 'materialCategoryId',
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          {record?.materialCategory?.name && (
      //             <Tag color={stringToColor(record.materialCategory.name)}>
      //                {record.materialCategory.name}
      //             </Tag>
      //          )}
      //       </Space>
      //    )
      // },
      {
         title: 'Продавец',
         dataIndex: 'addresses',
         width: '360px',
         key: 'addresses',
         render: (_, record) => (
            <Space size="small" wrap>
               <div className="address_list">
                  <List
                     itemLayout="horizontal"
                     dataSource={[record?.address?.seller || []]}
                     renderItem={(item) => (
                        <List.Item key={item.toString()}>
                           {item?.firstName && (
                              <List.Item.Meta
                                 title={`${item.firstName} ${item.lastName}`}
                                 description={
                                    <>
                                       <div>{record?.address?.address}</div>
                                       <div>
                                          Телефон:{' '}
                                          {record?.address?.seller?.mobileNumber}
                                       </div>{' '}
                                       {record?.address?.districtName ? (
                                          <div>{`Район: ${record?.address?.districtName}`}</div>
                                       ) : null}
                                       {record?.address?.typeOfAddress ? (
                                          <div>{`Место вывоза: ${record?.address?.typeOfAddress}`}</div>
                                       ) : null}
                                    </>
                                 }
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '100%'
                                 }}
                              />
                           )}
                        </List.Item>
                     )}
                  />
               </div>
            </Space>
         )
      },

      {
         title: 'Ответственный',
         dataIndex: 'userId',
         width: '200px',
         key: 'userId',
         filtered: !!filters?.['userId'],
         filteredValue: filters?.['userId'] || null,
         ...getColumnSearchProps({
            dataIndex: 'userId',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),
         render: (userId) => <UserCardById id={userId} />
      },
      {
         title: 'Хронология событий',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         width: '320px',
         align: 'left',
         render: (_, record) => (
            <StatusesDateTimeLineXronology
               createdAt={record.createdAt}
               updatedAt={record.updatedAt}
               entityId={record.id}
               entityType="lead"
               isReverse={true}
            />
         )
      },
      {
         title: 'Действие',
         key: 'action',
         width: 200,
         fixed: 'right',
         render: (_, record) => (
            <Space size="middle">
               <EnterToEntityButton id={record.id} entity="leads" />
               <CanDo permission="can_edit_leads">
                  <Space size="middle">
                     <CanDoManager userId={record.userId}>
                        <Tooltip placement="top" title={'Редактирование'}>
                           <Button
                              onClick={() => setSelectedLead(record)}
                              type="primary">
                              <EditOutlined />
                           </Button>
                        </Tooltip>{' '}
                     </CanDoManager>

                     <Tooltip placement="top" title={'Добавить заметку'}>
                        <ModalButtonNoteCreate
                           closeModal={closeModal}
                           entityId={record.id}
                           entityType={'lead'}
                           buttonText={null}
                        />{' '}
                     </Tooltip>

                     {record.status === 'finished' && (
                        <Tooltip placement="top" title={'Оценить'}>
                           <ModalButtonRatingCreateToEntityWithSMS
                              leadId={record.id}
                              id={record?.rating?.id || null}
                              isSendedSmsRating={record.isSendedSmsRating === 1}
                              valuesOfRating={{
                                 actionForEntityType: 'lead',
                                 actionForEntityId: record?.id,
                                 fromEntityType: 'seller',
                                 fromEntityId: record?.address?.seller.id,
                                 toEntityType: 'driver',
                                 toEntityId: record?.driver?.id,
                                 status: 'active',
                                 categoryOfRating: 1
                              }}
                              closeModal={fetchData}
                           />
                        </Tooltip>
                     )}

                     {/* {record.status === 'active' && (
                        <Tooltip placement="top" title={'Заблокировать'}>
                           <Button
                              type="primary"
                              danger
                              onClick={() => updateLeadStatusRequest(record.id)}>
                              <StopOutlined />
                           </Button>
                        </Tooltip>
                     )} */}

                     {/* {record.status === 'blocked' && (
                        <DeleteEntityButton
                           id={record.id}
                           callbackFetch={deleteLeadById}
                           update={fetchData}
                           permission="can_delete_leads"
                        />
                     )} */}
                  </Space>
               </CanDo>
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   // const updateLeadStatusRequest = (leadId) => {
   //    UpdateStatusOfLead({ status: 'blocked' }, leadId).then(() => fetchData());
   // };

   const closeModal = () => {
      setSelectedLead(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      const queryObj = getApiQueryParamsForTables(
         params,
         paramsTable,
         initialSorter
      );
      const { page: current, limit: pageSize } = queryObj;

      GetLeadList(queryObj).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, current, pageSize, total: res.count }
         });

         changeUrl(params);
         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   const showOnlyMy = (bool) => {
      const newParams = {
         ...paramsTable,
         filters: {
            ...paramsTable.filters,
            userId: userData.id.toString()
         }
      };

      if (!bool) {
         delete newParams.filters.userId;
      }

      fetchData(newParams);
   };

   return (
      <>
         <ModalLeadForm selectedLead={selectedLead} closeModal={closeModal} />

         <CanDo permission="can_create_leads">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonLeadCreate closeModal={closeModal} />
               <ModalButtonLeadsMap />
               <Space style={{ marginLeft: '10px' }} size="small">
                  Показать
                  <Switch
                     checkedChildren="Мои"
                     unCheckedChildren="Все"
                     checked={filters.userId === userData.id.toString()}
                     onChange={showOnlyMy}
                  />
               </Space>
            </Space>
         </CanDo>
         <VerticalSpace />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
                  <ExportToCSVModalButton
                     type="lead"
                     fetchData={GetLeadList}
                     dataParams={paramsTable}
                  />
               </Space>
            </div>
         </div>

         <Table
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            rowKey="id"
            onChange={handleTableChange}
            loading={isLoading}
            scroll={{ x: 1500 }}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableLeads;
