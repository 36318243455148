import React, { useEffect, useState } from 'react';
import { GetUserById } from '@features/list-user/model/GetUserById';
import { Typography, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
const { Text } = Typography;

const roles = [
   {
      value: 'manager',
      label: 'Менеджер'
   },
   {
      value: 'support',
      label: 'Поддержка'
   },
   {
      value: 'admin',
      label: 'Админ'
   },
   {
      value: 'superadmin',
      label: 'Супер Админ'
   }
];

const UserCardById = ({ id }) => {
   const [user, setUser] = useState([]);

   useEffect(() => {
      fetchUserById();
   }, [id]);

   const fetchUserById = async () => {
      if (!id) return;

      GetUserById(id).then((res) => {
         setUser(res?.data ? [res.data] : []);
      });
   };

   return (
      <>
         {!id ? (
            `Не указано`
         ) : (
            <>
               {user?.map((item) => (
                  <div key={`l${item.id}`} className="user-card-by-id">
                     <Avatar icon={<UserOutlined />} />
                     <div>
                        <div>
                           {item?.firstName || 'Не указано'} {item?.lastName}
                        </div>
                        <div>
                           <Text type="secondary">ID:{item.id}</Text>{' '}
                           {item?.role ? (
                              <Text type="secondary">
                                 Роль:{' '}
                                 {roles.find((e) => e.value === item.role).label}
                              </Text>
                           ) : null}{' '}
                        </div>
                        {/* <div>
                           {item?.post ? (
                              <Text type="secondary">Должность: {item.post}</Text>
                           ) : null}{' '}
                        </div> */}
                     </div>
                  </div>
               ))}
            </>
         )}
      </>
   );
};

export default UserCardById;
