/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Alert } from '@shared/ui';
import TableRatings from '@features/list-rating/ui/TableRatings';
import { Title, Select } from '@shared/ui';

const RatingsPage = () => {
   const [typeOfRating, setTypeOfRating] = useState('udr');

   return (
      <div>
         <Title>Список рейтингов</Title>
         <Select
            placeholder="Выберите тип рейтинга"
            style={{ width: '400px' }}
            defaultValue={typeOfRating}
            onChange={(value) => setTypeOfRating(value)}
            options={[
               {
                  value: 'udr',
                  label: 'Рейтинг от пользователей, водителям за маршруты'
               },
               {
                  value: 'sdl',
                  label: 'Рейтинг от продавцов, водителям за заявки'
               },
               {
                  value: 'dsl',
                  label: 'Рейтинг от водителей, продавцам за заявки'
               }
            ]}
         />
         {typeOfRating === 'sdl' && (
            <Alert
               style={{ marginTop: '15px', width: '800px' }}
               message="Эти рейтинги напрямую получаем от продавцов с помощью смс рассылки."
               type="info"
               showIcon
            />
         )}
         {typeOfRating && <TableRatings type={typeOfRating} />}

         <div style={{ margin: '15px 0' }}></div>
      </div>
   );
};

export default RatingsPage;
