import { getAuctionPricesById } from '@shared/api/all/auction';

export const GetAuctionPricesList = async (id, params) => {
   try {
      const response = await getAuctionPricesById(id, params);
      if (!response.json) throw new Error();
      return { data: response.json.data, count: response.json.count };
   } catch (e) {
      console.log(e);
      throw new Error(e);
   }
};
