const unitSettings = [
   {
      value: 'kg',
      label: 'Килограмм',
      shortLabel: 'кг'
   },
   {
      value: 'litr',
      label: 'Литр',
      shortLabel: 'л'
   },
   {
      value: 'piece',
      label: 'Штука',
      shortLabel: 'шт'
   },
   {
      value: 'ton',
      label: 'Тонна',
      shortLabel: 'т'
   },
   {
      value: 'kvm',
      label: 'Квадратный метр',
      shortLabel: 'м²'
   }
];
export { unitSettings };
