/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Space } from 'antd';
import { Alert, Button, Title, Select } from '@shared/ui';
import TableRatings from '@features/list-rating/ui/TableRatings';
import ModalFormSendPushNotification from './ModalFormSendPushNotification';
import CanDo from '@shared/lib/CanDo';
import { useNavigate } from 'react-router-dom';

const MobileAppsPage = () => {
   const navigate = useNavigate();

   return (
      <div>
         <Title>Мобильные приложения</Title>
         <Space direction="horizontal" size={12}>
            <Button onClick={() => navigate('/apps/mobile/numbers')}>
               Номера телефонов введеные в приложениях при регистрации
            </Button>

            <CanDo permission="send_custom_push_notification">
               <ModalFormSendPushNotification />
            </CanDo>
         </Space>
      </div>
   );
};

export default MobileAppsPage;
