import React, { useState, useEffect } from 'react';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { getCenter } from 'geolib';
import _ from 'lodash';

const isArrayEqual = function (x, y) {
   return _(x).xorWith(y, _.isEqual).isEmpty();
};

const ListLeadsOnMap = ({ leads }) => {
   const [centerOfMap, setCenterOfMap] = useState({ center: [0, 0], zoom: 10 });
   const [listCoordinates, setListCoordinates] = useState([]);

   useEffect(() => {
      loadCenter(leads);
   }, [leads]);

   const loadCenter = (ls) => {
      const dot = ls.map((e) => ({
         latitude: e.address.geoLat,
         longitude: e.address.geoLon
      }));

      if (isArrayEqual(dot, listCoordinates)) {
         return;
      }

      const centerCoords = getCenter(dot);
      setListCoordinates(dot);
      setCenterOfMap((prev) => ({
         ...prev,
         center: [centerCoords.latitude, centerCoords.longitude]
      }));
   };

   const mapRef = React.createRef();

   return (
      <>
         <YMaps>
            <div ref={mapRef}>
               <Map
                  height={'400px'}
                  width={'100%'}
                  state={centerOfMap}
                  defaultState={{ zoom: 2 }}>
                  {leads.map((l) => (
                     <Placemark
                        key={`placemark-${l.id}`}
                        properties={{
                           iconContent: `<div class="placemark-title status-${l.status}">Заявка №${l?.id}</div>`
                        }}
                        defaultGeometry={[l.address?.geoLat, l.address?.geoLon]}
                     />
                  ))}
               </Map>
            </div>
         </YMaps>
      </>
   );
};

export default ListLeadsOnMap;
