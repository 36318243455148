/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select, Alert } from '@shared/ui';
import { statusesOfRoutes } from '@shared/const/statuses';

import { Col, Row, InputNumber, Card, Tag } from 'antd';

import SelectDriver from '@widgets/FormItems/SelectDriver';
import SelectLeads from '@widgets/FormItems/SelectLeads';
import { statusesOfDrivers } from '@shared/const/statuses';
import { message } from 'antd';

const { TextArea } = Input;

const DriverCard = ({ driver }) => {
   if (!driver.id) {
      return <></>;
   }

   return (
      <Card size="small" title={`Карточка ${driver.firstName} ${driver.lastName}`}>
         <div>Телефон: {driver.mobileNumber}</div>
         {/* <div>
            Статус:
            <Tag
               style={{ marginLeft: '5px' }}
               color={statusesOfDrivers[driver.status]?.color}>
               {statusesOfDrivers[driver.status]?.label}
            </Tag>
         </div> */}
         <div>Номер авто: {driver.carNumber}</div>
         <div>СТС авто: {driver.carSTS}</div>
         <div>Грузоподъемность: {driver.carCapacity}</div>
      </Card>
   );
};

const RouteForm = (props) => {
   const [isLoading, setIsLoading] = useState(false);
   const [capacityOfLeads, setCapacityOfLeads] = useState(0);
   const [choosedStatus, setChoosedStatus] = useState(null);
   const [selectedDriver, setSelectedDriver] = useState({});
   const { initialValues, onSuccess, isEditForm, errorMessage } = props;
   const [form] = Form.useForm();

   useEffect(() => {
      if (!initialValues?.status) return;
      setChoosedStatus(initialValues?.status);
   }, [initialValues]);

   const onFinish = (values) => {
      if (values.status === 'inwork' && !values.driverId) {
         message.error(
            'Выберите водителя, если хотите перевести маршрут в статус в работе'
         );
         return;
      }

      // if (values.status === "active" && values.driverId) {
      //    values.driverId = null;
      // }

      values.driverId = values.driverId === undefined ? null : values.driverId;
      onSuccess(values, setIsLoading).then(() => {
         if (isEditForm) {
            return;
         }

         // form.resetFields();
      });
   };

   const onFinishFailed = (errorInfo) => {
      setIsLoading(false);
      console.log('Failed:', errorInfo);
   };

   const currentStatus = choosedStatus || 'active';
   const initialStatus = initialValues.status || 'active';

   const isDisabledInput = (inputName) => {
      const list = {
         driverId: ['active', 'inwork', 'blocked', 'deleted'],
         leadIds: ['active', 'inwork', 'finished'],
         commission: ['active', 'inwork', 'finished'],
         status: ['active', 'inwork', 'finished', 'blocked', 'deleted'],
         statusComment: ['active', 'inwork', 'blocked', 'finished', 'deleted']
      };

      return !list[inputName].includes(initialStatus);
   };

   const statusesCanBeDoOnCurrent = {
      active: ['active', 'inwork', 'blocked', 'deleted'],
      inwork: ['inwork', 'active', 'finished'],
      finished: ['finished', 'inwork', 'blocked', 'deleted'],
      blocked: ['blocked', 'active', 'deleted'],
      deleted: ['blocked', 'deleted', 'active']
   };

   const isRouteBlocked = currentStatus === 'blocked';
   const isRouteInWork = currentStatus === 'inwork';

   const actualStatuses = initialValues?.status
      ? statusesCanBeDoOnCurrent[initialValues?.status].map(
           (e) => statusesOfRoutes[e]
        )
      : [statusesOfRoutes.active, statusesOfRoutes.inwork];

   const isChoosedInWork = choosedStatus === 'inwork';
   const isChoosedActive = choosedStatus === 'active';

   const showErrorMessageWhenStatusChangeToActiveWithDriver =
      initialStatus !== 'active' &&
      (initialStatus?.driverId || form.getFieldValue('driverId'));

   initialValues.leadIds = initialValues.leads?.map((e) => e?.id);
   initialValues.statusComment = initialValues?.statusObj?.comment || '';

   const suffixSelector = <Form.Item noStyle>руб/кг</Form.Item>;

   return (
      <Form
         style={{
            maxWidth: '100%',
            minWidth: 320
         }}
         form={form}
         initialValues={initialValues}
         onFinish={onFinish}
         onFinishFailed={onFinishFailed}
         hideRequiredMark
         layout="vertical">
         <Row gutter={24}>
            <Col span={12}>
               <Form.Item
                  name="leadIds"
                  label={
                     capacityOfLeads > 0
                        ? `Заявки | Вес ${capacityOfLeads} кг`
                        : 'Заявки'
                  }
                  // rules={
                  //    !isDisabledInput('leadIds') && [
                  //       {
                  //          required: isChoosedInWork || isChoosedActive,
                  //          message: 'Выберите заявки'
                  //       }
                  //    ]
                  // }
               >
                  <SelectLeads
                     disabled={isDisabledInput('leadIds')}
                     initialLeads={initialValues?.leads || null}
                     leadIds={
                        form.getFieldValue('leadIds') || initialValues?.leadIds
                     }
                     setField={(value) => {
                        form.setFieldValue('leadIds', value);
                     }}
                     setCapacityOfLeads={setCapacityOfLeads}
                  />
               </Form.Item>
               <Form.Item
                  name="commission"
                  defaultValue={0}
                  // rules={
                  //    !isDisabledInput("commission") && [
                  //       {
                  //          required: true,
                  //          message: "Пропустили поле",
                  //       },
                  //       // {
                  //       //    validator: (rule, value, callback) => {
                  //       //       try {
                  //       //          if (value < 100) {
                  //       //             throw new Error();
                  //       //          }
                  //       //       } catch (err) {
                  //       //          callback(err);
                  //       //          return;
                  //       //       }
                  //       //       callback();
                  //       //    },
                  //       //    message: "Минимальная комиссия 100 рублей",
                  //       // },
                  //    ]
                  // }
                  label="Комиссия">
                  <InputNumber
                     type="number"
                     disabled={isDisabledInput('commission')}
                     style={{ width: '100%' }}
                     placeholder="Введите комиссию"
                  />
               </Form.Item>
            </Col>

            <Col span={12}>
               <Form.Item
                  name="status"
                  label="Статус"
                  rules={
                     !isDisabledInput('status')
                        ? [
                             {
                                required: true,
                                message: 'Выберите статус'
                             }
                          ]
                        : []
                  }
                  defaultValue={statusesOfRoutes.active}>
                  <Select
                     disabled={isDisabledInput('status')}
                     style={{
                        width: '100%'
                     }}
                     placeholder="Выберите статус"
                     rules={[
                        {
                           required: true,
                           message: 'Поле не может быть пустым '
                        }
                     ]}
                     onChange={(e) => {
                        setChoosedStatus(e);
                        form.setFieldValue('statusComment', '');
                     }}
                     options={actualStatuses}
                  />
               </Form.Item>
               <Form.Item name="statusComment" label="Комментарий к статусу">
                  <TextArea
                     disabled={isDisabledInput('statusComment')}
                     rows={2}
                     placeholder="Максимум 500 символов"
                     maxLength={500}
                  />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={16}>
            <Col span={12}>
               <Form.Item
                  name="driverId"
                  label="Водитель"
                  rules={
                     !isDisabledInput('driverId')
                        ? [
                             {
                                required: isChoosedInWork,
                                message: 'Выберите водителя'
                             },
                             {
                                validator: () => {
                                   // if (
                                   //    form.getFieldValue('status') === 'active' &&
                                   //    form.getFieldValue('driverId')
                                   // ) {
                                   //    return Promise.reject(
                                   //       'Нужно выбрать статус В работе'
                                   //    );
                                   // }

                                   if (
                                      form.getFieldValue('status') === 'finished' &&
                                      !form.getFieldValue('driverId')
                                   ) {
                                      return Promise.reject('Укажите водителя');
                                   }

                                   if (
                                      form.getFieldValue('status') === 'inwork' &&
                                      !form.getFieldValue('driverId')
                                   ) {
                                      return Promise.reject(
                                         'У вас не указан водитель, выберите водителя'
                                      );
                                   }

                                   return Promise.resolve();

                                   // if (form.getFieldValue("driverId")) {
                                   //    return Promise.resolve();
                                   // } else {
                                   //    return Promise.reject("Some message here");
                                   // }
                                }
                             }
                          ]
                        : []
                  }>
                  <SelectDriver
                     disabled={isDisabledInput('driverId')}
                     driver={initialValues?.driver || {}}
                     driverId={
                        form.getFieldValue('driverId') || initialValues?.driverId
                     }
                     setField={(value) => {
                        form.setFieldValue('driverId', value);
                     }}
                     setDriver={(driver) => setSelectedDriver(driver)}
                  />
               </Form.Item>
               <Form.Item>
                  <DriverCard driver={selectedDriver} />
               </Form.Item>
            </Col>
         </Row>

         <Row gutter={[16, 24]}>
            {isRouteBlocked && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе заблокирован нельзя редактировать все поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {showErrorMessageWhenStatusChangeToActiveWithDriver && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="Внимание! при выборе активного статуса, водитель убирается из маршрута"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {isRouteInWork && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message="В статусе В работе нельзя редактировать некоторые поля"
                     type="warning"
                     showIcon
                  />
               </Col>
            )}

            {errorMessage && (
               <Col
                  className="gutter-row"
                  span={24}
                  className="stats-card-count-col">
                  <Alert
                     message={errorMessage.replace('Error: ', '')}
                     type="error"
                     showIcon
                  />
               </Col>
            )}

            <Col className="gutter-row" span={24} className="stats-card-count-col">
               <Form.Item>
                  <Button type="primary" htmlType="submit" loading={isLoading}>
                     {isEditForm ? 'Сохранить' : 'Создать'}
                  </Button>
               </Form.Item>
            </Col>
         </Row>
      </Form>
   );
};

export default RouteForm;
