import React from 'react';
import TableDrivers from '@features/list-driver/ui/TableDrivers';
import { Title } from '@shared/ui';

const DriversPage = () => {
   return (
      <div>
         <Title>Список водителей</Title>
         <TableDrivers />
      </div>
   );
};

export default DriversPage;
