import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import CreateOrEditRating from './CreateOrEditRating';

const ModalRatingForm = ({ selectedRating, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!selectedRating?.id);
   }, [selectedRating]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!selectedRating?.id;

   return (
      <Modal
         title={`Редактировать рейтинг № ${selectedRating?.id}`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         footer={null}
         width={400}
         key={`modal${selectedRating?.id}`}
         destroyOnClose={true}
      >
         {isHaveData && (
            <CreateOrEditRating
               id={selectedRating?.id}
               callbackOnSuccess={handleCancel}
            />
         )}
      </Modal>
   );
};

export default ModalRatingForm;
