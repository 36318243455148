/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Alert, Button, Title, Select, VerticalSpace } from '@shared/ui';
import TableNumbers from '@features/list-mobile-numbers/ui/TableNumbers';
import { useNavigate } from 'react-router-dom';

const MobileAppsNumbersPage = () => {
   return (
      <div>
         <Title>Номера</Title>
         <Alert
            message="Номера, введеные в приложениях, попадают в эту таблицу, номера не повторяются, вполне возможно что некоторые уже зарегистрировались успешно, но и возможно нет."
            type="info"
            showIcon
         />{' '}
         <VerticalSpace />
         <TableNumbers />
      </div>
   );
};

export default MobileAppsNumbersPage;
