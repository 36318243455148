import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import { getRouteEarningById } from '@shared/api/all/route';

const RouteEarningById = ({ routeId, type = 1 }) => {
   const [data, setData] = useState(0);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (routeId) {
         fetchData(routeId);
      }
   }, [routeId]);

   const fetchData = async (id) => {
      let rr = [];
      setLoading(true);

      rr = await getRouteEarningById(id, type);

      setLoading(false);
      setData(rr?.json?.data || 0);
   };

   if (loading) {
      return <Spin size="small" />;
   }

   return <>{data ? `${data} руб` : `Неизвестно`} </>;
};

export default RouteEarningById;
