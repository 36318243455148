import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getAuctionById = (id) => get(`/auction/${id}`);

export const deleteAuctionById = (id) => _delete(`/auction/${id}`, {}, true);

export const getAuctionPricesById = (id, params) =>
   get(generateQueryParams(`/auction/${id}/prices`, params));

export const finishAuctionById = (fields, id) =>
   put(
      `/auction/${id}/finish`,
      {
         ...fields
      },
      true
   );

export const startAuctionById = (id) => get(`/auction/${id}/start`);
export const cancelAuctionById = (id) => get(`/auction/${id}/cancel`);
export const getAuctions = (params) => get(generateQueryParams(`/auctions`, params));

export const createAuction = (fields) =>
   post(
      '/auction',
      {
         ...fields
      },
      true
   );

export const updateAuction = (fields, id) =>
   put(
      `/auction/${id}`,
      {
         ...fields
      },
      true
   );
