import React, { useState, useRef, useEffect } from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { CSVLink } from 'react-csv';
import { Button, message } from 'antd';
import {
   statusesOfLeads,
   statusesOfSellers,
   statusesOfDrivers,
   statusesOfRoutes
} from '@shared/const/statuses';
import { timestampToNormalDate } from '@shared/utils/tsToTime';
import { getCountOfService } from '@shared/api/all/stats';
import { getRoutes } from '@shared/api/all/route';
import { getLeads } from '@shared/api/all/lead';
import { getUserById } from '@shared/api/all/user';
import { unitSettings } from '@shared/const/units';

const typesOfStatuses = {
   lead: statusesOfLeads,
   seller: statusesOfSellers,
   route: statusesOfRoutes,
   driver: statusesOfDrivers
};

function removeNewLines(text) {
   if (!text) return '';

   return text?.replace(/\n/g, ' ') || '';
}

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

const fieldsOfEntities = {
   driver: [
      { key: 'id', label: 'Водитель №', render: (value) => value },
      { key: 'firstName', label: 'Имя', render: (value) => value },
      { key: 'lastName', label: 'Фамилия', render: (value) => value },
      { key: 'mobileNumber', label: 'Телефон', render: (value) => value },
      {
         key: 'status',
         label: 'Статус',
         render: (value, record, type) =>
            typesOfStatuses?.[type]?.[value]?.label || 'Неизвестно'
      },
      {
         key: 'createdAt',
         label: 'Дата регистрации',
         render: (value) => timestampToNormalDate(value)
      },
      {
         key: 'lastSeenAt',
         label: 'Был в приложении',
         render: (value) => (value ? timestampToNormalDate(value) : 'Не указано')
      },
      {
         key: 'carNumber',
         label: 'Номер авто',
         render: (value) => value
      },
      {
         key: 'routeCount',
         label: 'Вывезено маршрутов',
         render: (value) => value || 0
      },
      {
         key: 'notes',
         label: 'Комментарий водителю',
         render: (value) => removeNewLines(value)
      }
   ],
   seller: [
      { key: 'id', label: 'Продавцец №', render: (value) => value },
      { key: 'firstName', label: 'Имя', render: (value) => value },
      { key: 'lastName', label: 'Фамилия', render: (value) => value },
      {
         key: 'userInfo',
         label: 'Менеджер',
         render: (value) => value
      },
      {
         key: 'status',
         label: 'Статус',
         render: (value, record, type) =>
            typesOfStatuses?.[type]?.[value]?.label || 'Неизвестно'
      },
      {
         key: 'organization',
         label: 'Компания',
         render: (value) => (value ? `${value}` : `Физ. лицо`)
      },
      { key: 'mobileNumber', label: 'Телефон', render: (value) => value },
      {
         key: 'createdAt',
         label: 'Дата создания',
         render: (value) => timestampToNormalDate(value)
      },
      {
         key: 'lastSeenAt',
         label: 'Был в приложении',
         render: (value) => (value ? timestampToNormalDate(value) : 'Не указано')
      },
      {
         key: 'leadCount',
         label: 'Количество заявок',
         render: (value) => value || 0
      },
      {
         key: 'addresses',
         label: 'Адреса',
         render: (value) => value.map((item) => item.address).join(', ')
      },
      {
         key: 'notes',
         label: 'Комментарий продавцу',
         render: (value) => removeNewLines(value)
      }
   ],
   lead: [
      { key: 'id', label: 'Заявка №', render: (value) => value },
      {
         key: 'userInfo',
         label: 'Менеджер',
         render: (value) => value
      },
      {
         key: 'status',
         label: 'Статус',
         render: (value, record, type) =>
            typesOfStatuses?.[type]?.[value]?.label || 'Неизвестно'
      },
      {
         key: 'statusId',
         label: 'Комментарий к статусу',
         render: (value, record) => removeNewLines(record?.statusObj?.comment)
      },
      {
         key: 'comment',
         label: 'Комментарий к заявке',
         render: (value) => removeNewLines(value)
      },
      {
         key: 'routeId',
         label: 'Маршрут №',
         render: (value) => value || 'Нет маршрута'
      },
      {
         key: 'materialCategory',
         label: 'Виды сырья',
         render: (value, record) => record.materialCategory.name
      },
      {
         key: 'capacity',
         label: 'Вес',
         render: (value) => (value ? value : `Не указан вес`)
      },
      {
         key: 'capacityType',
         label: 'Тип веса',
         render: (value) => suffix(value)
      },

      {
         key: 'driverId',
         label: 'ID водителя',
         render: (value) => value
      },
      {
         key: 'driverMobile',
         label: 'Номер телефона водитель',
         render: (value) => value
      },

      {
         key: 'driverName',
         label: 'Водитель',
         render: (value) => value
      },

      {
         key: 'price',
         label: 'Стоимость',
         render: (_) => _
      },
      {
         key: 'priceType',
         label: 'Тип стоимости',
         render: (_) => (_ ? `${_ === 'unit' ? 'руб/кг' : 'руб'}` : 'Бесплатно')
      },
      {
         key: 'createdAt',
         label: 'Дата создания',
         render: (value) => timestampToNormalDate(value)
      },
      // {
      //    key: 'createdAt',
      //    label: 'Дата вывоза',
      //    render: (value) => 'в разработке'
      // },
      // {
      //    key: 'createdAt',
      //    label: 'Дата взятия в работу',
      //    render: (value) => 'в разработке'
      // },
      // {
      //    key: 'createdAt',
      //    label: 'Дата завершения',
      //    render: (value) => 'в разработке'
      // },
      {
         key: 'address',
         label: 'Продавец',
         render: (value, record) =>
            `ID: ${record?.address?.seller?.id} ${record?.address?.seller?.firstName} ${record?.address?.seller?.lastName}`
      },
      {
         key: 'finishDate',
         label: 'Телефон продавца',
         render: (value, record) => `${record?.address?.seller?.mobileNumber}`
      },
      {
         key: 'distance',
         label: 'Место вывоза',
         render: (value, record) => `${record?.address?.typeOfAddress || ''}`
      },
      {
         key: 'isSendedSmsRating',
         label: 'Адрес',
         render: (value, record) => `${record?.address?.address}`
      },
      {
         key: 'isThereLift',
         label: 'Район',
         render: (value, record) =>
            `${record?.address?.districtName || 'Не указано'}`
      },
      {
         key: 'isLoadingRequired',
         label: 'Широта',
         render: (value, record) => `${record?.address?.geoLat}`
      },
      {
         key: 'isPassRequired',
         label: 'Долгота',
         render: (value, record) => `${record?.address?.geoLon}`
      }
   ],
   route: [
      { key: 'id', label: 'Маршрут №', render: (value) => value },
      {
         key: 'status',
         label: 'Статус',
         render: (value, record, type) =>
            typesOfStatuses?.[type]?.[value]?.label || 'Неизвестно'
      },
      {
         key: 'statusId',
         label: 'Комментарий к статусу',
         render: (value, record) => removeNewLines(record?.statusObj?.comment)
      },
      {
         key: 'driver',
         label: 'Водитель',
         render: (value, record) =>
            record?.driver
               ? `ID: ${record?.driver?.id} ${record?.driver?.firstName} ${record?.driver?.lastName}`
               : 'Не назначен'
      },
      {
         key: 'createdAt',
         label: 'Дата создания',
         render: (value) => timestampToNormalDate(value)
      },
      // {
      //    key: 'createdAt',
      //    label: 'Дата вывоза',
      //    render: (value) => 'в разработке'
      // },
      // {
      //    key: 'createdAt',
      //    label: 'Дата взятия в работу',
      //    render: (value) => 'в разработке'
      // },
      // {
      //    key: 'createdAt',
      //    label: 'Дата завершения',
      //    render: (value) => 'в разработке'
      // },
      {
         key: 'leads',
         label: 'Заявки',
         render: (value, record) => record.leads.map((item) => item.id).join(', ')
      }
   ]
};

const ExportToCSVModalButton = ({ type, fetchData, dataParams }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState(null);
   const csvInstance = useRef();

   const getData = () => {
      setIsLoading(true);

      fetchData({
         page: 1,
         limit: 100000,
         ...dataParams.sorter,
         ...dataParams.filters
      })
         .then(async (res) => {
            const newData = [];
            const listOfData = res.data;

            if (type === 'driver') {
               const promises = [];
               listOfData.map((item) =>
                  promises.push(
                     getRoutes({
                        pageSize: 1,
                        page: 1,
                        driverId: item.id,
                        status: 'finished'
                     }).then((res) => ({
                        driverId: item.id,
                        routeCount: res.json.count
                     }))
                  )
               );

               const resultOfCountOfDrivers = await Promise.all(promises).then(
                  (res) => res
               );

               resultOfCountOfDrivers.map(
                  (e) =>
                     (listOfData.find((item) => item.id === e.driverId).routeCount =
                        e.routeCount)
               );
            }

            if (type === 'seller' || type === 'lead') {
               const promises = [];
               let userIds = new Set([...listOfData.map((item) => item.userId)]);
               userIds = [...userIds].filter((e) => !!e);

               userIds.map(
                  (e) =>
                     e &&
                     promises.push(
                        getUserById(e).then((res) => ({
                           userId: e,
                           userInfo: `ID: ${res?.json?.data?.id} ${res?.json?.data?.firstName} ${res?.json?.data?.lastName}`
                        }))
                     )
               );

               const resultOfPromises = await Promise.all(promises).then(
                  (res) => res
               );

               listOfData.forEach((element) => {
                  const current = resultOfPromises.find(
                     (e) => e.userId === element.userId
                  );

                  if (current) {
                     element.userInfo = current.userInfo;
                  }
               });
            }

            if (type === 'lead') {
               const promises = [];
               listOfData.map((item) => {
                  item.capacityType = item.materialCategory.unit;

                  if (item.routeId) {
                     promises.push(
                        getRoutes({
                           pageSize: 1,
                           page: 1,
                           id: item.routeId
                        }).then((res) => {
                           const driver = res?.json?.data?.[0]?.driver;

                           return {
                              leadId: item.id,
                              name: driver
                                 ? `${driver.firstName} ${driver.lastName}`
                                 : ``,
                              id: driver ? `${driver.id}` : ``,
                              mobile: driver ? `${driver.mobileNumber}` : ``
                           };
                        })
                     );
                  }
               });

               const resultOfCountOfDrivers = await Promise.all(promises).then(
                  (res) => res
               );

               resultOfCountOfDrivers.map((e) => {
                  listOfData.find((item) => item.id === e.leadId).driverName =
                     e.name;
                  listOfData.find((item) => item.id === e.leadId).driverId = e.id;
                  listOfData.find((item) => item.id === e.leadId).driverMobile =
                     e.mobile;
               });
            }

            if (type === 'seller') {
               const promises = [];
               listOfData.map((item) =>
                  promises.push(
                     getLeads({
                        pageSize: 1,
                        page: 1,
                        sellerId: item.id
                     }).then((res) => ({
                        sellerId: item.id,
                        leadCount: res.json.count
                     }))
                  )
               );

               const resultOfCountOfSellers = await Promise.all(promises).then(
                  (res) => res
               );

               resultOfCountOfSellers.map(
                  (e) =>
                     (listOfData.find((item) => item.id === e.sellerId).leadCount =
                        e.leadCount)
               );
            }

            listOfData.map((item) => {
               const itemKeys = Object.keys(item);

               let excelRow = {};

               itemKeys.map((itemKey) => {
                  const res = fieldsOfEntities[type].find(
                     (item) => item.key === itemKey
                  );

                  if (res) {
                     excelRow[itemKey] = res.render(item[itemKey], item, type);
                  }
               });

               newData.push(excelRow);
            });

            setData(newData);

            setTimeout(() => {
               csvInstance.current.link.click();
               message.success('Данные успешно экспортированы');
               setIsLoading(false);
            }, 500);
         })
         .catch(() => {
            setIsLoading(false);
         });
   };

   return (
      <>
         <Button
            icon={<DownloadOutlined />}
            onClick={getData}
            loading={isLoading}
            disabled={isLoading}>
            Экспортировать в csv
         </Button>

         {data ? (
            <CSVLink
               filename={'file.csv'}
               data={data}
               ref={csvInstance}
               headers={fieldsOfEntities[type]}
               separator={';'}
            />
         ) : null}
      </>
   );
};

export default ExportToCSVModalButton;
