import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Rate } from 'antd';
import { Button, VerticalSpace } from '@shared/ui';
import { GetRatingList } from '../model/GetRatingList';
import { EditOutlined } from '@ant-design/icons';
import ModalRatingForm from './ModalRatingForm';
import { Divider, Tooltip } from 'antd';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import { deleteRatingById } from '@shared/api/all/rating';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import ModalButtonRatingCreate from './ModalButtonRatingCreate';
import CanDo from '@shared/lib/CanDo';
import { statusesOfRatings } from '@shared/const/statuses';
import {
   getColumnSearchProps,
   getStatusColumnFilterProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';

const wordsEntities = {
   user: 'Пользователь',
   driver: 'Водитель',
   seller: 'Продавец'
};

const entityKeys = {
   driver: {
      to: 'toEntityDriver',
      from: 'fromEntityDriver'
   },
   seller: {
      to: 'toEntitySeller',
      from: 'fromEntitySeller'
   },
   user: {
      to: 'toEntityUser',
      from: 'fromEntityUser'
   }
};

const wordsActions = {
   lead: 'Заявка',
   route: 'Маршрут'
};

const TableRatings = ({ type }) => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedRating, setSelectedRating] = useState(null);

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: {}
   });

   useEffect(() => {
      fetchData();
   }, [type]);

   const { pagination, filters } = paramsTable;

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         width: '140px',
         filtered: !!filters?.['id'],
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      },
      {
         title: 'Оценка',
         dataIndex: 'valueInFloat',
         key: 'valueInFloat',
         width: '280px',
         render: (_) => <Rate value={_} disabled allowHalf />
      },
      // {
      //    title: "Тип оценки",
      //    dataIndex: "categoryOfRating",
      //    key: "categoryOfRating",
      //    render: (_) => "Общая оценка",
      // },
      {
         title: 'От кого',
         dataIndex: 'fromEntityType',
         key: 'fromEntityType',
         render: (_, record) => (
            <>
               {`${wordsEntities[_]}  ${record[entityKeys[_].from]?.firstName} ${
                  record[entityKeys[_].from]?.lastName
               }  (ID: ${record[entityKeys[_].from]?.id})`}
               {/* ${record.fromEntityId} */}
            </>
         )
      },
      {
         title: 'Кому',
         dataIndex: 'toEntityType',
         key: 'toEntityType',
         render: (_, record) => (
            <>
               {`${wordsEntities[_]}  ${record[entityKeys[_].to]?.firstName} ${
                  record[entityKeys[_].to]?.lastName
               } (ID: ${record[entityKeys[_].to]?.id})`}

               {/* ${record.toEntityId}  */}
            </>
         )
      },
      {
         title: 'Повод',
         dataIndex: 'actionForEntityType',
         key: 'actionForEntityType',
         render: (_, record) => <>{`${wordsActions[_]}  №${record[_].id}`}</>
      }
   ];

   const actions = [
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         filtered: !!filters?.['status'],
         ...getStatusColumnFilterProps({
            options: Object.values(statusesOfRatings),
            dataIndex: 'status',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         }),
         render: (_) => (
            <Tag color={statusesOfRatings[_]?.color}>
               {statusesOfRatings[_]?.label}
            </Tag>
         )
      },
      {
         title: 'Действие',
         key: 'action',
         render: (_, record) => (
            <Space size="small">
               <CanDo permission="can_edit_ratings">
                  <Tooltip placement="top" title={'Редактировать'}>
                     <Button
                        onClick={() => setSelectedRating(record)}
                        type="primary">
                        <EditOutlined />
                     </Button>
                  </Tooltip>
               </CanDo>

               {record.status === 'archive' && (
                  <DeleteEntityButton
                     id={record.id}
                     callbackFetch={deleteRatingById}
                     update={fetchData}
                     permission="can_delete_ratings"
                  />
               )}
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedRating(null);
      fetchData();
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);
      GetRatingList({
         page: params.pagination.current,
         limit: params.pagination.pageSize,
         type,
         ...params.sorter,
         ...params.filters
      }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, total: res.count }
         });

         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   return (
      <>
         {/* <CreateOrEditRating id={null} callbackOnSuccess={() => {}} /> */}
         <ModalRatingForm selectedRating={selectedRating} closeModal={closeModal} />
         <CanDo permission="can_create_ratings">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonRatingCreate closeModal={closeModal} />
            </Space>
         </CanDo>
         <VerticalSpace />
         <Table
            rowKey="id"
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            loading={isLoading}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableRatings;
