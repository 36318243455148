import { updateDriver } from '@shared/api/all/driver';

export const UpdateDriver = async (fields, driverId) => {
   try {
      // TODO по хорошему диспатч должен быть здесь
      const response = await updateDriver(fields, driverId);
      return response;
   } catch (e) {
      throw new Error(e);
   }
};
