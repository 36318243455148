import { Select } from 'antd';
import { useState, useEffect } from 'react';
import { GetAddressesOfSeller } from './model/GetAddressesOfSeller';

const SelectAddressOfSeller = (props) => {
   const [addresses, setAddresses] = useState([]);
   const { sellerId, currentAddress } = props;

   useEffect(() => {
      fetchAddresses(sellerId);
   }, [sellerId]);

   const fetchAddresses = (sId) => {
      if (!sellerId) {
         return;
      }

      setAddresses([]);

      GetAddressesOfSeller(sId).then((res) => {
         if (currentAddress && currentAddress?.status !== 'active') {
            res.data = [...res.data, currentAddress];
         }

         const modifiedData = res.data.map((item) => ({
            ...item,
            label: `${item.address} ${
               item.status === 'archive' ? '- АДРЕС УДАЛЕН' : ''
            }`,
            value: item.id
         }));
         setAddresses(modifiedData);
      });
   };

   return (
      <Select
         {...props}
         placeholder="Выберите адрес"
         onFocus={() => fetchAddresses(sellerId)}
         filterOption={false}
         options={addresses}
      />
   );
};
export default SelectAddressOfSeller;
