import { Space, Tag } from 'antd';
import dayjs from 'dayjs';

const NewEntityTag = ({ date }) => {
   if (!date) {
      return null;
   }

   const halfDayInMs = 43200000;
   const isShow = dayjs() - dayjs(date) > halfDayInMs;

   if (isShow) {
      return null;
   }

   return (
      <Space direction="vertical">
         <Tag className="status-tag" color="rgb(32 178 63)">
            Новый
         </Tag>
      </Space>
   );
};

export default NewEntityTag;
