export const getApiQueryParamsForTables = (params, paramsTable, initialSorter) => {
   if (
      JSON.stringify(params.filters).length !==
      JSON.stringify(paramsTable.filters).length
   ) {
      params.pagination.current = 1;
   }

   const current = params.pagination.current || paramsTable.pagination.current;
   const pageSize = params.pagination.pageSize || paramsTable.pagination.pageSize;
   const sorter = params.sorter?.sort ? params?.sorter : initialSorter;

   return {
      page: current,
      limit: pageSize,
      ...sorter,
      ...params.filters
   };
};
