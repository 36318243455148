import { Drawer, Button } from 'antd';
import { useState, useEffect } from 'react';
import CreateOrEditDriver from './';

const ModalDriverForm = ({ selectedDriver, closeModal }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   useEffect(() => {
      setIsModalOpen(!!selectedDriver?.id);
   }, [selectedDriver]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const isHaveData = !!selectedDriver?.id;

   return (
      <Drawer
         title={`Редактировать ${selectedDriver?.firstName} ${selectedDriver?.lastName}`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         onClose={handleCancel}
         footer={null}
         width={900}
         destroyOnClose={true}
         extra={<Button onClick={handleCancel}>Закрыть</Button>}>
         {isHaveData && (
            <CreateOrEditDriver
               id={selectedDriver.id}
               callbackOnSuccess={closeModal}
            />
         )}
      </Drawer>
   );
};

export default ModalDriverForm;
