import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Tooltip } from 'antd';
import { GetAuctionList } from '../model/GetAuctionList';
import { Button, VerticalSpace } from '@shared/ui';
import { EditOutlined } from '@ant-design/icons';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import ModalButtonAuctionCreate from './ModalButtonAuctionCreate';
import { statusesOfAuctions } from '@shared/const/statuses';
import ModalAuctionForm from './ModalAuctionForm';
import StartAuctionButton from './StartAuctionButton';
import AuctionPeersButton from './AuctionPeersButton';
import AuctionResultsButton from './AuctionResultsButton';
import { deleteAuctionById } from '@shared/api/all/auction';
import DeleteEntityButton from '@widgets/Button/DeleteEntityButton';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';

import dayjs from 'dayjs';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange,
   getStatusColumnFilterProps
} from '@shared/ui/Table';

const TableAuctions = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedAuction, setSelectedAuction] = useState(null);

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: {
         sort: 'id',
         order: 'desc'
      }
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      fetchData();
   }, []);

   const columns = [
      {
         title: '№',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         filtered: !!filters?.['id'],
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      },
      {
         title: 'Минимальная стоимость выкупа',
         dataIndex: 'startPrice',
         key: 'startPrice',
         render: (_) => <Space size="small">{`${_} руб`}</Space>
      },
      {
         title: 'Начало',
         dataIndex: 'startTime',
         key: 'startTime',
         render: (_) => {
            if (!_) {
               return <Space size="small">Не определен</Space>;
            }

            const dayjsLocal = dayjs(_);
            const istString = dayjsLocal.format('YYYY-MM-DD HH:mm');

            return <Space size="small">{istString}</Space>;
         }
      },
      {
         title: 'Конец',
         dataIndex: 'endTime',
         key: 'endTime',
         render: (_) => {
            if (!_) {
               return <Space size="small">Не определен</Space>;
            }

            const dayjsLocal = dayjs(_);
            const istString = dayjsLocal.format('YYYY-MM-DD HH:mm');

            return <Space size="small">{istString}</Space>;
         }
      }
      // {
      //    title: "Комиссия",
      //    dataIndex: "commission",
      //    key: "commission",
      //    render: (_) => <Space size="small">{`${_} руб`}</Space>,
      // },
   ];

   const actions = [
      {
         title: 'Заявка',
         dataIndex: 'leadId',
         key: 'leadId',
         render: (_) => (
            <Space size="small" wrap>
               <Tag key={_}> {`Заявка №${_}`}</Tag>
            </Space>
         )
      },
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         render: (_) => (
            <Tag color={statusesOfAuctions[_]?.color}>
               {statusesOfAuctions[_]?.label}
            </Tag>
         ),
         filtered: !!filters?.['status'],
         ...getStatusColumnFilterProps({
            options: Object.values(statusesOfAuctions),
            dataIndex: 'status',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Действие',
         key: 'action',
         width: '160px',
         render: (_, record) => (
            <Space size="middle">
               {/* <EnterToEntityButton id={record.id} entity="auctions" /> */}

               <Tooltip placement="top" title={'Редактировать'}>
                  <Button onClick={() => setSelectedAuction(record)} type="primary">
                     <EditOutlined />
                  </Button>
               </Tooltip>
               {record.status === 'active' && (
                  <Tooltip placement="top" title={'Запустить торги'}>
                     <StartAuctionButton
                        id={record.id}
                        callbackOnSuccess={closeModal}
                     />
                  </Tooltip>
               )}
               {record.status === 'pending' && (
                  <Tooltip placement="top" title={'Выбрать победителя'}>
                     <AuctionPeersButton
                        id={record.id}
                        callbackOnSuccess={closeModal}
                     />
                  </Tooltip>
               )}
               {record.status === 'finished' && (
                  <Tooltip placement="top" title={'Результаты торга'}>
                     <AuctionResultsButton
                        id={record.id}
                        callbackOnSuccess={closeModal}
                     />
                  </Tooltip>
               )}
               {record.status === 'archive' && (
                  <DeleteEntityButton
                     id={record.id}
                     callbackFetch={deleteAuctionById}
                     update={fetchData}
                     permission="can_delete_auctions"
                  />
               )}
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedAuction(null);
      fetchData();
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);
      GetAuctionList({
         page: params.pagination.current,
         limit: params.pagination.pageSize,
         ...params.sorter,
         ...params.filters
      }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, total: res.count }
         });

         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   return (
      <>
         <ModalAuctionForm
            selectedAuction={selectedAuction}
            closeModal={closeModal}
         />
         <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <ModalButtonAuctionCreate closeModal={closeModal} />
         </Space>{' '}
         <VerticalSpace />
         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
               </Space>
            </div>
         </div>
         <Table
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            rowKey="id"
            onChange={handleTableChange}
            loading={isLoading}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableAuctions;
