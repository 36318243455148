import { Button, Modal, Tooltip, Upload } from 'antd';
import { useState, useEffect } from 'react';
import { FileImageOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateCategoryImage } from '../model/services/CreateCategoryImage';
import imageCompression from 'browser-image-compression';

async function compressImage(img) {
   const imageFile = img;

   const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 120,
      useWebWorker: true
   };

   const compressedFile = await imageCompression(imageFile, options);
   return compressedFile;
}

const ModalIcon = ({ icon, categoryId }) => {
   const [isModalOpen, setIsModalOpen] = useState(false);

   const [fileList, setFileList] = useState([]);

   useEffect(() => {
      setFileList([{ uid: icon, url: icon, name: 'Картинка', status: 'done' }]);
   }, [icon]);

   const fetchImage = async (fi) => {
      const uid = fi.uid;
      const file = await compressImage(fi);
      const formData = new FormData();

      formData.append('image', file, uid);

      setFileList([
         {
            uid: uid,
            name: file.name,
            status: 'uploading'
         }
      ]);

      await CreateCategoryImage(categoryId, formData)
         .then((res) => {
            console.log(res);
            setFileList([{ url: res.url, status: 'done', id: uid, uid: uid }]);
         })
         .catch(() => {
            setFileList([{ status: 'done', uid: uid }]);
         });
   };

   const onRemove = (file) => {
      if (!file?.id) {
         return;
      }

      setFileList((prev) =>
         prev.filter((item) => item.name !== file.name || item.id !== file.id)
      );
   };

   const showModal = () => {
      setIsModalOpen(true);
   };

   const handleOk = () => {
      setIsModalOpen(false);
   };

   const handleCancel = () => {
      setIsModalOpen(false);
   };

   const uploadButton = (
      <div>
         <PlusOutlined />
         <div
            style={{
               marginTop: 8
            }}>
            Загрузить
         </div>
      </div>
   );

   console.log(fileList);

   const isUploadingStatus =
      fileList.length > 0 && fileList[0]?.status === 'uploading';
   return (
      <>
         <Tooltip
            placement="top"
            title={icon ? 'Изменить иконку' : 'Добавить иконку'}>
            <Button onClick={showModal} style={{ marginLeft: '10px' }}>
               <FileImageOutlined />
            </Button>
         </Tooltip>

         <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            title={`Иконка`}
            footer={null}
            width={600}
            destroyOnClose={true}>
            {fileList[0]?.url && !isUploadingStatus ? (
               <img
                  className="image-category"
                  alt="example"
                  style={{
                     width: '120px',
                     height: '120px',
                     marginBottom: '10px'
                  }}
                  src={fileList[0]?.url}
               />
            ) : null}

            <Upload
               accept="image/png, image/jpeg, image/webp"
               listType="picture-card"
               fileList={isUploadingStatus ? fileList : []}
               // onPreview={handlePreview}
               onRemove={onRemove}
               action={fetchImage}>
               {!isUploadingStatus ? uploadButton : null}
            </Upload>
         </Modal>
      </>
   );
};

export default ModalIcon;
