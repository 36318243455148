import { get, post, put, generateQueryParams, _delete } from '../fetch.js';

export const getSellerById = (id) => get(`/seller/${id}`);

export const getSellerTypes = () => get(`/types/seller`);

export const deleteSellerById = (id) => _delete(`/seller/${id}`, {}, true);

export const getSellers = (params) => get(generateQueryParams(`/sellers`, params));

export const createSeller = (fields) =>
   post(
      '/seller',
      {
         ...fields
      },
      true
   );

export const updateSeller = (fields, id) =>
   put(
      `/seller/${id}`,
      {
         ...fields
      },
      true
   );
