const leadExtraFieldsToExport = {
   isLoadingRequired: {
      label: 'Нужна ли погрузка',
      value: 'isLoadingRequired',
      type: 'boolean'
   },
   isThereLift: {
      label: 'Есть ли подъемные механизмы? (лифт/тележка)',
      value: 'isThereLift',
      type: 'boolean'
   },
   distance: {
      label: 'Расстояние от места сырья до зоны погрузки в метрах',
      value: 'distance',
      type: 'integer'
   },
   isPassRequired: {
      label: 'Нужен ли пропуск на машину?',
      value: 'isPassRequired',
      type: 'boolean'
   },
   areScalesRequired: {
      label: 'Нужны ли весы?',
      value: 'areScalesRequired',
      type: 'boolean'
   },
   areThereRestrictionsSize: {
      label: 'Есть ли ограничения по габаритам машины? (арка/проезд/подземный гараж)',
      value: 'areThereRestrictionsSize',
      type: 'boolean'
   },
   isNeededPassport: {
      label: 'Нужен ли паспорт РФ для вывоза',
      value: 'isNeededPassport',
      type: 'boolean'
   }
};

export { leadExtraFieldsToExport };
