import { put, getAuthHeaders, requestResult, API_URL, _delete } from '../fetch.js';

export const createImage = async (fields) => {
   const url = '/image';

   return await fetch(`${API_URL}${url}`, {
      method: 'post',
      body: fields,
      headers: { ...(await getAuthHeaders()) }
   }).then((res) => {
      return res.json().then((json) => requestResult(res, json));
   });
};

export const createCategoryImage = async (id, fields) => {
   const url = `/category-image/${id}`;

   return await fetch(`${API_URL}${url}`, {
      method: 'post',
      body: fields,
      headers: { ...(await getAuthHeaders()) }
   }).then((res) => {
      return res.json().then((json) => requestResult(res, json));
   });
};

export const updateImage = (fields, id) =>
   put(
      `/image/${id}`,
      {
         ...fields
      },
      true
   );

export const deleteImageById = (id) => _delete(`/image/${id}`, {}, true);
