import React, { useState } from 'react';
import { Button } from '@shared/ui';
import { StartAuctionById } from './../model/StartAuction';
import { message, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
const { confirm } = Modal;

const StartAuctionButton = ({ id, callbackOnSuccess = () => {} }) => {
   const [isLoading, setLoading] = useState(false);

   const showConfirm = () => {
      return confirm({
         title: 'Подтвердите действие',
         icon: <ExclamationCircleFilled />,
         maskClosable: true,
         async onOk() {
            await startAuction();
         },
         okText: 'Запустить',
         cancelText: 'Отмена'
      });
   };

   const startAuction = async () => {
      setLoading(true);

      await StartAuctionById(id)
         .then(() => {
            message.success('Выставлено в торг, и отправлено приемкам');
            callbackOnSuccess();
            setLoading(false);
         })
         .catch(() => {
            message.error('Возникла ошибка');
            setLoading(false);
         });
   };

   return (
      <Button danger onClick={showConfirm} loading={isLoading}>
         Запустить торги
      </Button>
   );
};

export default StartAuctionButton;
