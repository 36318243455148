import { createSeller } from '@shared/api/all/seller';

export const CreateSeller = async (fields) => {
   try {
      const response = await createSeller(fields);
      if (!response.json) throw new Error();
      return response.json.data;
   } catch (e) {
      console.log(e);
      throw new Error(e);
   }
};
