import React from 'react';
import { Descriptions, Tag } from 'antd';

import { useNavigate } from 'react-router-dom';

import { statusesOfSellers } from '@shared/const/statuses';

const SellerSimpleDescriptions = ({ data }) => {
   const navigate = useNavigate();

   const sellerLittleDescription = [
      {
         label: 'ID',
         key: 'id',
         render: (_, record) => (
            <>
               {_}

               <Tag
                  color={statusesOfSellers[record.status]?.color}
                  style={{ marginLeft: '10px' }}>
                  {statusesOfSellers[record.status]?.label}
               </Tag>

               <span
                  className="green-span-url"
                  style={{}}
                  onClick={() => navigate(`/sellers/${_}`)}>
                  Перейти
               </span>
            </>
         )
      },
      {
         label: 'ФИО',
         key: 'firstName',
         render: (_, record) =>
            `${record.lastName} ${record.firstName} ${record?.surName || ''}`
      },
      {
         label: 'Номер телефона',
         key: 'mobileNumber',
         render: (_) => _
      },
      // {
      //    label: 'Почта',
      //    key: 'email',
      //    render: (_) => _ || 'Не задано'
      // },
      {
         label: 'Учредитель',
         key: 'organization',
         render: (_) => (_ ? `Юридическое лицо ${_}` : 'Физическое лицо')
      }
   ];

   return (
      <Descriptions size="small">
         {sellerLittleDescription.map((e) => (
            <Descriptions.Item
               key={`${data.id}-${e.label}`}
               label={e.label}
               span={3}>
               {e.render(data[e.key], data)}
            </Descriptions.Item>
         ))}
      </Descriptions>
   );
};

export default SellerSimpleDescriptions;
