import { generateQueryParams } from '@shared/api/fetch';
import _ from 'lodash';

import qs from 'qs';

export const changeTitle = (title) => {
   if (!title) {
      return;
   }
   document.title = title;
};

export const changeUrl = (prs) => {
   const ignoreFields = ['defaultCurrent', 'total'];
   let params = {};

   Object.values(prs).forEach((element) => {
      params = { ...params, ...element };
   });

   const result = _.omit(params, ignoreFields);

   let baseUrl = window.location.pathname;
   let query = generateQueryParams('', result);
   window.history.replaceState(null, null, baseUrl + query);
};

export const changeUrlWithoutReloading = (query, defaultRoute) => {
   let baseUrl = window.location.pathname;
   if (query === '') {
      window.history.replaceState(null, null, baseUrl + defaultRoute);
   }

   return window.history.replaceState(null, null, baseUrl + query);
};

export const generateSearchQueryParams = (obj) => {
   let query = '';
   let queryObj = { ...obj };

   if (!queryObj?.str) {
      return query;
   }

   query = '?str=' + queryObj?.str;
   delete queryObj.str;

   queryObj = { ...queryObj };
   let filterKeys = Object.keys(queryObj);

   if (filterKeys.length > 0) {
      for (let key in queryObj) {
         let el = queryObj[key];

         if (Array.isArray(el)) {
            if (el.length > 1) {
               let newfilterParam = el.map((item) => '&' + key + '=' + item + '');
               query = query + newfilterParam.join('');
            } else {
               query = query + '&' + key + '=' + el;
            }
         }
      }
   }

   return query;
};

export const generateQueryParamsForLists = (queryArr) => {
   let query = '';
   if (queryArr.length > 0) {
      let count = 0;
      for (let key in queryArr) {
         let el = queryArr[key];

         if (count === 0) {
            query = query + '?' + el.key + '=' + el.value;
         } else {
            query = query + '&' + el.key + '=' + el.value;
         }
         count++;
      }
   }

   return query;
};

export const isHaveUrlListSettings = () => location.search.length > 0;

export const parseUrlListSettings = () => {
   const parsedFilters = qs.parse(location.search, {
      ignoreQueryPrefix: true
   });

   const sorterFields = ['sort', 'order'];
   const paginationFields = ['current', 'pageSize'];

   let params = {
      pagination: {},
      filters: {},
      sorter: {}
   };

   Object.keys(parsedFilters).forEach((key) => {
      if (sorterFields.includes(key)) {
         params.sorter[key] = parsedFilters[key];
      } else if (paginationFields.includes(key)) {
         params.pagination[key] = parseInt(parsedFilters[key]);
      } else {
         params.filters[key] = parsedFilters[key];
      }
   });

   return params;
};

import { generateUUID } from '@shared/utils/default';

export const parseAndNormalizeFiltersFromUrlForListsPage = (
   parsedFilters,
   filterRulers
) => {
   let filterSettings = []; // ! Функционал сохранения фильтра в роуте, в будущем пригодиться, нужно доработать
   for (let key in parsedFilters) {
      let filterInfo = filterRulers.find((item) => item.key === key);
      if (filterInfo) {
         if (Array.isArray(parsedFilters[key])) {
            parsedFilters[key].map((item) =>
               filterSettings.push({
                  ...filterInfo,
                  autoVisibleInput: false,
                  value: item,
                  uuid: generateUUID()
               })
            );
         } else {
            filterSettings.push({
               ...filterInfo,
               autoVisibleInput: false,
               value: parsedFilters[key],
               uuid: generateUUID()
            });
         }
      }
   }

   return filterSettings;
};
