export const generateSorterParam = (fieldName, orderType) => ({
  columnKey: fieldName,
  order: orderType
});

export const normalizeFiltersToObjWithKeyAndValue = (f) => {
  let normalizedFilterObj = {};

  for (let obj of f) {
    if (obj?.value) {
      if (normalizedFilterObj[obj?.key]) {
        normalizedFilterObj[obj?.key] = [...normalizedFilterObj[obj?.key], obj.value];
      } else {
        normalizedFilterObj[obj?.key] = [obj.value];
      }
    }
  }

  return normalizedFilterObj;
};

export const generateUUIDbyTimeNow = () => Date.now();

export const generateUUID = () => (Math.random() + 1).toString(36).substring(5);

export const addElipsisToText = (text, maxLength) => {
  if (!text) {
    return '';
  }
  return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
};
