import { Modal } from 'antd';
import { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from '@shared/ui';
import { Col, Row, Divider, message, Alert, InputNumber } from 'antd';
import { UpdateCategory } from '../model/services/UpdateCategory';
import { v4 as uuidv4 } from 'uuid';

import { VerticalSpace } from '@shared/ui';
import { unitSettings } from '@shared/const/units';

const initialObj = () => ({
   id: uuidv4(),
   percentOfPriceWithoutLoading: 100,
   percentOfPriceWithLoading: 100
});

const suffix = (unit) => unitSettings.find((e) => e?.value === unit)?.shortLabel;

function validateIntervals(intervals) {
   // Создаем массив для хранения границ интервалов
   let boundaries = [];

   // Проверка на пересечение интервалов
   for (const interval of intervals) {
      // Добавляем начальную границу интервала
      boundaries.push({ value: interval.capacityFrom, type: 'from' });

      // Добавляем конечную границу интервала
      boundaries.push({ value: interval.capacityTo, type: 'to' });
   }

   // Сортируем границы интервалов
   boundaries.sort((a, b) => {
      if (a.value !== b.value) {
         return a.value - b.value; // Сортировка по значению
      } else {
         return a.type === 'from' ? -1 : 1; // Если значения равны, то сначала идет 'from', потом 'to'
      }
   });

   // Проверка на пересечение границ интервалов
   let openCount = 0;
   for (const boundary of boundaries) {
      if (boundary.type === 'from') {
         if (openCount > 0) {
            return false; // Интервалы пересекаются
         }
         openCount++;
      } else {
         openCount--;
      }
   }

   return true; // Интервалы не пересекаются
}

function validateCapacityOrder(list) {
   let isHave = false;

   list.forEach((item) => {
      if (item.capacityFrom > item.capacityTo) {
         isHave = true;
      }
   });

   return isHave; // Все интервалы валидны
}

const getPrice = (id, list, price, percentType) => {
   let item = list.find((e) => e.id === id);

   if (price === '0.0') {
      return '';
   }

   return (price * (item[percentType] / 100)).toFixed(1);
};

const ModalMaterialCategoryPriceRuleForm = (props) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isLoading, setIsLoading] = useState(false);
   const [errorMessage, setErrorMessage] = useState('');
   const [data, setData] = useState([]);
   const [price, setPrice] = useState('0.0');
   const { selectedCategory, closeModal } = props;

   useEffect(() => {
      setIsModalOpen(!!selectedCategory?.id);
      console.log(selectedCategory?.capacityPriceRules);
      setData(selectedCategory?.capacityPriceRules || []);
      if (selectedCategory?.capacityPrice) {
         console.log(selectedCategory?.capacityPrice);
         setPrice(selectedCategory?.capacityPrice);
      }
   }, [selectedCategory]);

   const handleOk = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const handleCancel = () => {
      setIsModalOpen(false);
      closeModal();
   };

   const save = () => {
      let errorMessage = '';

      data.forEach((item) => {
         if (!item.capacityFrom) {
            errorMessage = 'Укажите вес от';
         }

         if (!item.capacityTo) {
            errorMessage = 'Укажите вес от';
         }
      });

      if (!validateIntervals(data)) {
         return;
      }

      if (errorMessage) {
         setErrorMessage(errorMessage);
         return;
      }

      setIsLoading(true);
      UpdateCategory(
         { capacityPriceRules: data, capacityPrice: price },
         selectedCategory?.id
      )
         .then(() => {
            closeModal();
            message.success('Настройки обновлены');
         })
         .catch((e) => message.error(e?.message || 'Возникла ошибка при сохранении'))
         .finally(() => setIsLoading(false));
   };

   const addEmptyRow = () => {
      if (price === 0) {
         message.error('Заполните поле цены');
      }

      if (data.length > 0) {
         let isHaveEmpty = false;

         data.forEach((item) => {
            if (!item.capacityFrom || !item.capacityTo) {
               isHaveEmpty = true;
            }
         });

         if (isHaveEmpty) {
            message.error('Заполните пустые поля');
            return;
         }
      }

      setData([...data, initialObj()]);
   };

   const deleteRow = (id) => {
      setData(data.filter((e) => e.id !== id));
   };

   const onChange = (id, type, value) => {
      const newData = [...data];
      newData.find((e) => e.id === id)[type] = parseInt(value);

      let error = '';

      if (validateCapacityOrder(newData)) {
         error = 'Значение веса "от" должно быть меньше значения веса "до"';
      }

      if (!validateIntervals(newData)) {
         error = 'Интервалы не должны пересекаться';
      }

      setErrorMessage(error);

      setData(newData);
   };

   return (
      <Modal
         title={`Настройки цен для выкупа`}
         open={isModalOpen}
         onOk={handleOk}
         onCancel={handleCancel}
         footer={null}
         forceRender={true}
         destroyOnClose={true}
         width={1200}>
         <Row gutter={24}>
            <Col span={8}>
               <Form.Item
                  label={`Цена продажи на приемку за ${suffix(
                     selectedCategory?.unit
                  )}`}
                  rules={[
                     {
                        required: true,
                        message: 'Укажите цену'
                     }
                  ]}>
                  <InputNumber
                     style={{
                        width: '100%'
                     }}
                     addonAfter={<Form.Item noStyle>руб</Form.Item>}
                     defaultValue={price}
                     value={price}
                     onChange={(e) => setPrice(e)}
                     suffix="руб"
                     placeholder="Введите цену"
                     min="0"
                     step="0.1"
                     stringMode
                     precision={1}
                     decimalSeparator="."
                  />
               </Form.Item>{' '}
            </Col>
         </Row>

         {data.length > 0 ? (
            <Divider style={{ margin: '5px 5px 20px 5px' }} />
         ) : null}

         {data.map((item) => (
            <Row gutter={24} key={`ok-${item.id}`}>
               <Col span={3}>
                  <Form.Item
                     name="capacityFrom"
                     label="Вес от"
                     rules={[
                        {
                           required: true,
                           message: 'Укажите вес'
                        }
                     ]}>
                     <Input
                        style={{
                           width: '100%'
                        }}
                        defaultValue={item.capacityFrom}
                        onChange={(e) =>
                           onChange(item.id, 'capacityFrom', e.target.value)
                        }
                        suffix="кг"
                        type="number"
                     />
                  </Form.Item>
               </Col>

               <Col span={3}>
                  <Form.Item
                     name="capacityTo"
                     label="Вес до"
                     rules={[
                        {
                           required: true,
                           message: 'Укажите вес до'
                        }
                     ]}>
                     <Input
                        style={{
                           width: '100%'
                        }}
                        defaultValue={item.capacityTo}
                        onChange={(e) =>
                           onChange(item.id, 'capacityTo', e.target.value)
                        }
                        suffix="кг"
                        type="number"
                     />
                  </Form.Item>
               </Col>

               <Col span={4}>
                  <Form.Item
                     name="percentOfPriceWithoutLoading"
                     label={`Процент без погрузки`}
                     rules={[
                        {
                           required: true,
                           message: 'Укажите процент'
                        }
                     ]}>
                     <InputNumber
                        defaultValue={item?.percentOfPriceWithoutLoading || 100}
                        value={item?.percentOfPriceWithoutLoading || 100}
                        min={0}
                        style={{ width: '100%' }}
                        max={1000}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace('%', '')}
                        onChange={(e) =>
                           onChange(item.id, 'percentOfPriceWithoutLoading', e)
                        }
                     />
                  </Form.Item>
               </Col>

               <Col span={4}>
                  <Form.Item
                     name="percentOfPriceWithLoading"
                     label={`Процент с погрузкой`}
                     rules={[
                        {
                           required: true,
                           message: 'Укажите процент'
                        }
                     ]}>
                     <InputNumber
                        defaultValue={item?.percentOfPriceWithLoading || 100}
                        value={item?.percentOfPriceWithLoading || 100}
                        min={0}
                        style={{ width: '100%' }}
                        max={1000}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value?.replace('%', '')}
                        onChange={(e) =>
                           onChange(item.id, 'percentOfPriceWithLoading', e)
                        }
                     />
                  </Form.Item>
               </Col>

               <Col span={5}>
                  <Form.Item label="Расчет цены на этот вес">
                     <div>
                        Без погрузки{' '}
                        {getPrice(
                           item.id,
                           data,
                           price,
                           'percentOfPriceWithoutLoading'
                        )}{' '}
                        {`руб/${suffix(selectedCategory?.unit)}`}
                     </div>
                     <div>
                        C погрузкой{' '}
                        {getPrice(item.id, data, price, 'percentOfPriceWithLoading')}{' '}
                        {`руб/${suffix(selectedCategory?.unit)}`}
                     </div>
                  </Form.Item>
               </Col>

               <Col span={3}>
                  <Form.Item
                     label="Действие"
                     rules={[
                        {
                           required: true,
                           message: 'Укажите'
                        }
                     ]}>
                     <Button
                        type="primary"
                        danger
                        onClick={() => deleteRow(item.id)}>
                        Удалить
                     </Button>
                  </Form.Item>
               </Col>
            </Row>
         ))}

         {errorMessage && (
            <Col className="gutter-row" span={24} style={{ marginBottom: '15px' }}>
               <Alert
                  message={errorMessage.replace('Error: ', '')}
                  type="error"
                  showIcon
               />
            </Col>
         )}

         <Button type="primary" onClick={addEmptyRow} disabled={price === 0}>
            Добавить ограничение
         </Button>

         <VerticalSpace />

         <Form.Item>
            <Button type="primary" onClick={save} loading={isLoading}>
               Сохранить
            </Button>
         </Form.Item>
      </Modal>
   );
};

export default ModalMaterialCategoryPriceRuleForm;
