import React from 'react';
import TableFaqs from '@features/list-faq/ui/TableFaqs';
import { Title } from '@shared/ui';

const FaqsPage = ({ service }) => {
   return (
      <div>
         <Title>Список faq</Title>
         <TableFaqs service={service} />
      </div>
   );
};

export default FaqsPage;
