import React, { useState, useEffect } from 'react';
import { Space, Table, Segmented } from 'antd';
import { GetNumbersList } from '../model/GetNumbersList';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import { VerticalSpace } from '@shared/ui';
import { DateWithAgo } from '@shared/ui';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';

const services = [
   {
      label: 'Приложение водителя',
      value: 'driverApp'
   },
   {
      label: 'Приложение продавца',
      value: 'sellerApp'
   }
];

const entityType = {
   driver: 'Водитель',
   seller: 'Продавец'
};

const TableNumbers = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [service, setService] = useState(services[0].value);

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: {
         sort: 'id',
         order: 'desc'
      }
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      fetchData();
   }, []);

   useEffect(() => {
      fetchData();
   }, [service]);

   const columns = [
      {
         title: 'ID',
         dataIndex: 'id',
         key: 'id',
         sorter: true,
         filtered: !!filters?.['id'],
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         })
      }
   ];

   const actions = [
      {
         title: 'Приложение',
         dataIndex: 'service',
         key: 'service',
         render: (_) => services.find((e) => e.value === _)?.label
      },
      {
         title: 'Телефон',
         dataIndex: 'mobileNumber',
         key: 'mobileNumber'
      },
      {
         title: 'Сущность',
         dataIndex: 'entityType',
         key: 'entityType',
         render: (_) => entityType[_]
      },
      {
         title: 'Дата создания',
         dataIndex: 'createdAt',
         key: 'createdAt',
         align: 'center',
         sorter: true,
         render: (_) => <DateWithAgo date={_} />
      },
      {
         title: 'Дата последней',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         align: 'center',
         sorter: true,
         render: (_) => <DateWithAgo date={_} />
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);
      GetNumbersList({
         page: params.pagination.current,
         limit: params.pagination.pageSize,
         ...params.sorter,
         ...params.filters,
         service
      }).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, total: res.count }
         });

         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   return (
      <>
         <Segmented
            options={services}
            onChange={(value) => setService(value)}
            size="large"
         />
         <VerticalSpace />
         <Table
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            rowKey="id"
            onChange={handleTableChange}
            loading={isLoading}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableNumbers;
