import React, { useState, useEffect } from 'react';
import { Space, Table, Tag, Tooltip, Spin } from 'antd';
import { Button, DateWithAgo } from '@shared/ui';
import { GetDriversList } from '../model/GetDriversList';
import { EditOutlined, StarFilled } from '@ant-design/icons';
import ModalDriverForm from '../../form-driver/ui/ModalDriverForm';
import Pagination, { initialPaginationSettings } from '@widgets/Pagination';
import stringToColor from '@shared/utils/stringToColor';
import { statusesOfDrivers } from '@shared/const/statuses';
import ModalButtonDriverCreate from './ModalButtonDriverCreate';
import { Typography } from 'antd';
import { VerticalSpace, StatusTag } from '@shared/ui';
import ModalButtonDriversMap from './ModalButtonDriversMap';

import ModalButtonNoteCreate from '@features/notes/ui/ModalButtonNoteCreate';
import {
   getColumnSearchProps,
   onSearchFilterTable,
   onTableChange
} from '@shared/ui/Table';
import {
   timestampToNormalTime,
   timestampToNormalDDMMYY
} from '@shared/utils/tsToTime';
import CanDo from '@shared/lib/CanDo';
import { useNavigate } from 'react-router-dom';
import EnterToEntityButton from '@widgets/Button/EnterToEntityButton';
import TotalCountTableText from '@widgets/Table/TotalCountTableText';
import { isHaveUrlListSettings, parseUrlListSettings } from '@shared/utils/browser';
import { getApiQueryParamsForTables } from '@shared/lib/table';
import { changeUrl } from '@shared/utils/browser';
import ExportToCSVModalButton from '@widgets/ExportToCSV/ExportToCSVModalButton';
import NewEntityTag from '@shared/ui/Custom/NewEntityTag';
import { FinishedRouteCount } from './DriverComponents';
import regions from '@shared/const/regions';

const { Text } = Typography;

const initialSorter = {
   sort: 'id',
   order: 'desc'
};

const TableDrivers = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [data, setData] = useState([]);
   const [selectedDriver, setSelectedDriver] = useState(null);
   const navigate = useNavigate();

   const [paramsTable, setParamsTable] = useState({
      pagination: { ...initialPaginationSettings() },
      filters: {},
      sorter: initialSorter
   });

   const { pagination, filters } = paramsTable;

   useEffect(() => {
      if (isHaveUrlListSettings) {
         const params = parseUrlListSettings();

         fetchData(params);
      }
   }, []);

   const getCategoryNames = (arrOfEntityCategories = []) => {
      return arrOfEntityCategories.map((item) => item?.materialCategory?.name);
   };

   const columns = [
      // {
      //    title: 'ID',
      //    dataIndex: 'id',
      //    key: 'id',
      //    sorter: true,
      //    filtered: !!filters?.['id'],
      //    ...getColumnSearchProps({
      //       dataIndex: 'id',
      //       handleSearch: (searchObj) => onSearchTable(searchObj),
      //       type: 'number'
      //    })
      // },
      {
         title: 'Водитель',
         dataIndex: 'id',
         key: 'id',
         fixed: 'left',
         sorter: true,
         sortOrder:
            paramsTable?.sorter?.sort === 'id'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         width: '260px',
         filtered: !!filters?.['id'],
         ...getColumnSearchProps({
            dataIndex: 'id',
            handleSearch: (searchObj) => onSearchTable(searchObj),
            type: 'number'
         }),
         render: (_, record) => (
            <Space direction="vertical">
               <Space direction="horizontal" size="small">
                  <span
                     className="green-span-url"
                     style={{ margin: 0 }}
                     onClick={() => navigate(`/drivers/${record.id}`)}>
                     {`Водитель №${record.id}`}
                  </span>
                  {record.rating ? (
                     <Tag bordered={false}>
                        <StarFilled style={{ color: '#ffca00' }} />{' '}
                        {record.rating / 10}
                     </Tag>
                  ) : null}

                  <NewEntityTag date={record.createdAt} />
               </Space>

               <Text type="secondary" style={{ fontSize: '12px' }}>
                  {record.firstName} {record.lastName}
               </Text>
            </Space>
         )
      },
      // {
      //    title: 'Рейтинг',
      //    dataIndex: 'rating',
      //    key: 'rating',
      //    render: (_) => (
      //       <>
      //          <StarFilled style={{ color: '#ffca00' }} /> {_ / 10}
      //       </>
      //    )
      // },
      // {
      //    title: 'Имя',
      //    dataIndex: 'firstName',
      //    key: 'firstName',
      //    filtered: !!filters?.['firstName'],
      //    ...getColumnSearchProps({
      //       dataIndex: 'firstName',
      //       handleSearch: (searchObj) => onSearchTable(searchObj)
      //    })
      // },
      // {
      //    title: 'Фамилия',
      //    dataIndex: 'lastName',
      //    key: 'lastName',
      //    filtered: !!filters?.['lastName'],
      //    ...getColumnSearchProps({
      //       dataIndex: 'lastName',
      //       handleSearch: (searchObj) => onSearchTable(searchObj)
      //    })
      // },
      {
         title: 'Номер телефона',
         dataIndex: 'mobileNumber',
         fixed: 'left',
         width: '240px',
         key: 'mobileNumber',
         filtered: !!filters?.['mobileNumber'],
         ...getColumnSearchProps({
            dataIndex: 'mobileNumber',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      },
      {
         title: 'Статус',
         dataIndex: 'status',
         key: 'status',
         fixed: 'left',
         align: 'center',
         width: '200px',
         filterMode: true,
         filteredValue: Array.isArray(paramsTable?.filters?.status)
            ? paramsTable?.filters?.status
            : paramsTable?.filters?.status
            ? [paramsTable?.filters?.status]
            : [],

         filtered: !!filters?.['status'],
         filters: Object.values(statusesOfDrivers).map((e) => ({
            text: <StatusTag status={e.value} type="driver" />,
            value: e.value
         })),
         render: (_) => <StatusTag status={_} type="driver" />
      },
      {
         title: 'Был в приложении',
         dataIndex: 'lastSeenAt',
         key: 'lastSeenAt',
         sorter: true,
         align: 'center',
         width: '180px',
         sortOrder:
            paramsTable?.sorter?.sort === 'lastSeenAt'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         render: (_) => <DateWithAgo date={_} showDate={false} />
      },
      {
         title: 'Округи',
         dataIndex: 'districts',
         key: 'districts',
         width: '240px',
         filterMode: true,
         filterMultiple: false,
         filtered: !!filters?.['districts'],
         filteredValue: Array.isArray(paramsTable?.filters?.districts)
            ? paramsTable?.filters?.districts
            : paramsTable?.filters?.districts
            ? [paramsTable?.filters?.districts]
            : [],
         filters: Object.values(regions).map((e) => ({
            text: `${e.value} ${e.label}`,
            value: e.value
         })),
         render: (_) => (_?.length > 0 ? _?.join(', ') : 'Не задано')
      },
      {
         title: 'Дата регистрации',
         dataIndex: 'createdAt',
         key: 'createdAt',
         sorter: true,
         align: 'center',
         width: '180px',
         sortOrder:
            paramsTable?.sorter?.sort === 'createdAt'
               ? `${paramsTable?.sorter?.order}end`
               : null,
         render: (_) => `${timestampToNormalDDMMYY(_)} в ${timestampToNormalTime(_)}`
      },
      {
         title: 'Количество вывезенных маршрутов',
         dataIndex: 'updatedAt',
         key: 'updatedAt',
         width: '180px',
         align: 'center',
         render: (_, record) => <FinishedRouteCount driverId={record.id} />
      },
      {
         title: 'Номер авто',
         dataIndex: 'carNumber',
         key: 'carNumber',
         width: '180px',
         filtered: !!filters?.['carNumber'],
         render: (_) => <Tag bordered={false}>{_}</Tag>,
         ...getColumnSearchProps({
            dataIndex: 'carNumber',
            handleSearch: (searchObj) => onSearchTable(searchObj)
         })
      }
      // {
      //    title: "Бренд авто",
      //    dataIndex: "carBrand",
      //    key: "carBrand",
      // },
      // {
      //    title: 'Грузоподъемность',
      //    dataIndex: 'carCapacity',
      //    key: 'carCapacity',
      //    sorter: true
      // }
      // {
      //    title: "СТС авто",
      //    dataIndex: "carSTS",
      //    key: "carSTS",
      //    filtered: !!filters?.["carSTS"],
      //    ...getColumnSearchProps({
      //       dataIndex: "carSTS",
      //       handleSearch: (searchObj) => onSearchTable(searchObj),
      //    }),
      // },
   ];

   const actions = [
      // {
      //    title: 'Категория',
      //    dataIndex: 'entityCategories',
      //    key: 'entityCategories',
      //    width: 300,
      //    render: (_, record) => (
      //       <Space size="small" wrap>
      //          {getCategoryNames(_).map((item) => (
      //             <React.Fragment key={`category-${record.id}-${item}`}>
      //                {item && <Tag color={stringToColor(item)}>{item}</Tag>}
      //             </React.Fragment>
      //          ))}
      //       </Space>
      //    )
      // },
      {
         title: 'Действие',
         key: 'action',
         width: '240px',
         fixed: 'right',
         render: (_, record) => (
            <Space size="middle">
               {<EnterToEntityButton id={record.id} entity="drivers" />}

               <CanDo permission="can_edit_drivers">
                  <Space size="middle">
                     <Tooltip placement="top" title={'Редактирование'}>
                        <Button
                           onClick={() => setSelectedDriver(record)}
                           type="primary">
                           <EditOutlined />
                        </Button>
                     </Tooltip>
                     <Tooltip placement="top" title={'Добавить заметку'}>
                        <ModalButtonNoteCreate
                           closeModal={closeModal}
                           entityId={record.id}
                           entityType={'driver'}
                           buttonText={null}
                        />{' '}
                     </Tooltip>
                     {/* {record.status === 'active' && (
                        <Popconfirm
                           title="Сбросить пароль"
                           onConfirm={() => generatePasswordForDriver(record)}
                           okText="Да"
                           cancelText="Отмена">
                           <Tooltip placement="left" title="Создать новый пароль">
                              <Button>
                                 <UnlockOutlined />
                              </Button>
                           </Tooltip>
                        </Popconfirm>
                     )} */}
                  </Space>
               </CanDo>

               {/* {record.status === 'blocked' && (
                  <DeleteEntityButton
                     id={record.id}
                     callbackFetch={deleteDriverById}
                     update={fetchData}
                     permission="can_delete_drivers"
                  />
               )} */}
            </Space>
         )
      }
   ];

   const onSearchTable = (searchObj) => {
      onSearchFilterTable(searchObj, paramsTable, fetchData);
   };

   const handleTableChange = (pagination, filtersTable, sorterTable) => {
      onTableChange(pagination, filtersTable, sorterTable, paramsTable, fetchData);
   };

   const closeModal = () => {
      setSelectedDriver(null);

      setTimeout(() => {
         fetchData(paramsTable);
      }, 1000);
   };

   const fetchData = (params = paramsTable) => {
      setIsLoading(true);

      const queryObj = getApiQueryParamsForTables(
         params,
         paramsTable,
         initialSorter
      );
      const { page: current, limit: pageSize } = queryObj;

      GetDriversList(queryObj).then((res) => {
         setParamsTable({
            ...params,
            pagination: { ...params.pagination, current, pageSize, total: res.count }
         });

         changeUrl(params);
         setData(res.data);
         setIsLoading(false);
      });
   };

   const onChangePagination = (current, pageSize) => {
      const newParams = {
         ...paramsTable,
         pagination: { ...paramsTable.pagination, current, pageSize }
      };
      fetchData(newParams);
   };

   return (
      <>
         <ModalDriverForm selectedDriver={selectedDriver} closeModal={closeModal} />
         <CanDo permission="can_create_drivers">
            <Space style={{ display: 'flex', justifyContent: 'flex-start' }}>
               <ModalButtonDriverCreate closeModal={closeModal} />
               <ModalButtonDriversMap />
            </Space>
         </CanDo>
         <VerticalSpace />

         <div className="table-right-block-top">
            <div className="table-right-block-top_item">
               <Space size="small">
                  <TotalCountTableText total={pagination.total} />
                  <ExportToCSVModalButton
                     type="driver"
                     fetchData={GetDriversList}
                     dataParams={paramsTable}
                  />
               </Space>
            </div>
         </div>

         <Table
            rowKey="id"
            columns={[...columns, ...actions]}
            dataSource={data}
            pagination={false}
            onChange={handleTableChange}
            loading={isLoading}
            scroll={{ x: 1500 }}
         />
         <VerticalSpace />
         <Space style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {!!pagination.total && (
               <Pagination
                  pagination={pagination}
                  onChangePagination={onChangePagination}
                  isLoading={isLoading}
               />
            )}
         </Space>
      </>
   );
};

export default TableDrivers;
