import React, { useState, useEffect } from 'react';
import AcceptanceForm from './AcceptanceForm';
import { CreateAcceptance } from '../model/CreateAcceptance';
import { UpdateAcceptance } from '../model/UpdateAcceptance';
import { GetAcceptance } from '../model/GetAcceptance';
import _ from 'lodash';

import { message } from 'antd';

const CreateOrEditAcceptance = ({ id = null, callbackOnSuccess = () => {} }) => {
   const isEditForm = !!id;
   const [initialValues, setIntialValues] = useState({});
   const [loadForm, setLoadForm] = useState(false);

   useEffect(() => {
      if (!isEditForm) {
         return;
      }

      GetAcceptance(id).then((res) => {
         setIntialValues({ ...initialValues, ...res });
         setLoadForm(true);
      });

      return () => {
         setLoadForm(false);
      };
   }, [id]);

   const onSuccess = async (values, setLoading) => {
      setLoading(true);

      if (isEditForm) {
         const acceptanceId = initialValues.id;
         const mergedObj = { ...initialValues, ...values };

         const updatedFields = _.pickBy(
            mergedObj,
            (v, k) => !_.isEqual(initialValues[k], v)
         );

         if (updatedFields?.driverId === undefined) {
            updatedFields.driverId = null;
         }

         await UpdateAcceptance(updatedFields, acceptanceId)
            .then(() => {
               callbackOnSuccess();
               message.success('Приемка изменена');
            })
            .catch((e) =>
               message.error(e?.message || 'Возникла ошибка при сохранении')
            )
            .finally(() => setLoading(false));

         return;
      }

      await CreateAcceptance(values)
         .then(() => {
            message.success('Приемка создана');
            callbackOnSuccess();
         })
         .catch(() => message.error('Приемка не создана, возникла ошибка'))
         .finally(() => setLoading(false));
   };

   if (!loadForm && isEditForm) {
      // Чтобы initialValues прогрузились при первом рендере, если это Редактируемая форма
      return <></>;
   }

   return (
      <AcceptanceForm
         initialValues={initialValues}
         isEditForm={isEditForm}
         onSuccess={onSuccess}
      />
   );
};

export default CreateOrEditAcceptance;
